export default class MatrixRenderer {
  static TYPE_LINK = 'link';

  static RATIO_HIGHLIGHT_OFFSET = 0.75;
  static RATIO_LINE_THICKNESS = 0.06;
  static RATIO_IMAGE_SCALE = 0.48;
  static RATIO_IMAGE_MARGIN = 0.5;

  static COLOR_TEXT = '#000000';
  static COLOR_HIGHLIGHT = '#FFFF00';
  static COLOR_UNDERLINE = '#3d3d3d';
  static COLOR_STRIKETHROUGH = '#3d3d3d3';
  static COLOR_LINK = '#0000EE';

  #onRenderTextListener;

  render() {}

  renderText(data, boundary, renderingState) {
    var styles = data.styles;
    var text = data.text;
    var highlight = styles.includes('HIGHLIGHT');
    var underline = styles.includes('UNDERLINE');
    var strikethrough = styles.includes('STRIKETHROUGH');
    var options = { highlight, underline, strikethrough };

    var fontStyle = this.getFontStyle(styles);
    renderingState.setFont(renderingState.getFont(), fontStyle);

    let absX = renderingState.getAbsPositionX(boundary);
    var absBoundaryRight = renderingState.getAbsBoundaryRight(boundary);

    var lineFreeSpace = Math.max(0, absBoundaryRight - absX);
    var firstWord = text.split(' ')[0];

    var pdfDocument = renderingState.getPdfDocument();
    var textDimensions = renderingState.getTextDimensions(firstWord);
    pdfDocument.setTextColor(data.color || MatrixRenderer.COLOR_TEXT);

    var displacement = 0;

    if (textDimensions.width <= lineFreeSpace) {
      var linesFinish = pdfDocument.splitTextToSize(text, lineFreeSpace);
      var firstLine = linesFinish[0];

      if (firstLine !== undefined) {
        this.drawLine(
          {
            type: data.type,
            value: firstLine,
            data: data.extraData,
          },
          boundary,
          renderingState,
          options
        );
        displacement += renderingState.getLineHeight();
        text = text.substring(firstLine.length);
        if (text.length === 0)
          renderingState.movePositionY(
            -renderingState.getLineHeight(),
            boundary
          );
      }
    } else {
      renderingState.movePositionY(renderingState.getLineHeight(), boundary);
    }

    var lines = pdfDocument.splitTextToSize(text, boundary.getWidth());
    if (text.length > 0) {
      lines.forEach((line) => {
        renderingState.getPosition().setX(0);
        this.drawLine(
          {
            type: data.type,
            value: line.trimLeft(),
            data: data.extraData,
          },
          boundary,
          renderingState,
          options
        );
        displacement += renderingState.getLineHeight();
      });
      renderingState.movePositionY(-renderingState.getLineHeight(), boundary);
    }
    return displacement;
  }

  getFontStyle(styles) {
    var fontStyle = '';
    if (styles.includes('BOLD')) fontStyle = 'bold';
    if (styles.includes('ITALIC')) fontStyle += 'italic';
    return fontStyle === '' ? 'normal' : fontStyle;
  }

  drawLine(
    line,
    boundary,
    renderingState,
    options = { highlight: false, underline: false, strikethrough: false }
  ) {
    var textDimensions = renderingState.getTextDimensions(line.value);
    var currentPosition = renderingState.getPosition();
    var lineHeight = renderingState.getLineHeight();
    var barLineThickness = lineHeight * MatrixRenderer.RATIO_LINE_THICKNESS;

    renderingState.movePositionY(lineHeight, boundary, false);
    var absX = renderingState.getAbsPositionX(boundary),
      absY = renderingState.getAbsPositionY(boundary);
    //console.log("rendering---", this.#onRenderTextListener !== undefined , currentPosition.getX() < 1, currentPosition.getX(), currentPosition, line);
    if (this.#onRenderTextListener !== undefined && currentPosition.getX() < 1)
      this.#onRenderTextListener(line.value, absX, absY, 0, 0);

    renderingState.movePositionX(textDimensions.width, boundary);
    var pdfDocument = renderingState.getPdfDocument();

    if (options.highlight) {
      pdfDocument.setFillColor(MatrixRenderer.COLOR_HIGHLIGHT);
      pdfDocument.rect(
        absX,
        absY - lineHeight * MatrixRenderer.RATIO_HIGHLIGHT_OFFSET,
        textDimensions.width,
        lineHeight,
        'F'
      );
    }

    if (options.underline) {
      pdfDocument.setFillColor(MatrixRenderer.COLOR_UNDERLINE);
      pdfDocument.rect(
        absX,
        absY + barLineThickness,
        textDimensions.width,
        barLineThickness,
        'F'
      );
    }

    if (line.type.toLowerCase() === MatrixRenderer.TYPE_LINK) {
      pdfDocument.setTextColor(MatrixRenderer.COLOR_LINK);
      pdfDocument.textWithLink(line.value, absX, absY, line.data);
    } else {
      //pdfDocument.setTextColor(MatrixRenderer.COLOR_TEXT);
      pdfDocument.text(line.value, absX, absY);
    }

    if (options.strikethrough)
      pdfDocument.rect(
        absX,
        absY - lineHeight / 3,
        textDimensions.width,
        barLineThickness,
        'F'
      );
  }

  getDataUri(url) {
    return new Promise((resolve, reject) => {
      var image = new Image();
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;

        //next three lines for white background in case png has a transparent background
        var ctx = canvas.getContext('2d');
        ctx.fillStyle = '#fff'; /// set white fill style
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        canvas.getContext('2d').drawImage(this, 0, 0);

        resolve({
          data: canvas.toDataURL('image/jpeg'),
          width: this.naturalWidth,
          height: this.naturalHeight,
        });
      };

      image.src = url;
    });
  }

  async renderImage(data, boundary, renderingState, addMargin = false) {
    let image = await this.getDataUri(data.url);
    return new Promise((resolve, reject) => {
      var boundaryWidth = boundary.getWidth();
      var width = Math.min(
        image.width,
        boundaryWidth * MatrixRenderer.RATIO_IMAGE_SCALE
      );
      var height = (width / image.width) * image.height;
      var offsetY = addMargin
        ? renderingState.getLineHeight() * MatrixRenderer.RATIO_IMAGE_MARGIN
        : 0;
      var DOffsetY = 2 * offsetY;

      if (
        renderingState.getAbsPositionY(boundary) + height + DOffsetY >
        renderingState.getAbsPageBottom()
      ) {
        var currPage = renderingState.getCurrentPage();
        var numPages = renderingState.getPageNumber();
        if (currPage < numPages) {
          renderingState.getPdfDocument().setPage(currPage + 1);
        } else {
          renderingState.getPdfDocument().addPage();
        }
        var pageHeight = renderingState.getPageHeight();
        if (height + DOffsetY > pageHeight) {
          height = pageHeight - DOffsetY;
          width = (height / image.height) * width;
        }
        renderingState.getPosition().setY(0);
      }

      renderingState.getPosition().setX(0);
      var alignment = data.alignment || 'left';
      if (alignment === 'center') {
        renderingState.getPosition().setX(boundaryWidth / 2 - width / 2);
      } else if (alignment === 'right') {
        renderingState.getPosition().setX(boundaryWidth - width);
      }

      renderingState
        .getPdfDocument()
        .addImage(
          image.data,
          'JPEG',
          renderingState.getAbsPositionX(boundary),
          renderingState.getAbsPositionY(boundary) + offsetY,
          width,
          height
        );
      renderingState.movePositionY(height + DOffsetY, boundary);
      resolve({ width, height: height + DOffsetY });
    });
  }

  setOnRenderTextListener(onRenderTextListener) {
    this.#onRenderTextListener = onRenderTextListener;
  }
}
