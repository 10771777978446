import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { config } from '../../Constants';

export function* fetchMedicalAidSaga() {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    const response = yield fetch(
      `${config.url.API_URL}/api/v1/medschemes?limit=100&sort=descr`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: bearer,
        },
      }
    ).then((d) => d.json());
    if (response.error) throw response.error;
    let fetchedMedAid = response.data.map((m) => ({
      id: m.id,
      descr: m.descr,
    }));
    yield put(actions.fetchMedicalAidsSuccess(fetchedMedAid));
  } catch (error) {
    yield put(actions.fetchMedicalAidsFail(error));
  }
}

export function* fetchPatientSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    const response = yield fetch(
      `${config.url.API_URL}/api/v1/patients/${action.patientId}`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: bearer,
        },
      }
    ).then((d) => d.json());
    if (response.error) throw response.error;
    yield put(actions.fetchPatientSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchPatientFail(error));
  }
}

export function* fetchPatientsSaga() {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    const response = yield fetch(`${config.url.API_URL}/api/v1/patients/`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    }).then((d) => d.json());
    if (response.error) throw response.error;
    yield put(actions.fetchPatientsSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchPatientsFail(error));
  }
}

export function* addMainMemberSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  const mainmemberData = action.mm;
  mainmemberData.idnumber =
    action.mm.identificationType === 'idnumber'
      ? action.mm.idnumber
      : action.mm.passportnumber;
  mainmemberData.passportnumber =
    action.mm.identificationType === 'idnumber'
      ? action.mm.idnumber
      : action.mm.passportnumber;
  mainmemberData.address =
    action.mm.address1 +
    ', ' +
    action.mm.address2 +
    ', ' +
    action.mm.city +
    ', ' +
    action.mm.code +
    ', ' +
    action.mm.province +
    ', ' + action.mm.country;
  mainmemberData.address1 = action.mm.address1;
  mainmemberData.address2 = action.mm.address2;
  mainmemberData.city = action.mm.city;
  mainmemberData.city = action.mm.code;
  mainmemberData.province = action.mm.province;
  action.mm.paymentType === 'Cash' && delete mainmemberData.medicalAid;
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/medschemeinfo`;
    const response = yield fetch(endpointURL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(mainmemberData),
    }).then((d) => d.json());
    if (response.error) throw response.error;
    const mmId = response.data.id;
    const patientData = action.pat;
    patientData.idnumber =
      action.pat.identificationType === 'idnumber'
        ? action.pat.idnumber
        : action.pat.passportnumber;
    patientData.passportnumber =
      action.pat.identificationType === 'idnumber'
        ? action.pat.idnumber
        : action.pat.passportnumber;
    patientData.address =
      action.pat.address1 +
      ', ' +
      action.pat.address2 +
      ', ' +
      action.pat.city +
      ', ' +
      action.pat.code +
      ', ' +
      action.pat.province +
      ', ' + action.pat.country;
    patientData.address1 = action.pat.address1;
    patientData.address2 = action.pat.address2;
    patientData.city = action.pat.city;
    patientData.code = action.pat.code;
    patientData.province = action.pat.province;
    patientData.country = action.pat.country;
    yield put(actions.addPatientStart(patientData, mmId, action.spec));
  } catch (error) {
    yield put(actions.addPatientFail(error));
  }
}

export function* addPatientSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  const patientData = action.patient;
  patientData.mainMember = action.medicalId;
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/patients`;
    const responsePatient = yield fetch(endpointURL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(patientData),
    }).then((d) => d.json());
    if (responsePatient.error) throw responsePatient.error;
    action.specimen.patient = responsePatient.data.id;
    yield put(actions.addPatientSuccess(responsePatient.data));
    yield put(actions.addSpecimenStart(action.specimen));
  } catch (error) {
    yield put(actions.addPatientFail(error));
  }
}

export function* editPatientSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  //yield put(actions.addDoctorStart());
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/patients/${action.payload.id}`;
    const response = yield fetch(endpointURL, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(
        Object.fromEntries(
          Object.entries(action.payload).filter(
            ([key, value]) => key !== 'mainMember'
          )
        )
      ),
    }).then((d) => d.json());
    if (response.error) throw response.error;
    yield put(actions.editPatientSuccess(response.data));
    yield put(actions.editMainMemberStart(action.payload.mainMember));
  } catch (error) {
    if (typeof error === 'object')
      yield put(actions.editPatientFail(error.errmsg));
    else yield put(actions.editPatientFail(error));
  }
}

export function* editMainMemberSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  //yield put(actions.addDoctorStart());
  if (action.payload.paymentType === 'Cash') {
    delete action.payload.medicalAid;
    delete action.payload.medicalId;
  }
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/medschemeinfo/${action.payload.id}`;
    const response = yield fetch(endpointURL, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(action.payload),
    }).then((d) => d.json());
    if (response.error) throw response.error;
    yield put(actions.editMainMemberSuccess(response.data));
  } catch (error) {
    if (typeof error === 'object')
      yield put(actions.editMainMemberFail(error.errmsg));
    else yield put(actions.editMainMemberFail(error));
  }
}
