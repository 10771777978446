import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Modal,
  Typography,
  Backdrop,
  Fade,
  Button,
  Box,
} from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  error: {
    backgroundColor: red[500],
  },
  success: {
    backgroundColor: green[500],
  },
}));

export default function AlertModal(props) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <Typography
              variant="h5"
              className={
                props.alertType === 'error' ? classes.error : classes.success
              }
              align="center"
              component="h2"
              gutterBottom
            >
              {props.title}
            </Typography>
            <p id="transition-modal-description">{props.message}</p>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                variant="contained"
                color="default"
                onClick={props.onClose}
              >
                Close
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
