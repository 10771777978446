import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBAnimation,
} from 'mdbreact';
import MatTable from '../MatTable';
import Spinner from '../Spinner';

function DoctorHome({ Doctor, onFetchDoctors, ...props }) {
  const history = useHistory();
  const [columns] = useState([
    {
      title: 'Mnemonic',
      field: 'mnemonic',
      editable: 'never',
      filtering: false,
    },
    {
      title: 'Name',
      field: 'name',
      editable: 'never',
      filtering: false,
    },
    { title: 'Surname', field: 'surname', editable: 'never' },
    {
      title: 'Practice Number',
      field: 'practiceNumber',
      hidden: true,
    },
    {
      title: 'Address',
      field: 'location.formattedAddress',
    },
    {
      title: 'Email',
      field: 'email',
      hidden: true,
    },
    {
      title: 'Cellphone',
      field: 'cellphone',
    },
    {
      title: 'Phone',
      field: 'phone',
    },
    {
      title: 'Speciality',
      field: 'speciality',
      editable: 'never',
    },
    { title: 'id', field: 'id', hidden: true },
  ]);
  const handleRowClick = (event, rowData) => {
    history.push(`/doctors/${rowData.id}`);
  };
  //   history.push(`doctors/${rowData.id}`);

  const handleClick = () => {
    history.push('/doctors/new');
  };

  useEffect(() => {
    onFetchDoctors();
  }, []);

  return (
    <MDBContainer className="mt-3">
      <MDBRow className="py-3">
        <MDBCol md="12">
          <MDBAnimation reveal type="fadeInLeft">
            <MDBCard>
              <MDBCardBody>
                {props.Doctors ? (
                  <>
                    <MDBBtn onClick={handleClick} color="deep-orange">
                      Add Doctor
                    </MDBBtn>
                    <MatTable
                      title="Doctor Dictionary"
                      columns={columns}
                      data={props.Doctors}
                      handleRowClick={handleRowClick}
                    />
                  </>
                ) : (
                  <Spinner />
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    Doctors: state.doctor.doctors,
    Doctor: state.doctor.doctor,
    Specimen: state.patient.specimen,
    Loading: state.patient.loading,
    Error: state.patient.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDoctor: (doctorId) => dispatch(actions.fetchDoctorStart(doctorId)),
    onFetchDoctors: () => dispatch(actions.fetchDoctorsStart()),
    onAddDoctor: (doctor) => dispatch(actions.addDoctorStart(doctor)),
    onClearError: () => dispatch(actions.clearError()),
    onAddError: (error) => dispatch(actions.addSpecimenFail(error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorHome);
