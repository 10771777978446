import React, { useEffect, useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBBtn,
  MDBAnimation,
  MDBAlert,
} from 'mdbreact';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CytoAutocomplete from '../../CytoAutoComplete';
import Spinner from '../../Spinner';
import { TextField  } from '@material-ui/core';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

 let theme = createTheme();
theme = responsiveFontSizes(theme);
function FormCytology(props) {
  const { deleteCytoSelection, AddCytoSelection, onChangeMain, nextStep, prevStep, specimenNature, adequacy, flora, background, morphologyGlandular, morphologySquamous, cytoDiagnosis, specimenID, userId, texts, report } = props;
  const [pError, setPerror] = useState(false);
  const [sT, setsT] = React.useState(false);
  const [bT, setbT] = React.useState(false);
  const [aT, setaT] = React.useState(false);
  const [fT, setfT] = React.useState(false);
  const [dT, setdT] = React.useState(false);
  const [msT, setmsT] = React.useState(false);
  const [mgT, setmgT] = React.useState(false);

  var dict = {
    specimenNature: "NATURE OF SPECIMEN",
    adequacy: "ADEQUACY",
    flora: "FLORA",
    background: "BACKGROUND",
    morphologySquamous: "MORPHOLOGY SQUAMOUS",
    morphologyGlandular: "MORPHOLOGY GLANDULAR",
    cytoDiagnosis: "DIAGNOSIS"
  };
  const onClose = () => setPerror(false);

  const addCytoSelection = (selection, subSelection) => {
    AddCytoSelection({ selection, subSelection, text: ``, id: '' });
  }

  const buildAnObjectFromAQuery = () => ({
    ...sT && { specimenNature },
    ...aT && { adequacy },
    ...fT && { flora },
    ...bT && { background },
    ...msT && { morphologySquamous },
    ...mgT && { morphologyGlandular },
    ...dT && { cytoDiagnosis },
  });

  const nextClick = () => {
    if (props.reportId === '') {
      if (JSON.parse(specimenNature.text).blocks[0].text === '') {
        setPerror(true);
        return;
      } else{
        if (JSON.parse(specimenNature.text).blocks[0].text === '')
        setPerror(true);
        else
        props.addReport({
          title: 'Cytology Report',
          ...sT && { specimenNature },
          ...aT && { adequacy },
          ...fT && { flora },
          ...bT && { background },
          ...msT && { morphologySquamous },
          ...mgT && { morphologyGlandular },
          ...dT && { cytoDiagnosis },
          specimen: specimenID,
          Author: userId,
          status: 'Initial draft',
        });
      }
        
    } else {
      var dirtyFields = buildAnObjectFromAQuery();
      Object.keys(dirtyFields).length > 0 &&
        props.updateReport(
          dirtyFields,
          props.reportId
        );
    }
    setsT(false);
    setbT(false);
    setaT(false);
    setfT(false);
    setdT(false);
    setmsT(false);
    setmgT(false);
    nextStep();
  };

  const handleTag = (event, value) => {
    if (value) {
      const index = texts.findIndex((x) => x.code === value.code);
      if (index !== -1) {
        // if (value.diagnosisText) {
        //   setdT(true);
        //   onChangeMain('cytoSubDiagnosis', { text: value.diagnosisText, id: value.id, arrayIndex: event.currentTarget.id, icd: value.icd.map(x => x.icdcode).join('')  });
        // }
        switch (value.heading.descr) {
          case 'NATURE OF SPECIMEN':
            setsT(true);
            onChangeMain('specimenNature', { text: value.displayText, id: value.id });
            break;
          case 'ADEQUACY':
            setaT(true);
            onChangeMain('adequacy', { text: value.text, id: value.id });
            break;
          case 'FLORA':
            setfT(true);
            onChangeMain('flora', { text: value.text, id: value.id, arrayIndex: event.currentTarget.id });
            break;
          case 'BACKGROUND':
            setbT(true);
            onChangeMain('background', { text: value.text, id: value.id, arrayIndex: event.currentTarget.id });
            break;
          case 'MORPHOLOGY SQUAMOUS':
            setmsT(true);
            onChangeMain('morphologySquamous', { text: value.text, id: value.id, arrayIndex: event.currentTarget.id });
            break;
          case 'MORPHOLOGY GLANDULAR':
            setmgT(true);
            onChangeMain('morphologyGlandular', { text: value.text, id: value.id, arrayIndex: event.currentTarget.id });
            break;
          default:
            setdT(true);
            onChangeMain('cytoDiagnosis', { text: value.displayText, id: value.id, arrayIndex: event.currentTarget.id, icd: value.icd.map(x => x.icdcode).filter(x=>x!== 'Z03.9')});
            break;
        }
      } else {
        switch (value.heading.descr) {
          case 'NATURE OF SPECIMEN':
            setsT(true);
            onChangeMain('specimenNature', '');
            break;
          case 'ADEQUACY':
            setaT(true);
            onChangeMain('adequacy', '');
            break;
          case 'FLORA':
            setfT(true);
            onChangeMain('flora', '');
            break;
          case 'BACKGROUND':
            setbT(true);
            onChangeMain('background', '');
            break;
          case 'MORPHOLOGY SQUAMOUS':
            setmsT(true);
            onChangeMain('morphologySquamous', '');
            break;
          case 'MORPHOLOGY GLANDULAR':
            setmgT(true);
            onChangeMain('morphologyGlandular', '');
            break;
          default:
            setdT(true);
            onChangeMain('cytoDiagnosis', '');
            break;
        }
      }
    };
  }

  useEffect(() => {
    props.fetchCytologyTexts();
  }, []);

  return (!Object.keys(report).length && props.reportId && !props.report.texts?.length ? <Spinner /> :
    <MDBContainer className="mt-2 mb-3">
      <MDBAnimation reveal type={props.animation}>
        <MDBRow>
          <MDBCol className="mt-3">
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Cytology</MDBCardTitle>
                <ThemeProvider theme={theme}>
                  {pError && (
                    <MDBAlert color="danger" dismiss onClosed={onClose}>
                      <strong>Error!</strong> Please enter some text.
                    </MDBAlert>
                  )}
                  <Typography variant="h6">NATURE OF SPECIMEN</Typography>
                  <CytoAutocomplete id="snAutoComp" texts={texts} vIndex={!report?.specimenNature ? -1 : texts.findIndex(x => x.id === report?.specimenNature.id)} filter='NATURE OF SPECIMEN' handleTag={handleTag} label='Nature of Specimen Code' style={{ width: "25ch" }} />
                  <hr />
                  <Typography variant="h6">ADEQUACY</Typography>
                  <CytoAutocomplete id="adAutoComp" texts={texts} vIndex={!report?.adequacy ? -1 : texts.findIndex(x => x.id === report?.adequacy.id)} filter='ADEQUACY' handleTag={handleTag} label='Adequacy Code' style={{ width: "25ch" }} />
                  <hr />
                  <Typography variant="h6">FLORA/ORGANISMS</Typography>
                  {report?.flora?.length > 0 && report.flora.map((y, i) => <MDBRow key={"RowflAutoComp" + i}>
                    <MDBCol size="10"><CytoAutocomplete pkey={"flAutoComp" + i} id={"flAutoComp" + i} texts={texts} vIndex={texts.findIndex(x => x.id === y.id)} filter={dict['flora']} handleTag={handleTag} label='Flora Code' /></MDBCol>
                    <MDBCol size="2"className="mt-4"><IconButton aria-label="delete" key={"btndelflAutoComp" + i} onClick={() => deleteCytoSelection('report', 'flora', i)}><DeleteIcon /></IconButton></MDBCol>
                  </MDBRow>)}
                  <IconButton aria-label="add" key="btnAddflAutoComp" onClick={() => addCytoSelection('report', 'flora')}><AddCircleOutlineIcon /></IconButton>
                  <hr />
                  <Typography variant="h6">BACKGROUND</Typography>
                  {report?.background?.length > 0 && report?.background.map((y, i) => <MDBRow key={"RowbgAutoComp" + i}>
                    <MDBCol size="10" ><CytoAutocomplete pkey={"bgAutoComp" + i} id={"bgAutoComp" + i} texts={texts} vIndex={texts.findIndex(x => x.id === y.id)} filter={dict['background']} handleTag={handleTag} label='Background Code' /></MDBCol>
                    <MDBCol size="2" className="mt-4"><IconButton aria-label="delete" key={"btndelbgAutoComp" + i} onClick={() => deleteCytoSelection('report', 'background', i)}><DeleteIcon /></IconButton></MDBCol>
                  </MDBRow>
                 )}
                  <IconButton aria-label="add" key="btnAddbgAutoComp" onClick={() => addCytoSelection('report', 'background')}><AddCircleOutlineIcon /></IconButton>
                  <hr />
                  <Typography variant="h6">MORPHOLOGY SQUAMOUS</Typography>
                  {report?.morphologySquamous?.length > 0 && report?.morphologySquamous.map((y, i) => <MDBRow key={"RowmsAutoComp" + i}>
                    <MDBCol size="10" ><CytoAutocomplete pkey={"msAutoComp" + i} id={"msAutoComp" + i} texts={texts} vIndex={texts.findIndex(x => x.id === y.id)} filter={dict['morphologySquamous']} handleTag={handleTag} label='Morphology Squamous Code' /></MDBCol>
                    <MDBCol size="2" className="mt-4"><IconButton aria-label="delete" key={"btndelmsAutoComp" + i} onClick={() => deleteCytoSelection('report', 'morphologySquamous', i)}><DeleteIcon /></IconButton></MDBCol>
                  </MDBRow>
                 )}
                  <IconButton aria-label="add" key="btnAddmsAutoComp" onClick={() => addCytoSelection('report', 'morphologySquamous')}><AddCircleOutlineIcon /></IconButton>
                  <hr />
                  <Typography variant="h6">MORPHOLOGY GLANDULAR</Typography>
                  {report?.morphologyGlandular?.length > 0 && report.morphologyGlandular.map((y, i) => <MDBRow key={"RowmgAutoComp" + i}>
                    <MDBCol size="10" >
                    <CytoAutocomplete pkey={"mgAutoComp" + i} id={"mgAutoComp" + i} texts={texts} vIndex={texts.findIndex(x => x.id === y.id)} filter={dict['morphologyGlandular']} handleTag={handleTag} label='Morphology Glandular Code' />
                    </MDBCol>
                    <MDBCol size="2" className="mt-4">
                    <IconButton aria-label="delete" key={"btnDelmsAutoComp" + i} onClick={() => deleteCytoSelection('report', 'morphologyGlandular', i)}><DeleteIcon /></IconButton>
                    </MDBCol>
                  </MDBRow>)}
                  <IconButton aria-label="add" key="btnAddmgAutoComp" onClick={() => addCytoSelection('report', 'morphologyGlandular')}><AddCircleOutlineIcon /></IconButton>
                  <hr />
                  <Typography variant="h6">DIAGNOSIS</Typography>
                  {report?.cytoDiagnosis?.length > 0 && report.cytoDiagnosis.map((y, i) => <MDBRow key={"RowmgAutoComp" + i}>
                    <MDBCol size="10">
                    <CytoAutocomplete pkey={"diagAutoComp" + i} id={"diagAutoComp" + i} texts={texts} vIndex={texts.findIndex(x => x.id === y.id)} filter={dict['cytoDiagnosis']} handleTag={handleTag} label='Diagnosis Code' />
                    </MDBCol>
                    <MDBCol size="2" className="mt-4">
                    <IconButton aria-label="delete" key={"btnDelmgAutoComp" + i} onClick={() => deleteCytoSelection('report', 'cytoDiagnosis', i)}><DeleteIcon /></IconButton>
                    </MDBCol>
                  </MDBRow>
                    )}
                  <IconButton aria-label="add" key="btnAdddgAutoComp" onClick={() => addCytoSelection('report', 'cytoDiagnosis')}><AddCircleOutlineIcon /></IconButton>
                <hr />
                {/* <Typography variant="h6">SUB DIAGNOSIS</Typography>
                  {report?.cytoSubDiagnosis?.length > 0 && report.cytoSubDiagnosis.map((y, i) => <MDBRow>
                    <MDBCol size="10">
                    <TextField key={i} style={{ width: "80ch",lef
                    
                    t: "5ch" }} id="txtCytoSubDiagnosis" label="Diagnosis" multiline value={JSON.parse(y.text.replace('\n','\\n').blocks[0].text)}/>
                    </MDBCol>
                    <MDBCol size="2" className="mt-4">
                    <IconButton aria-label="delete" key={"btnDelcsAutoComp" + i} onClick={() => deleteCytoSelection('report', 'cytoSubDiagnosis', i)}><DeleteIcon /></IconButton>
                    </MDBCol>
                  </MDBRow>
                    )}
                  <IconButton aria-label="add" key="btnAddcsAutoComp" onClick={() => addCytoSelection('report', 'cytoDiagnosis')}><AddCircleOutlineIcon /></IconButton> */}
                </ThemeProvider>
                <hr />
                <MDBBtn color="black" variant="contained" onClick={prevStep}>
                  Back
                </MDBBtn>
                <MDBBtn color="orange" variant="contained" onClick={nextClick}>
                  Continue
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBAnimation>
    </MDBContainer>
  );
}
export default FormCytology;
