import React, { useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBInput,
  MDBCardBody,
  MDBBtn,
  MDBAnimation,
  MDBAlert,
} from 'mdbreact';
import { convertToRaw } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

function FormClinicalHistory(props) {
  const { onChange, nextStep, title, clinicalData, specimenID, userId } = props;
  const [pError, setPerror] = useState(false);
  const onClose = () => setPerror(false);

  const nextClick = () => {
    if (props.reportId === '') {
      if (JSON.parse(clinicalData).blocks[0].text === '') {
        setPerror(true);
        return;
      } else
        props.addReport({
          title,
          clinicalData,
          specimen: specimenID,
          Author: userId,
          status: 'Initial draft',
        });
    } else {
      props.updateReport(
        {
          clinicalData,
        },
        props.reportId
      );
    }
    nextStep();
  };

  const defaultTheme = createTheme();

  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          marginTop: 5,
          minHeight: 150,
        },
        editor: {
          borderBottom: '1px solid gray',
        },
      },
    },
  });

  return (
    <MDBContainer className="mt-2 mb-3">
      <MDBAnimation reveal type={props.animation}>
        <MDBRow>
          <MDBCol className="mt-3">
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Clinical History</MDBCardTitle>
                <div className="grey-text">
                  <MDBInput
                    label="Type your title"
                    icon="clipboard"
                    group
                    type="text"
                    onChange={(e) => onChange('title', e.target.value)}
                    value={title}
                  />
                  {pError && (
                    <MDBAlert color="danger" dismiss onClosed={onClose}>
                      <strong>Error!</strong> Please enter some text.
                    </MDBAlert>
                  )}
                  <MuiThemeProvider theme={defaultTheme}>
                    <MUIRichTextEditor
                      label="Drop a file inside the editor or press the last icon in the toolbar to simulate uploading an image...."
                      value={props.report.clinicalData}
                      controls={['bold', 'italic', 'underline']}
                      onChange={(state) =>
                        onChange(
                          'clinicalData',
                          JSON.stringify(
                            convertToRaw(state.getCurrentContent())
                          )
                        )
                      }
                    />
                  </MuiThemeProvider>
                </div>
                <MDBBtn color="orange" variant="contained" onClick={nextClick}>
                  Continue
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBAnimation>
    </MDBContainer>
  );
}
export default FormClinicalHistory;
