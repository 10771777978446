import React, { useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBBtn,
  MDBAnimation,
  MDBAlert,
  MDBInput,
} from 'mdbreact';
import { convertToRaw } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

function FormDiagnosis(props) {
  const {
    onChange,
    prevStep,
    nextStep,
    report,
    animation,
    diagnosis,
    icd10codeDescr,
  } = props;
  const defaultTheme = createTheme();

  const [pError, setPerror] = useState(false);

  const onClose = () => setPerror(false);

  const nextClick = () => {
    if (
      Object.values(JSON.parse(diagnosis))[0].filter(function (e) {
        return e.text.trim() !== '';
      }).length > 0
    ) {
      props.updateReport(
        {
          icd10code: icd10codeDescr,
          diagnosis,
        },
        props.reportId
      );
      nextStep();
    } else {
      setPerror(true);
      return;
    }
  };

  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          marginTop: 5,
          minHeight: 150,
        },
        editor: {
          borderBottom: '1px solid gray',
        },
      },
    },
  });
  return (
    <MDBContainer className="mt-2 mb-3">
      <MDBAnimation reveal type={animation}>
        <MDBRow>
          <MDBCol className="mt-3">
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Diagnosis</MDBCardTitle>
                {pError && (
                  <MDBAlert color="danger" dismiss onClosed={onClose}>
                    <strong>Error!</strong> Please enter some text.
                  </MDBAlert>
                )}
                <MuiThemeProvider theme={defaultTheme}>
                  <MUIRichTextEditor
                    label="Type something here..."
                    onChange={(state) =>
                      onChange(
                        'diagnosis',
                        JSON.stringify(convertToRaw(state.getCurrentContent()))
                      )
                    }
                    inlineToolbar={true}
                    value={report.diagnosis}
                    controls={[
                      'bold',
                      'italic',
                      'underline',
                      'media',
                      'upload-image',
                    ]}
                  />
                </MuiThemeProvider>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      required
                      label="ICD10 code"
                      icon="file-medical-alt"
                      type="text"
                      validate
                      value={icd10codeDescr}
                      onChange={(event) =>
                        onChange('icd10code', event.target.value)
                      }
                    />
                  </MDBCol>
                </MDBRow>
                <MDBBtn color="black" variant="contained" onClick={prevStep}>
                  Back
                </MDBBtn>

                <MDBBtn color="orange" variant="contained" onClick={nextClick}>
                  Continue
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBAnimation>
    </MDBContainer>
  );
}
export default FormDiagnosis;
