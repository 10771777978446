import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

function ConfirmationDialog(props) {
  const { onClose, open, fn, message, modalTitle, ...other } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    fn();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      modalTitle={modalTitle}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{modalTitle}</DialogTitle>
      <DialogContent dividers>
        <h3>{message}</h3>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ConfirmationDialog;
