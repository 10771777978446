import React, { useEffect } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBNavLink,
  MDBNav,
  MDBAnimation,
} from 'mdbreact';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import PatientInfo from './index';
import SpecimenCollection from '../Specimen/SpecimenCollection';
import Spinner from '../Spinner';

function Patient({
  match: {
    params: { patientId },
  },
  loading,
  onDeleteSpecimen,
  onSendReport,
  onFetchPatient,
  onFetchDoctors,
  onFetchPatientSpecimens,
  patient,
  specimens,
  doctors,
  onClearPatient,
}) {
  const scrollToTop = () => window.scrollTo(0, 0);
  useEffect(() => {
    onFetchPatient(patientId);
    if (!doctors) onFetchDoctors();
    onFetchPatientSpecimens(patientId);
    return () => {
      onClearPatient();
    };
  }, [patientId]);

  // if (specimens && !sdata) {
  //   if (patients) {
  //     const patient = patients.find((x) => x.id === patientId);
  //     setsdata(patient);
  //   }
  // }

  return !specimens ? (
    <Spinner />
  ) : (
    <MDBContainer className="mt-3">
      <MDBRow className="py-3">
        <MDBCol md="3">
          {!specimens ? (
            <Spinner />
          ) : specimens.length > 0 && patient ? (
            <MDBAnimation reveal type="flipInX">
              <MDBNav color="black" className="font-weight-bold py-1 px-2 mb-4">
                <MDBNavLink
                  tag="button"
                  to={`/reports/new/${specimens[0].id}`}
                  color="black"
                  className="text-center white-text"
                  onClick={scrollToTop}
                >
                  Capture Report
                </MDBNavLink>
              </MDBNav>
            </MDBAnimation>
          ) : null}
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="3">
          <SpecimenCollection
            doctors={doctors}
            specimens={specimens}
            patientID={patientId}
            patientId={patientId}
            sendReport={onSendReport}
            deleteSpecimen={onDeleteSpecimen}
            // doctorId={specimens[0].doctors}
          />
        </MDBCol>
        <MDBCol md="9">
          <PatientInfo patient={patient} edit={true} patientId={patientId} />
          {/* {!!patient ? (
            
          ) : (
            <Spinner />
          )} */}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    medicalAids: state.patient.medicalAids,
    doctors: state.doctor.doctors,
    patients: state.patient.patients,
    patient: state.patient.patient,
    specimens: state.specimen.specimens,
    loading: state.patient.loading,
    error: state.patient.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteSpecimen: (payload) =>
      dispatch(actions.deleteSpecimenStart(payload)),
    onSendReport: (payload) => dispatch(actions.sendReportStart(payload)),
    onFetchDoctors: () => dispatch(actions.fetchDoctorsStart()),
    onFetchPatient: (patientId) =>
      dispatch(actions.fetchPatientStart(patientId)),
    onFetchPatientSpecimens: (patientId) =>
      dispatch(actions.fetchPatientSpecimenStart(patientId)),
    onAddDoctor: (doctor, specimen) =>
      dispatch(actions.addDoctorStart(doctor, specimen)),
    onAddSpecimen: (specimen) => dispatch(actions.addSpecimenStart(specimen)),
    onClearError: () => dispatch(actions.clearError()),
    onAddError: (error) => dispatch(actions.addSpecimenFail(error)),
    onClearPatient: () => dispatch(actions.clearPatient()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
