import React from 'react';
import {
  MDBAnimation,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
} from 'mdbreact';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Divider,
  TextField,
  Box,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  isFormValid,
  getbirthdayFromID,
  contactNumberPresent,
} from '../../../shared/utility';
import DateFnsUtils from '@date-io/date-fns';

function AddPatient({
  medicalAids,
  patient,
  dispatch,
  loading,
  error,
  nextStep,
  onClearDoctor,
  onClearError,
  ...props
}) {
  const {
    initials,
    title,
    name,
    surname,
    email,
    identificationType,
    idnumber,
    passportnumber,
    birthday,
    gender,
    dependantCode,
    address1,
    address2,
    city,
    code,
    province,
    country,
    phone,
    cellphone,
    mainMember,
  } = patient;

  const handleBirthday = (e) => {
    dispatch({
      type: 'date',
      value: e,
    });
  };

  const handleTag = ({ target }) => {
    const { value } = target;
    if (value) {
      const index = medicalAids.findIndex((x) => x.descr === value);
      if (index !== -1) {
        dispatch({
          type: 'mfield',
          value: medicalAids[index].id,
          field: 'medicalAid',
        });
        dispatch({
          type: 'mfield',
          value: index,
          field: 'mmIndex',
        });
      }
    } else {
      dispatch({
        type: 'mfield',
        value: '',
        field: 'medicalAid',
      });
      dispatch({
        type: 'mfield',
        value: -1,
        field: 'mmIndex',
      });
    }
  };

  const handleCopy = () => {
    dispatch({
      type: 'mfield',
      field: 'title',
      value: title,
    });
    dispatch({
      type: 'mfield',
      field: 'name',
      value: name,
    });
    dispatch({
      type: 'mfield',
      field: 'surname',
      value: surname,
    });
    dispatch({
      type: 'mfield',
      field: 'initials',
      value: initials,
    });
    dispatch({
      type: 'mfield',
      field: 'idnumber',
      value: idnumber,
    });
    dispatch({
      type: 'mfield',
      field: 'identificationType',
      value: identificationType,
    });
    dispatch({
      type: 'mfield',
      field: 'passportnumber',
      value: passportnumber,
    });
    dispatch({
      type: 'mfield',
      field: 'phone',
      value: phone,
    });
    dispatch({
      type: 'mfield',
      field: 'cellphone',
      value: cellphone,
    });
    dispatch({
      type: 'mfield',
      field: 'email',
      value: email,
    });
    dispatch({
      type: 'mfield',
      field: 'address1',
      value: address1,
    });
    dispatch({
      type: 'mfield',
      field: 'address2',
      value: address2,
    });
    dispatch({
      type: 'mfield',
      field: 'city',
      value: city,
    });
    dispatch({
      type: 'mfield',
      field: 'code',
      value: code,
    });
    dispatch({
      type: 'mfield',
      field: 'province',
      value: province,
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    contactNumberPresent('txtTelephone', 'txtCellphone');
    contactNumberPresent('txtMTelephone', 'txtMCellphone');
    event.target.className += ' was-validated';
    if (!isFormValid(event)) return;
    nextStep();
  };

  React.useEffect(() => {
    document.getElementById('txtTelephone').oninput = () =>
      contactNumberPresent('txtTelephone', 'txtCellphone');
    document.getElementById('txtCellphone').oninput = () =>
      contactNumberPresent('txtTelephone', 'txtCellphone');
    document.getElementById('txtMTelephone').oninput = () =>
      contactNumberPresent('txtMTelephone', 'txtMCellphone');
    document.getElementById('txtMCellphone').oninput = () =>
      contactNumberPresent('txtMTelephone', 'txtMCellphone');
    // returned function will be called on component unmount
    return () => {
      document.getElementById('txtTelephone').oninput = undefined;
      document.getElementById('txtCellphone').oninput = undefined;
      document.getElementById('txtMTelephone').oninput = undefined;
      document.getElementById('txtMCellphone').oninput = undefined;
    };
  }, [mainMember.medicalAid]);

  return (
    <MDBAnimation type="bounce">
      <MDBCard className="mt-3 mb-5">
        <MDBCardBody>
          <h5 className="pb-2">Patient Details</h5>
          <Box mb={5}>
            <Divider className="orange" />
          </Box>
          <form
            className="needs-validation"
            onSubmit={submitHandler}
            noValidate
          >
            <MDBRow>
              <MDBCol md="2" className="mb-2">
                <InputLabel id="demo-simple-select-label">Title</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={title}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'title',
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value="Mr.">Mr.</MenuItem>
                  <MenuItem value="Mrs.">Mrs.</MenuItem>
                  <MenuItem value="Ms.">Ms.</MenuItem>
                  <MenuItem value="Dr.">Dr.</MenuItem>
                  <MenuItem value="Rev.">Rev.</MenuItem>
                  <MenuItem value="Prof.">Prof</MenuItem>
                </Select>
              </MDBCol>
              <MDBCol md="4" className="mb-2">
                <MDBInput
                  label="Name"
                  icon="user"
                  type="text"
                  validate
                  value={name}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'name',
                      value: e.target.value,
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'initials',
                      value: e.target.value
                        .split(' ')
                        .map((x) => x.substring(0, 1))
                        .join(''),
                    })
                  }
                />
                {/* <div className="invalid-feedback">Name is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput> */}
              </MDBCol>
              <MDBCol md="2" className="mb-2">
                <MDBInput
                  label="Initials"
                  icon="user"
                  type="text"
                  validate
                  value={initials}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'initials',
                      value: e.target.value,
                    })
                  }
                />
                {/* <div className="invalid-feedback">Name is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput> */}
              </MDBCol>
              <MDBCol md="4" className="mb-2">
                <MDBInput
                  label="Surname"
                  icon="user-tag"
                  type="text"
                  validate
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'surname',
                      value: e.target.value,
                    })
                  }
                  value={surname}
                  required
                  containerClass="form-sm"
                >
                  <div className="invalid-feedback">Surname is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="2" className="mb-2">
                <MDBInput
                  // onClick={handleRadioClick('M')}
                  checked={identificationType === 'idnumber' ? true : false}
                  label="SA ID"
                  type="radio"
                  id="radio1I"
                  onChange={() =>
                    dispatch({
                      type: 'field',
                      field: 'identificationType',
                      value: 'idnumber',
                    })
                  }
                  required
                  validate
                />{' '}
                <MDBInput
                  // onClick={handleRadioClick('F')}
                  checked={
                    identificationType === 'passportnumber' ? true : false
                  }
                  label="Passport"
                  type="radio"
                  id="radio2I"
                  onChange={() =>
                    dispatch({
                      type: 'field',
                      field: 'identificationType',
                      value: 'passportnumber',
                    })
                  }
                  required
                  validate
                >
                  <div className="invalid-feedback">
                    Identification type selection is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="3" className="mb-2">
                {identificationType === 'idnumber' ? (
                  <MDBInput
                    label="ID Number"
                    icon="id-card"
                    type="text"
                    onChange={(e) =>
                      dispatch({
                        type: 'field',
                        field: 'idnumber',
                        value: e.target.value,
                      })
                    }
                    onBlur={(event) =>
                      handleBirthday(
                        new Date(getbirthdayFromID(event.target.value))
                      )
                    }
                    validate
                    //pattern="(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))"
                    value={idnumber}
                    //required
                    containerClass="form-sm"
                  />
                ) : (
                  //   <div className="invalid-feedback">
                  //     ID number or passport number is required and must be
                  //     valid.
                  //   </div>
                  //   <div className="valid-feedback">Looks good!</div>
                  // </MDBInput>
                  <MDBInput
                    label="Passport Number"
                    icon="id-card"
                    type="text"
                    onChange={(e) =>
                      dispatch({
                        type: 'field',
                        field: 'passportnumber',
                        value: e.target.value,
                      })
                    }
                    // required
                    validate
                    //pattern="^(?!^0+$)[a-zA-Z0-9]{3,20}$"
                    value={passportnumber}
                    containerClass="form-sm"
                  />
                  //   <div className="invalid-feedback">
                  //     Passport number must be valid.
                  //   </div>
                  //   <div className="valid-feedback">Looks good!</div>
                  // </MDBInput>
                )}
              </MDBCol>
              <MDBCol md="2" className="mb-2">
                <MDBInput
                  // onClick={handleRadioClick('M')}
                  checked={gender === 'male' ? true : false}
                  label="Male"
                  type="radio"
                  id="radio1G"
                  onChange={() =>
                    dispatch({
                      type: 'field',
                      field: 'gender',
                      value: 'male',
                    })
                  }
                  required
                  validate
                />{' '}
                <MDBInput
                  // onClick={handleRadioClick('F')}
                  checked={gender === 'female' ? true : false}
                  label="Female"
                  type="radio"
                  id="radio2G"
                  onChange={() =>
                    dispatch({
                      type: 'field',
                      field: 'gender',
                      value: 'female',
                    })
                  }
                  required
                  validate
                >
                  <div className="invalid-feedback">
                    Gender selection is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="3" className="mb-2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    required
                    disableFuture
                    format="dd-MM-yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Birthday"
                    value={birthday}
                    onChange={handleBirthday}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  >
                    <div className="invalid-feedback">
                      Birthday is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </KeyboardDatePicker>
                </MuiPickersUtilsProvider>
              </MDBCol>
              <MDBCol md="2" className="mb-2">
                <MDBInput
                  label="Dep. Code"
                  icon="id-card"
                  type="text"
                  value={dependantCode}
                  validate
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'dependantCode',
                      value: e.target.value,
                    })
                  }
                  containerClass="form-sm"
                >
                  <div className="invalid-feedback">
                    Dependant code is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6" className="mb-2">
                <MDBInput
                  type="text"
                  label="Address line 1"
                  icon="map-marker-alt"
                  required
                  validate
                  value={address1}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'address1',
                      value: e.target.value,
                    })
                  }
                >
                  <div className="invalid-feedback">Address is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="6" className="mb-2">
                <MDBInput
                  type="text"
                  label="Address line 2"
                  icon="map-marker"
                  validate
                  value={address2}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'address2',
                      value: e.target.value,
                    })
                  }
                >
                  <div className="invalid-feedback">Address is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="4">
                <MDBInput
                  type="text"
                  label="City"
                  icon="city"
                  validate
                  required
                  value={city}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'city',
                      value: e.target.value,
                    })
                  }
                >
                  <div className="invalid-feedback">City is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  type="text"
                  label="Code"
                  icon="map-marked-alt"
                  pattern="\d{4}"
                  validate
                  value={code}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'code',
                      value: e.target.value,
                    })
                  }
                >
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <Box mt={1}>
                  <InputLabel id="ddlprov-label">Province</InputLabel>
                  <Select
                    style={{ width: '25ch' }}
                    required
                    error={province.trim().length === 0}
                    labelId="ddlprov-label"
                    id="ddlProv"
                    value={province}
                    onChange={(e) =>
                      dispatch({
                        type: 'field',
                        field: 'province',
                        value: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="N/A ">N/A</MenuItem>
                    <MenuItem value="Gauteng">Gauteng</MenuItem>
                    <MenuItem value="Eastern Cape">Eastern Cape</MenuItem>
                    <MenuItem value="Free State">Free State</MenuItem>
                    <MenuItem value="Kwa-Zulu/Natal">Kwa-Zulu/Natal</MenuItem>
                    <MenuItem value="Limpopo">Limpopo</MenuItem>
                    <MenuItem value="Mpumalanga">Mpumalanga</MenuItem>
                    <MenuItem value="North West">North West</MenuItem>
                    <MenuItem value="Northern Cape">Northern Cape</MenuItem>
                    <MenuItem value="Western Cape">Western Cape</MenuItem>
                  </Select>
                </Box>
              </MDBCol>
            </MDBRow>
            <MDBRow>
            <MDBCol md="12">
                <MDBInput
                  type="text"
                  label="Country"
                  icon="country"
                  validate
                  required
                  value={country}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'country',
                      value: e.target.value,
                    })
                  }
                >
                  <div className="invalid-feedback">Country is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="4">
                <MDBInput
                  label="Email"
                  icon="envelope"
                  type="email"
                  validate
                  value={email}
                  containerClass="form-sm"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'email',
                      value: e.target.value,
                    })
                  }
                />
                {/* <div className="invalid-feedback">
                    Valid email is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput> */}
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  id="txtTelephone"
                  label="Telephone"
                  icon="phone"
                  type="text"
                  validate
                  pattern="^(\+27|0)[0-9]{9}$"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'phone',
                      value: e.target.value,
                    })
                  }
                  value={phone}
                  containerClass="form-sm"
                >
                  <div className="invalid-feedback">
                    A valid telephone or cellphone or both is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  id="txtCellphone"
                  label="Cellphone"
                  icon="mobile"
                  type="text"
                  pattern="^(\+27|0)[6-8][0-9]{8}$"
                  validate
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'cellphone',
                      value: e.target.value,
                    })
                  }
                  value={cellphone}
                  containerClass="form-sm"
                >
                  <div className="invalid-feedback">
                    A valid cellphone or telephone or both is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <h5 className="pb-2">Payment Details</h5>
            <Box mb={5}>
              <Divider className="orange" />
            </Box>
            <MDBRow>
              <MDBCol>
                <Box mb={2}>
                  <MDBInput
                    //onClick={onClick('Cash')}
                    checked={mainMember.paymentType === 'Cash' ? true : false}
                    label="Cash"
                    type="radio"
                    id="radio1"
                    containerClass="mr-5"
                    onChange={() =>
                      dispatch({
                        type: 'mfield',
                        field: 'paymentType',
                        value: 'Cash',
                      })
                    }
                  />
                  <MDBInput
                    //onClick={onClick('Medical Aid')}
                    checked={
                      mainMember.paymentType === 'Medical Aid' ? true : false
                    }
                    onChange={() =>
                      dispatch({
                        type: 'mfield',
                        field: 'paymentType',
                        value: 'Medical Aid',
                      })
                    }
                    label="Medical Aid"
                    type="radio"
                    id="radio2"
                    containerClass="mr-5"
                  >
                    <div className="invalid-feedback">
                      Payment type selection is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput>
                </Box>
              </MDBCol>
              <MDBCol>
                <MDBBtn color="black" type="button" onClick={handleCopy}>
                  Copy from patient
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="1" className="mb-2">
                <Box mt={1}>
                  <InputLabel id="ddlMMTitle-label">Title</InputLabel>
                  <Select
                    labelId="ddlMMTitle-label"
                    id="ddlMMTitle"
                    value={mainMember.title}
                    onChange={(e) =>
                      dispatch({
                        type: 'mfield',
                        field: 'title',
                        value: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="Mr.">Mr.</MenuItem>
                    <MenuItem value="Mrs.">Mrs.</MenuItem>
                    <MenuItem value="Ms.">Ms.</MenuItem>
                    <MenuItem value="Dr.">Dr.</MenuItem>
                    <MenuItem value="Rev.">Rev.</MenuItem>
                    <MenuItem value="Prof.">Prof</MenuItem>
                  </Select>
                </Box>
              </MDBCol>
              <MDBCol md="4" className="mb-2">
                <MDBInput
                  label="Name"
                  icon="user"
                  type="text"
                  validate
                  value={mainMember.name}
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'name',
                      value: e.target.value,
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'initials',
                      value: e.target.value
                        .split(' ')
                        .map((x) => x.substring(0, 1))
                        .join(''),
                    })
                  }
                  containerClass="form-sm"
                />
                {/* <div className="invalid-feedback">Name is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput> */}
              </MDBCol>
              <MDBCol md="2" className="mb-2">
                <MDBInput
                  label="Initials"
                  icon="user"
                  type="text"
                  validate
                  value={mainMember.initials}
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'initials',
                      value: e.target.value,
                    })
                  }
                  required
                >
                  <div className="invalid-feedback">Name is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4" className="mb-2">
                <MDBInput
                  label="Surname"
                  icon="user-tag"
                  type="text"
                  validate
                  value={mainMember.surname}
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'surname',
                      value: e.target.value,
                    })
                  }
                  required
                  containerClass="form-sm"
                >
                  <div className="invalid-feedback">Surname is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="2" className="mb-2">
                <MDBInput
                  //onClick={onClick('Cash')}
                  checked={
                    mainMember.identificationType === 'idnumber' ? true : false
                  }
                  label="SA ID"
                  type="radio"
                  id="radio1ID"
                  containerClass="mr-5"
                  onChange={() =>
                    dispatch({
                      type: 'mfield',
                      field: 'identificationType',
                      value: 'idnumber',
                    })
                  }
                />
                <MDBInput
                  //onClick={onClick('Medical Aid')}
                  checked={
                    mainMember.identificationType === 'passportnumber'
                      ? true
                      : false
                  }
                  onChange={() =>
                    dispatch({
                      type: 'mfield',
                      field: 'identificationType',
                      value: 'passportnumber',
                    })
                  }
                  label="Passport"
                  type="radio"
                  id="radio2ID"
                  containerClass="mr-5"
                >
                  <div className="invalid-feedback">
                    Identification type selection is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="3" className="mb-2">
                {mainMember.identificationType === 'idnumber' ? (
                  <MDBInput
                    label="ID Number"
                    icon="id-card"
                    type="text"
                    onChange={(e) =>
                      dispatch({
                        type: 'mfield',
                        field: 'idnumber',
                        value: e.target.value,
                      })
                    }
                    validate
                    pattern="(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))"
                    value={mainMember.idnumber}
                    required
                    containerClass="form-sm"
                  >
                    <div className="invalid-feedback">
                      ID number or passport number is required and must be
                      valid.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput>
                ) : (
                  <MDBInput
                    label="Passport Number"
                    icon="id-card"
                    type="text"
                    onChange={(e) =>
                      dispatch({
                        type: 'mfield',
                        field: 'passportnumber',
                        value: e.target.value,
                      })
                    }
                    validate
                    pattern="^(?!^0+$)[a-zA-Z0-9]{3,20}$"
                    required
                    value={mainMember.passportnumber}
                    containerClass="form-sm"
                  >
                    <div className="invalid-feedback">
                      Passport number must be valid.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput>
                )}
              </MDBCol>
              <MDBCol md="4" className="mb-2">
                <MDBInput
                  type="text"
                  label="Address line 1"
                  icon="map-marker-alt"
                  required
                  validate
                  value={mainMember.address1}
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'address1',
                      value: e.target.value,
                    })
                  }
                >
                  <div className="invalid-feedback">Address is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="3" className="mb-2">
                <MDBInput
                  type="text"
                  label="Address line 2"
                  icon="map-marker"
                  validate
                  value={mainMember.address2}
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'address2',
                      value: e.target.value,
                    })
                  }
                >
                  <div className="invalid-feedback">Address is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="4">
                <MDBInput
                  type="text"
                  label="City"
                  icon="city"
                  validate
                  required
                  value={mainMember.city}
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'city',
                      value: e.target.value,
                    })
                  }
                >
                  <div className="invalid-feedback">City is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  type="text"
                  label="Code"
                  icon="map-marked-alt"
                  pattern="\d{4}"
                  validate
                  value={mainMember.code}
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'code',
                      value: e.target.value,
                    })
                  }
                >
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <Box mt={1}>
                  <InputLabel id="ddlMMprov-label">Province</InputLabel>
                  <Select
                    labelId="ddlMMprov-label"
                    id="ddlMMProv"
                    required
                    error={mainMember.province.trim().length === 0}
                    style={{ width: '25ch' }}
                    value={mainMember.province}
                    onChange={(e) =>
                      dispatch({
                        type: 'mfield',
                        field: 'province',
                        value: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="N/A">N/A</MenuItem>
                    <MenuItem value="Gauteng">Gauteng</MenuItem>
                    <MenuItem value="Eastern Cape">Eastern Cape</MenuItem>
                    <MenuItem value="Free State">Free State</MenuItem>
                    <MenuItem value="Kwa-Zulu/Natal">Kwa-Zulu/Natal</MenuItem>
                    <MenuItem value="Limpopo">Limpopo</MenuItem>
                    <MenuItem value="Mpumalanga">Mpumalanga</MenuItem>
                    <MenuItem value="North West">North West</MenuItem>
                    <MenuItem value="Northern Cape">Northern Cape</MenuItem>
                    <MenuItem value="Western Cape">Western Cape</MenuItem>
                  </Select>
                </Box>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="4">
                <MDBInput
                  type="text"
                  label="Country"
                  icon="country"
                  validate
                  required
                  value={mainMember.country}
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'country',
                      value: e.target.value,
                    })
                  }
                >
                  <div className="invalid-feedback">country is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="4" className="mb-2">
                <MDBInput
                  label="Email"
                  icon="envelope"
                  type="email"
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'email',
                      value: e.target.value,
                    })
                  }
                  validate
                  value={mainMember.email}
                  containerClass="form-sm"
                />
                {/* <div className="invalid-feedback">
                    Valide email is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput> */}
              </MDBCol>
              <MDBCol md="4" className="mb-2">
                <MDBInput
                  id="txtMTelephone"
                  label="Telephone"
                  icon="mobile"
                  type="text"
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'phone',
                      value: e.target.value,
                    })
                  }
                  validate
                  pattern="^(\+27|0)[0-9]{9}$"
                  value={mainMember.phone}
                  containerClass="form-sm"
                >
                  <div className="invalid-feedback">
                    A valid telephone or cellphone number is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4" className="mb-2">
                <MDBInput
                  id="txtMCellphone"
                  label="Cellphone"
                  icon="phone"
                  type="text"
                  onChange={(e) =>
                    dispatch({
                      type: 'mfield',
                      field: 'cellphone',
                      value: e.target.value,
                    })
                  }
                  validate
                  pattern="^(\+27|0)[6-8][0-9]{8}$"
                  value={mainMember.cellphone}
                  containerClass="form-sm"
                >
                  <div className="invalid-feedback">
                    A valid telephone or cellphone number is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            {mainMember.paymentType === 'Medical Aid' && (
              <MDBRow>
                <MDBCol md="4" className="mb-2">
                  <Box mt={2}>
                    <Autocomplete
                      value={medicalAids[mainMember.mmIndex]}
                      style={{ width: 250 }}
                      options={medicalAids}
                      getOptionLabel={(option) => option.descr}
                      onSelect={(event) => handleTag(event)}
                      autoHighlight
                      renderInput={(params) => (
                        <TextField
                          error={
                            mainMember.paymentType === 'Medical Aid' &&
                            mainMember.medicalAid.length === 0
                          }
                          {...params}
                          label="Medical Aids"
                          fullWidth
                        >
                          <div className="invalid-feedback">
                            Medical aid is required.
                          </div>
                          <div className="valid-feedback">Looks good!</div>
                        </TextField>
                      )}
                    />
                  </Box>
                </MDBCol>
                <MDBCol md="4" className="mb-2">
                  <MDBInput
                    id="txtMedicalAidNumber"
                    label="Medical Number"
                    icon="laptop-medical"
                    type="text"
                    value={mainMember.medicalAidNumber}
                    onChange={(e) =>
                      dispatch({
                        type: 'mfield',
                        field: 'medicalAidNumber',
                        value: e.target.value,
                      })
                    }
                    required
                    validate
                    containerClass="form-sm"
                  >
                    <div className="invalid-feedback">
                      Medical number is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="4" className="mb-2">
                  <MDBInput
                    id="txtMedicalAidPlan"
                    label="Option/Plan"
                    icon="hospital-symbol"
                    onChange={(e) =>
                      dispatch({
                        type: 'mfield',
                        field: 'option',
                        value: e.target.value,
                      })
                    }
                    type="text"
                    validate
                    //required
                    value={mainMember.option}
                    containerClass="form-sm"
                  />
                  {/* <div className="invalid-feedback">
                      Option/plan is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput> */}
                </MDBCol>
              </MDBRow>
            )}
            <div className="text-center mb-3">
              <MDBBtn color="warning" type="submit">
                Next
              </MDBBtn>
              <MDBBtn color="black" type="button">
                Cancel
              </MDBBtn>
            </div>
          </form>
        </MDBCardBody>
      </MDBCard>
    </MDBAnimation>
  );
}
export default AddPatient;
