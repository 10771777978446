import MatrixRenderer from '../MatrixRenderer';
import Position from '../Position';
import { Boundary } from '../PDFRenderer';
import { TextRenderer } from './TextRenderer';
import { QuoteRenderer } from './QuoteRenderer';
import StyleRange from '../Range';

export class AtomicRenderer extends MatrixRenderer {
  static TYPE_IMAGE = 'IMAGE';

  #textRender;

  constructor() {
    super();
    this.#textRender = new TextRenderer();
  }

  async render(matrix, boundary, prevMatrix, renderingState) {
    var matrixColumns = matrix.columns;

    for (var pos = 0; pos < matrixColumns.length; pos++) {
      var column = matrixColumns[pos];
      switch (column.type) {
        case AtomicRenderer.TYPE_IMAGE:
          var addMargin =
            prevMatrix === undefined ? false : !prevMatrix.hasPadding;
          var value = column.value;
          var boundaryText = new Boundary(
            boundary.getWidth() * 0.5,
            new Position(0, 0)
          );
          var prevMatrixDisplacement = 0;
          if (
            prevMatrix !== undefined &&
            prevMatrix.type === 'atomic' &&
            prevMatrix.columnPosition < 1
          ) {
            var newWidth = boundary.getWidth() * 0.5;
            renderingState.setPosition(
              prevMatrix.startPosition,
              prevMatrix.pageNumber
            );
            prevMatrixDisplacement = prevMatrix.displacement;
            boundary = new Boundary(
              boundary.getWidth(),
              new Position(newWidth, 0)
            );
            boundaryText = new Boundary(
              boundary.getWidth() * 0.5,
              new Position(newWidth, 0)
            );

            matrix.columnPosition = prevMatrix.columnPosition + 1;
          } else {
            matrix.columnPosition = 0;
          }

          // console.log(
          //   'rendering image',
          //   renderingState.getPosition().copy(),
          //   renderingState.getCurrentPage(),
          //   prevMatrix.displacement
          // );

          matrix.startPosition = renderingState.getPosition().copy();
          matrix.pageNumber = renderingState.getPageNumber();

          var displacementObj = await this.renderImage(
            {
              url: value.url,
              alignment: value.alignment,
            },
            boundary,
            renderingState,
            addMargin
          );
          var displacement = displacementObj.height;

          matrix.displacement = displacement;
          renderingState.getPosition().setX(0);

          matrix.value = value.caption;
          matrix.columns = this.getCaptionColums(value.caption);
          boundaryText.setWidth(displacementObj.width);
          var textDisplacement = this.#textRender.render(
            matrix,
            boundaryText,
            prevMatrix,
            renderingState,
            QuoteRenderer.COLOR_TEXT
          );
          renderingState.movePositionY(
            renderingState.getLineHeight(),
            boundary
          );

          matrix.displacement += textDisplacement;
          //matrix.displacement += renderingState.getLineHeight();

          var displacementDiff = matrix.displacement - prevMatrixDisplacement;
          if (displacementDiff < 0) {
            // console.log(
            //   'displacement',
            //   matrix.displacement,
            //   prevMatrixDisplacement,
            //   displacementDiff
            // );
            matrix.displacement = prevMatrixDisplacement;
            //renderingState.movePositionY(-displacementDiff, boundary);
          }

          break;
        default:
          continue;
      }
    }

    return this;
  }

  getCaptionColums(caption) {
    return [
      {
        type: 'TEXT',
        range: new StyleRange(0, caption.length, 'ITALIC'),
        data: undefined,
      },
    ];
  }
}
