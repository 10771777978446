import React from 'react';
import { connect } from 'react-redux';
import {
  MDBBtn,
  MDBCard,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBAnimation,
  MDBInput,
  MDBContainer,
} from 'mdbreact';
import * as actions from '../../store/actions/index';
import Image from '../../assets/bg1.jpg';
import './style.css';
//import { config } from '../../Constants';
import { isFormValid } from '../../shared/utility';
import Error from '../Error';

function ConfirmEmail(props) {
  // const endUrl = config.url.API_URL + '/api/v1/auth/resendconfirmemail';
  const [email, setEmail] = React.useState('');
  // const [emailSent, setEmailSent] = React.useState(false);
  //const [error, setError] = React.useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    e.target.className += ' was-validated';
    if (!isFormValid(e)) return;
    props.onResendEmailConfirmation(email);
  };

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol md="6" className="offset-md-3" style={{ maxWidth: '40rem' }}>
          <MDBCard
            className="text-center card-image text-white  d-flex"
            style={{
              backgroundImage: `url(${Image})`,
            }}
          >
            <div className="rgba-black-strong py-3 px-4">
              <div>
                <h5 className="orange-text">
                  <MDBIcon icon="check-double" /> Confirm Email
                </h5>
                <MDBCardTitle tag="h3" className="pt-2">
                  <MDBAnimation type="tada">
                    <strong>Hello</strong>
                  </MDBAnimation>
                </MDBCardTitle>
                <p>
                  Thanks so much for registering on SHP Labs Portal! To finish
                  signing up, you just need to confirm that we got your email
                  right.
                </p>
                <div>
                  Did not receive confirmation email or it has expired? No
                  worries, enter email below and click submit to resend
                  confirmation email.
                  {props.emailSent ? (
                    <MDBAnimation type="tada">
                      <h3>Confirmation email sent successfully!</h3>
                    </MDBAnimation>
                  ) : (
                    <div>
                      <form
                        className="needs-validation"
                        onSubmit={submitHandler}
                        noValidate
                      >
                        <MDBInput
                          background="white"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                          id="txtEmail"
                          name="email"
                          label="Email"
                          validate
                          required
                        >
                          <div className="invalid-feedback">
                            Please provide a valid email.
                          </div>
                          <div className="valid-feedback">Looks good!</div>
                        </MDBInput>
                        <MDBBtn
                          type="submit"
                          color="orange"
                          rounded
                          className="btn-block z-depth-1a"
                        >
                          <MDBIcon icon="paper-plane" />
                          Submit
                        </MDBBtn>
                      </form>
                      <Error
                        show={props.error}
                        error={props.error}
                        clearErr={() => props.onAuthClearError()}
                      />
                    </div>
                  )}
                </div>
                <hr className="my-5" />
                <MDBBtn href="/" color="deep-orange">
                  <MDBIcon icon="clone left" /> Home
                </MDBBtn>
              </div>
            </div>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    emailSent: state.auth.emailSent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResendEmailConfirmation: (email) =>
      dispatch(actions.resendConfirmEmailStart(email)),
    onAuthClearError: () => dispatch(actions.authClearError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
