

export default class StyleRange{
    startIndex;
    endIndex;
    length;
    styles
    styleHash
    color;

    constructor(pStartIndex, pEndIndex, ...pStyles){
        this.startIndex = pStartIndex;
        this.endIndex = pEndIndex;
        this.styles = pStyles
        this.styleHash = "";
        this.styles.forEach(style=>{
            this.styleHash += style + "|";
        });
    }
    
    getStartIndex(){
        return this.startIndex;
    }

    getEndIndex(){
        return this.endIndex;
    }

    getLength(){
        return this.endIndex - this.startIndex;
    }

    getStyles(){
        return this.styles;
    }

    setColor(pColor){
        this.color = pColor;
        return this;
    }

    getColor(){
        return this.color;
    }

    isValid(){ return this.getLength() >= 0}

    isEmpty(){ return this.getLength() === 0; }

    getStyleHash(){
        return this.styleHash;
    }

    static sameRange(r1, r2){
        return (r1.getStartIndex() === r2.getStartIndex()) && (r1.getEndIndex() === r2.getEndIndex());
    }


    extract(indexStart, indexEnd, styles, includeMid = true, includeEnds = true){
        var result = [];

        var rangeMid = new StyleRange(indexStart, indexEnd, ...this.getStyles(), ...styles);
        if(!rangeMid.isEmpty() && rangeMid.isValid() && includeMid)
            result.push(rangeMid);

        var rangeLeft = new StyleRange(this.startIndex, indexStart, ...this.getStyles());
        if(!rangeLeft.isEmpty() && rangeLeft.isValid() && includeEnds)
            result.push(rangeLeft);

        var rangeRight = new StyleRange(indexEnd, this.endIndex, ...this.getStyles());
        if(!rangeRight.isEmpty() && rangeRight.isValid() && includeEnds)
            result.push(rangeRight);
        
        return result;
    }

    static Intersects(range1, range2){
         return !(range1.getStartIndex() >= range2.getEndIndex() || range2.getStartIndex() >= range1.getEndIndex());
    }

    /*static Union(range1, range2){
        var indexStart = Math.max(range1.getStartIndex(), range2.getStartIndex());
        var indexEnd = Math.min(range1.getEndIndex(), range2.getEndIndex());

        var result = [];
        result = result.concat(range1.extract(indexStart, indexEnd, range2.getStyles()).result);
        result = result.concat(range2.extract(indexStart, indexEnd, range1.getStyles(), false).result);

        return result;
    }*/

    static UnionLeft(range1, range2){
        var indexStart = Math.max(range1.getStartIndex(), range2.getStartIndex());
        var indexEnd = Math.min(range1.getEndIndex(), range2.getEndIndex());

        return range1.extract(indexStart, indexEnd, range2.getStyles());
    }

}