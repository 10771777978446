import React, { useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBBtn,
  MDBAnimation,
  MDBAlert,
  MDBSwitch,
} from 'mdbreact';
import { convertToRaw } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

function FormComments(props) {
  const {
    onChange,
    prevStep,
    nextStep,
    report,
    comments,
    animation,
    handleCheckChange,
    optcomments,
  } = props;
  const defaultTheme = createTheme();

  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          marginTop: 5,
          minHeight: 150,
        },
        editor: {
          borderBottom: '1px solid gray',
        },
      },
    },
  });

  const [pError, setPerror] = useState(false);

  const onClose = () => setPerror(false);

  const nextClick = () => {
    if (optcomments)
      if (
        Object.values(JSON.parse(comments))[0].filter(function (e) {
          return e.text.trim() !== '';
        }).length > 0
      ) {
        props.updateReport(
          {
            comments,
          },
          props.reportId
        );
        nextStep();
      } else {
        setPerror(true);
        return;
      }
    else nextStep();
  };

  return (
    <MDBContainer className="mt-2 mb-3">
      <MDBAnimation reveal type={animation}>
        <MDBRow>
          <MDBCol className="mt-3">
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Comments</MDBCardTitle>
                {pError && (
                  <MDBAlert color="danger" dismiss onClosed={onClose}>
                    <strong>Error!</strong> Please enter some text.
                  </MDBAlert>
                )}
                <MuiThemeProvider theme={defaultTheme}>
                  <MDBSwitch
                    checked={optcomments}
                    onChange={handleCheckChange}
                  />
                  {optcomments && (
                    <MUIRichTextEditor
                      label="Type something here..."
                      onChange={(state) =>
                        onChange(
                          'comments',
                          JSON.stringify(
                            convertToRaw(state.getCurrentContent())
                          )
                        )
                      }
                      inlineToolbar={true}
                      value={report.comments}
                      controls={[
                        'bold',
                        'italic',
                        'underline',
                        'media',
                        'upload-image',
                      ]}
                    />
                  )}
                </MuiThemeProvider>
                <MDBBtn color="black" variant="contained" onClick={prevStep}>
                  Back
                </MDBBtn>
                <MDBBtn color="orange" variant="contained" onClick={nextClick}>
                  Continue
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBAnimation>
    </MDBContainer>
  );
}
export default FormComments;
