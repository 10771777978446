import React, { useReducer } from 'react';
//import { useHistory } from 'react-router-dom';
import {
  MDBAnimation,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSwitch,
  MDBTimePicker,
} from 'mdbreact';
import {
  Box,
  Divider,
  TextField,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Success from '../../Success';
// import Error from '../../Error';
// import Spinner from '../../Spinner';
import { isFormValid } from '../../../shared/utility';
import DateFnsUtils from '@date-io/date-fns';

const initialState = {
  selIndex: -1,
  patient: '',
  doctors: '',
  urgent: false,
  specimenType: '',
  histologyNo: '',
  specimenNo: '',
  icd10code: '',
  specimenDate: new Date(),
  specimenTime: '',
  specimenNature: '',
  clinicalData: '',
  hospitalPatient: false,
  hospitalName: '',
  hospitalWard: '',
  copyDoctors: '',
  selectedCopyDoctors: [],
  copyDoctorsDetail: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return initialState;
    }
    case 'field': {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case 'date': {
      return {
        ...state,
        specimenDate: action.value,
      };
    }
    default:
  }
};

function AddSpecimen({
  Doctors,
  patient,
  loading,
  error,
  onAddPatient,
  onAddSpecimen,
  onClearDoctor,
  onClearError,
  ...props
}) {
  //const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    selIndex,
    urgent,
    specimenType,
    histologyNo,
    specimenNo,
    icd10code,
    specimenDate,
    specimenTime,
    specimenNature,
    //clinicalData,
    hospitalPatient,
    hospitalName,
    hospitalWard,
    doctors,
    selectedCopyDoctors,
  } = state;

  const handleSpecimenDate = (e) => {
    dispatch({
      type: 'date',
      value: e,
    });
  };

  const handleTag = (event, value) => {
    if (value) {
      const index = Doctors.findIndex((x) => x.mnemonic === value.mnemonic);
      if (index !== -1) {
        dispatch({
          type: 'field',
          value: value.id,
          field: 'doctors',
        });
        dispatch({
          type: 'field',
          value: index,
          field: 'selIndex',
        });
      }
    } else {
      dispatch({
        type: 'field',
        value: '',
        field: 'doctors',
      });
      dispatch({
        type: 'field',
        value: -1,
        field: 'selIndex',
      });
    }
  };

  const handleTag2 = (event, value) => {
    if (value.length > 0) {
      Doctors.findIndex((x) => x.mnemonic === value.mnemonic);
      const indexes = value.map((x) =>
        Doctors.findIndex((y) => y.mnemonic === x.mnemonic)
      );
      const cdocs = indexes.map((x) => Doctors[x]);
      const copydrs = value.map((x) => x.email).join(',');
      const copydrsdetail = value.map((x) => x.id);
      dispatch({
        type: 'field',
        value: copydrs,
        field: 'copyDoctors',
      });
      dispatch({
        type: 'field',
        value: cdocs,
        field: 'selectedCopyDoctors',
      });
      dispatch({
        type: 'field',
        value: copydrsdetail,
        field: 'copyDoctorsDetail',
      });
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    event.target.className += ' was-validated';
    if (!isFormValid(event)) return;
    onAddPatient(patient.mainMember, patient, state);
    // while (loading) {}
    // history.push('/patients/');
  };

  return (
    <MDBAnimation type="bounce">
      <MDBCard className="mt-3 mb-5">
        <MDBCardBody>
          <h5 className="pb-5">Specimen Details</h5>
          <Box mb={5}>
            <Divider className="orange" />
          </Box>
          <form
            className="needs-validation"
            onSubmit={submitHandler}
            noValidate
          >
            <MDBRow>
              <MDBCol md="6" className="mb-2">
                <Autocomplete
                  value={Doctors[selIndex]}
                  style={{ width: 370 }}
                  options={Doctors}
                  getOptionLabel={(option) =>
                    option.mnemonic +
                    ' - Dr. ' +
                    option.name +
                    ' ' +
                    option.surname
                  }
                  // onBlur={(event) => handleBlur(event)}
                  onChange={(event, value) => handleTag(event, value)}
                  autoHighlight
                  renderOption={({
                    mnemonic,
                    name,
                    surname,
                    location,
                    email,
                    ...props
                  }) => {
                    return (
                      <div>
                        <Tooltip
                          title={`${email} - ${location.formattedAddress}`}
                          placement="bottom"
                        >
                          <div>
                            {mnemonic} - {`Dr ${name} ${surname}`}
                          </div>
                        </Tooltip>
                      </div>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Doctors"
                      margin="normal"
                      error={!!!doctors}
                      required
                    />
                  )}
                />
              </MDBCol>
              <MDBCol md="6" className="mb-2">
                <Box mt={2}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={Doctors}
                    getOptionLabel={(option) =>
                      option.mnemonic +
                      ' - Dr. ' +
                      option.name +
                      ' ' +
                      option.surname
                    }
                    onChange={(event, value) => handleTag2(event, value)}
                    value={selectedCopyDoctors}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Copy Doctors"
                        placeholder="Select copy Doctors"
                      />
                    )}
                  />
                </Box>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="2" className="mb-2">
                <Box mt={2}>
                  <label>Urgent</label>
                  <MDBSwitch
                    labelLeft="No"
                    labelRight="Yes"
                    checked={urgent}
                    onChange={() =>
                      dispatch({
                        type: 'field',
                        field: 'urgent',
                        value: !urgent,
                      })
                    }
                  />
                </Box>
              </MDBCol>
              <MDBCol md="2" className="mb-2">
                <Box mt={2}>
                  <label>Hospital Patient</label>
                  <MDBSwitch
                    labelLeft="No"
                    labelRight="Yes"
                    checked={hospitalPatient}
                    onChange={() =>
                      dispatch({
                        type: 'field',
                        field: 'hospitalPatient',
                        value: !hospitalPatient,
                      })
                    }
                  />
                </Box>
              </MDBCol>
              <MDBCol md="4" className="mb-2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    required
                    disableFuture
                    format="dd-MM-yyyy"
                    margin="normal"
                    id="dtSpecimenDate"
                    label="Specimen Date"
                    value={specimenDate}
                    onChange={handleSpecimenDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  >
                    <div className="invalid-feedback">
                      Specimen date is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </KeyboardDatePicker>
                </MuiPickersUtilsProvider>
              </MDBCol>
              <MDBCol md="4" className="mb-2">
                <div>
                  <MDBTimePicker
                    id="dtSpecimenTime"
                    name="specimenTime"
                    label="Specimen Time"
                    hours={12}
                    minutes={0}
                    hoursFormat={24}
                    icon="clock"
                    validate
                    error="wrong"
                    success="right"
                    value={specimenTime}
                    getValue={(value) =>
                      dispatch({
                        type: 'field',
                        field: 'specimenTime',
                        value: value,
                      })
                    }
                  />
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="3" className="mb-2">
                <Box mt={1}>
                  <InputLabel id="ddlspecType-label">Specimen Type</InputLabel>
                  <Select
                    style={{ width: '25ch' }}
                    required
                    error={specimenType.trim().length === 0}
                    labelId="ddlspecType-label"
                    id="ddlSpecType"
                    value={specimenType}
                    onChange={(e) =>
                      dispatch({
                        type: 'field',
                        field: 'specimenType',
                        value: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="HIS">Histology</MenuItem>
                    <MenuItem value="CYT">Cytology</MenuItem>
                  </Select>
                </Box>
              </MDBCol>
              <MDBCol md="3" className="mb-2">
                <MDBInput
                  required
                  name="histologyNo"
                  label="Histology Number"
                  icon="vial"
                  type="text"
                  validate
                  value={histologyNo}
                  onChange={(event) =>
                    dispatch({
                      type: 'field',
                      field: 'histologyNo',
                      value: event.target.value,
                    })
                  }
                >
                  <div className="invalid-feedback">
                    Histology number is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="3" className="mb-2">
                <MDBInput
                  required
                  name="specimenNo"
                  label="Specimen Number"
                  icon="vial"
                  type="text"
                  validate
                  value={specimenNo}
                  onChange={(event) =>
                    dispatch({
                      type: 'field',
                      field: 'specimenNo',
                      value: event.target.value,
                    })
                  }
                >
                  <div className="invalid-feedback">
                    Specimen number is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="3" className="mb-2">
                <MDBInput
                  name="icd10code"
                  label="ICD10 Code"
                  icon="laptop-medical"
                  type="text"
                  validate
                  value={icd10code}
                  onChange={(event) =>
                    dispatch({
                      type: 'field',
                      field: 'icd10code',
                      value: event.target.value,
                    })
                  }
                ></MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  required
                  name="specimenNature"
                  type="textarea"
                  label="Nature of Sepcimen"
                  icon="book-medical"
                  value={specimenNature}
                  onChange={(event) =>
                    dispatch({
                      type: 'field',
                      field: 'specimenNature',
                      value: event.target.value,
                    })
                  }
                  validate
                >
                  <div className="invalid-feedback">
                    Nature of specimen number is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            {/* <MDBRow>
              <MDBCol>
                <MDBInput
                  required
                  name="clinicalData"
                  type="textarea"
                  label="Clinical Data"
                  icon="notes-medical"
                  value={clinicalData}
                  onChange={(event) =>
                    dispatch({
                      type: 'field',
                      field: 'clinicalData',
                      value: event.target.value,
                    })
                  }
                  validate
                >
                  <div className="invalid-feedback">
                    Clinical data is required.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow> */}

            {hospitalPatient && (
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    required
                    validate
                    name="hospitalName"
                    label="Hospital Name"
                    icon="hospital"
                    type="text"
                    value={hospitalName}
                    onChange={(event) =>
                      dispatch({
                        type: 'field',
                        field: 'hospitalName',
                        value: event.target.value,
                      })
                    }
                  >
                    <div className="invalid-feedback">
                      Hospital name number is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput>
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    //required
                    name="hospitalWard"
                    label="Hospital Ward"
                    icon="id-card-alt"
                    type="text"
                    value={hospitalWard}
                    onChange={(event) =>
                      dispatch({
                        type: 'field',
                        field: 'hospitalWard',
                        value: event.target.value,
                      })
                    }
                  />
                  {/* <div className="invalid-feedback">
                      Hospital ward number is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput> */}
                </MDBCol>
              </MDBRow>
            )}
            <div className="text-center mb-3">
              <hr className="my-5" />
              <MDBBtn color="warning" type="submit">
                Save
              </MDBBtn>
              <MDBBtn color="black" type="button">
                Cancel
              </MDBBtn>
            </div>
          </form>
        </MDBCardBody>
      </MDBCard>
    </MDBAnimation>
  );
}
export default AddSpecimen;
