import * as actionTypes from './actionTypes';

export const addDoctorStart = (doctor, specimen) => {
  return {
    type: actionTypes.ADD_DOCTOR_START,
    doctor,
    specimen,
  };
};
export const addDoctorSuccess = (doctor) => {
  return {
    type: actionTypes.ADD_DOCTOR_SUCCESS,
    doctor,
  };
};

export const addDoctorFail = (error) => {
  return {
    type: actionTypes.ADD_DOCTOR_FAIL,
    error,
  };
};

export const editDoctorStart = (doctor) => {
  return {
    type: actionTypes.EDIT_DOCTOR_START,
    doctor,
  };
};
export const editDoctorSuccess = (doctor) => {
  return {
    type: actionTypes.EDIT_DOCTOR_SUCCESS,
    doctor,
  };
};

export const editDoctorFail = (error) => {
  return {
    type: actionTypes.EDIT_DOCTOR_FAIL,
    error,
  };
};

export const clearDoctor = () => {
  return {
    type: actionTypes.CLEAR_DOCTOR,
  };
};

export const clearDoctorError = () => {
  return {
    type: actionTypes.CLEAR_DOCTOR_ERROR,
  };
};

export const fetchDoctorStart = (doctorId) => {
  return {
    type: actionTypes.FETCH_DOCTOR_START,
    doctorId,
  };
};

export const fetchDoctorSuccess = (doctor) => {
  return {
    type: actionTypes.FETCH_DOCTOR_SUCCESS,
    doctor,
  };
};

export const fetchDoctorFail = (error) => {
  return {
    type: actionTypes.FETCH_DOCTOR_FAIL,
    error: error,
  };
};

export const fetchDoctorsStart = () => {
  return {
    type: actionTypes.FETCH_DOCTORS_START,
  };
};

export const fetchDoctorsSuccess = (doctors) => {
  return {
    type: actionTypes.FETCH_DOCTORS_SUCCESS,
    doctors,
  };
};

export const fetchDoctorsFail = (error) => {
  return {
    type: actionTypes.FETCH_DOCTORS_FAIL,
    error: error,
  };
};
