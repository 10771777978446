import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    TextField,
    Tooltip
  } from '@material-ui/core';
import { cmp } from '../../shared/utility';

function CytoAutocomplete (props) {
    const {id, texts, vIndex, filter, handleTag, label, pkey} = props;
return <Autocomplete key={pkey}
id={id}
value={texts[vIndex]}
options={texts.filter(x => x.heading.descr === filter).sort(cmp)}
getOptionLabel={(option) =>
  option.code +
  ' - ' +
  option.displayText
}
// onBlur={(event) => handleBlur(event)}
onChange={(event, value) => handleTag(event, value)}
autoHighlight
renderOption={({
  code,
  heading,
  displayText,
  text,
  ...props
}) => {
  return (
    <div>
      <Tooltip
        title={`${code} - ${heading.descr}`}
        placement="bottom"
      >
        <div>
          {displayText} - {text}
        </div>
      </Tooltip>
    </div>
  );
}}
renderInput={(params) => (
  <TextField
    {...params}
    label={label}
    margin="normal"
    error={!!!texts}
    required
  />
)}
/>
}
export default CytoAutocomplete;