import MatrixRenderer from '../MatrixRenderer';
import Position from '../Position';
import { TextRenderer } from './TextRenderer';

export class ListItemRenderer extends MatrixRenderer {
  static DEFAULT_BAR = '  •  ';

  #columnBar;
  #textRenderer;

  constructor() {
    super();
    this.#textRenderer = new TextRenderer();
  }

  render(matrix, boundary, prevMatrix, renderingState) {
    var bar = ListItemRenderer.DEFAULT_BAR;
    if (matrix.type === 'ordered-list-item') {
      if (prevMatrix !== undefined && prevMatrix.type === 'ordered-list-item') {
        var itemPosition = prevMatrix.position + 1;
        bar = ' ' + itemPosition + '. ';
        matrix.position = itemPosition;
      } else {
        bar = '  1. ';
        matrix.position = 1;
      }
    }

    var barDimensions = renderingState.getTextDimensions(bar);
    var tab = Math.max(renderingState.getTabSize(), barDimensions.width);

    var boundaryWidth = boundary.getWidth();
    var widthTextBoundary = Math.max(0, boundaryWidth - 2 * tab);
    var widthBarBoundary = widthTextBoundary + 2 * barDimensions.width;
    var positionTextBoundary = new Position(tab, 0, boundary.getPosition());
    var positionBarBoundary = new Position(
      tab - barDimensions.width,
      0,
      boundary.getPosition()
    );

    var boundaryText = renderingState.requestBoundary(
      positionTextBoundary,
      widthTextBoundary
    );
    var boundaryBar = renderingState.requestBoundary(
      positionBarBoundary,
      widthBarBoundary
    );

    var prevMatrixType =
      prevMatrix === undefined ? matrix.type : prevMatrix.type;
    var prevHasPadding =
      prevMatrix === undefined ? false : prevMatrix.hasPadding;
    var offsetY =
      (prevMatrixType === matrix.type ? -1 : prevHasPadding ? 0 : 1) *
      renderingState.getLineHeight();
      
    renderingState.getPosition().setX(0);
    renderingState.movePositionY(offsetY, boundary);
    this.renderText(
      {
        text: bar,
        type: 'text',
        styles: ['Bold'],
        extraData: undefined,
      },
      boundaryBar,
      renderingState
    );

    renderingState.getPosition().setX(0);
    this.#textRenderer.render(matrix, boundaryText, prevMatrix, renderingState);

    var currentPosition = renderingState.getPosition();
    currentPosition.incrementY(renderingState.getLineHeight());
    currentPosition.setX(0);

    return this;
  }
}
