import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBCardBody,
  MDBInput,
  MDBFooter,
} from 'mdbreact';
import Copyrights from './Copyrights';

export default function Footer(props) {
  return (
    <MDBFooter className="pt-5 mt-4 text-center text-md-left grey">
      <MDBContainer>
        <section id="contact">
          <MDBRow>
            <MDBCol lg="8">
              <MDBCardBody className="form">
                <h3 className="mt-4">
                  <MDBIcon icon="envelope" className="pr-2" />
                  Write to us:
                </h3>
                <MDBRow>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        id="form-contact-name"
                        label="Your name"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        id="form-contact-email"
                        label="Your email"
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        id="form-contact-phone"
                        label="Your phone"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        id="form-contact-company"
                        label="Your company"
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="12">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="textarea"
                        id="form-contact-message"
                        label="Your message"
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="6" className="offset-md-3">
                    <div className="md-form mb-0">
                      <MDBBtn rounded color="black" className="float-left">
                        <MDBIcon icon="send-o" />
                        Send
                      </MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCol>
            <MDBCol lg="4">
              <MDBCardBody className="contact text-center h-100">
                <h3 className="my-4 pb-2">Contact information</h3>
                <ul className="text-lg-left list-unstyled ml-4">
                  <li>
                    <p>
                      <MDBIcon icon="map-marker-alt" className="pr-2" />
                      78 First Avenue, Dunvegan, Edenvale, Gauteng, 1609
                    </p>
                  </li>
                  <li>
                    <p>
                      <MDBIcon icon="phone" className="pr-2" />+ 27 83 340 0037
                    </p>
                  </li>
                  <li>
                    <p>
                      <MDBIcon icon="envelope" className="pr-2" />
                      info@shplabs.co.za
                    </p>
                  </li>
                </ul>
                <hr className="hr-light my-4" />
                <ul className="list-inline text-center list-unstyled">
                  <li className="list-inline-item">
                    <a href="#!" className="p-2 fa-lg w-ic">
                      <MDBIcon fab icon="twitter" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#!" className="p-2 fa-lg w-ic">
                      <MDBIcon fab icon="linkedin" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#!" className="p-2 fa-lg w-ic">
                      <MDBIcon fab icon="instagram" />
                    </a>
                  </li>
                </ul>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>
      <Copyrights />
    </MDBFooter>
  );
}
