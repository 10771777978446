import MatrixRenderer from "../MatrixRenderer";


export
class HeaderRenderer extends MatrixRenderer{
    static HEADER_SIZE = 22;

     render(matrix, boundary, prevMatrix, renderingState){
        
        var text = matrix.value;
        var matrixColumns = matrix.columns;
        var fontSize = matrix.fontSize || HeaderRenderer.HEADER_SIZE;
        var lastFontSize = renderingState.getFontSize();
        renderingState.setFontSize(fontSize)
        
        if(prevMatrix === undefined || !prevMatrix.hasPadding)
            renderingState.movePositionY(renderingState.getLineHeight(), boundary, false);
        else if(!renderingState.movePositionY(0, boundary)){
            renderingState.movePositionY(renderingState.getLineHeight(), boundary);
        }

        renderingState.getPdfDocument().setTextColor("#000000");

        if(text.length > 0){
            matrixColumns.forEach(matrixColumn => {
                var styleRange = matrixColumn.range;
                var subText = text.substring(styleRange.getStartIndex(), styleRange.getEndIndex());
                this.renderText({
                    text: subText,
                    type: matrixColumn.type,
                    styles: styleRange.getStyles(),
                    extraData: matrixColumn.data
                }
                , boundary
                , renderingState);
             });
        }

        var currentPosition = renderingState.getPosition();
        currentPosition.incrementY(2 * renderingState.getLineHeight());
        currentPosition.setX(0);
        renderingState.setFontSize(lastFontSize);

        return this;
    }
}