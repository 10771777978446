import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBInput,
  MDBBtn,
  MDBCardBody,
  MDBModalFooter,
  MDBAnimation,
} from 'mdbreact';
//import Image from '../../../assets/bg1.jpg';
import {
  isFormValid,
  passwordValidation,
  passwordEqual,
} from '../../../shared/utility';
import Error from '../../Error';
import Spinner from '../../Spinner';

function SignIn(props) {
  const [isSignUP, setIsSignUP] = useState('login');
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [animation, setAnimation] = useState('bounce');

  React.useEffect(() => {
    if (
      document.getElementById('txtPassword') &&
      document.getElementById('txtPasswordConfirm')
    ) {
      document.getElementById('txtPassword').oninput = passwordValidation;
      document.getElementById('txtPasswordConfirm').oninput = passwordEqual;
    }
    // returned function will be called on component unmount
    return () => {
      if (
        document.getElementById('txtPassword') &&
        document.getElementById('txtPasswordConfirm')
      ) {
        document.getElementById('txtPassword').oninput = undefined;
        document.getElementById('txtPasswordConfirm').oninput = undefined;
      }
    };
  }, [isSignUP]);

  const changeHandler = (event) => {
    switch (event.target.name) {
      case 'displayName':
        setDisplayName(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      case 'password1':
        setPassword1(event.target.value);
        break;
      default:
    }
  };

  const switchAuthModeHandler = () => {
    isSignUP === 'login'
      ? setIsSignUP('register')
      : isSignUP === 'register'
      ? setIsSignUP('login')
      : setIsSignUP('register');
    isSignUP === 'login'
      ? setAnimation('flipInY')
      : isSignUP === 'register'
      ? setAnimation('flip')
      : setAnimation('flipInX');
    return false;
  };

  const forgotPasswordHandler = (event) => {
    event.preventDefault();
    setIsSignUP('forgot');
    setAnimation('flipInX');
    return false;
  };

  const submitHandler = (event) => {
    event.preventDefault();
    event.target.className += ' was-validated';
    if (!isFormValid(event)) return;
    props.onAuth(displayName, email, password, isSignUP);
  };

  const forgotPasswordSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += ' was-validated';
    if (!isFormValid(event)) return;
    props.onForgotPassword(email);
  };

  let errorMessage = null;

  if (props.error) {
    errorMessage = (
      <Error
        show={props.error}
        error={props.error}
        clearErr={props.onClearError}
      />
    );
  }

  const rform =
    isSignUP === 'register' ? (
      <form
        id="frmRegister"
        className="needs-validation"
        onSubmit={submitHandler}
        noValidate
      >
        <div className="text-center">
          <h3 className="dark-grey-text mb-5">
            <strong>Sign up</strong>
          </h3>
        </div>
        <div className="grey-text">
          <MDBInput
            id="txtDisplayName"
            name="displayName"
            value={displayName}
            onChange={changeHandler}
            type="text"
            label="Your name"
            icon="user"
            validate
            required
          >
            <div className="invalid-feedback">Provide name and surname.</div>
            <div className="valid-feedback">Looks good!</div>
          </MDBInput>
        </div>
        <div className="grey-text">
          <MDBInput
            id="txtEmail"
            name="email"
            value={email}
            onChange={changeHandler}
            type="email"
            label="Your email"
            icon="envelope"
            validate
            required
          >
            <div className="invalid-feedback">Provide a valid email.</div>
            <div className="valid-feedback">Looks good!</div>
          </MDBInput>
        </div>
        <div className="grey-text">
          <MDBInput
            id="txtPassword"
            name="password"
            value={password}
            onChange={changeHandler}
            type="password"
            label="Your password"
            icon="lock"
            validate
            required
          >
            <div className="invalid-feedback">
              Ensure password is at least 8 charcaters long, contains
              alpha-numeric and special character.
            </div>
            <div className="valid-feedback">Looks good!</div>
          </MDBInput>
        </div>{' '}
        <div className="grey-text">
          <MDBInput
            id="txtPasswordConfirm"
            name="password1"
            value={password1}
            onChange={changeHandler}
            type="password"
            label="Confirm password"
            icon="exclamation"
            validate
            required
          >
            <div className="invalid-feedback">
              Re-type password and ensure they are the same.
            </div>
            <div className="valid-feedback">Looks good!</div>
          </MDBInput>
        </div>
        <div className="text-center mb-3">
          <MDBBtn
            form="frmRegister"
            type="submit"
            gradient="cloudy-knoxville-gradient"
            rounded
            className="btn-block z-depth-1a"
          >
            Register
          </MDBBtn>
        </div>
      </form>
    ) : isSignUP === 'login' ? (
      <form
        id="frmLogin"
        className="needs-validation"
        onSubmit={submitHandler}
        noValidate
      >
        <div className="text-center">
          <h3 className="dark-grey-text mb-5">
            <strong>Sign in</strong>
          </h3>
        </div>
        <div className="grey-text">
          <MDBInput
            id="txtEmail"
            icon="envelope"
            name="email"
            value={email}
            onChange={changeHandler}
            type="email"
            label="Your email"
            validate
            required
            error="wrong"
            success="right"
          >
            <div className="invalid-feedback">
              You must enter your email address before submitting.
            </div>
            <div className="valid-feedback">Looks good!</div>
          </MDBInput>
        </div>
        <div className="grey-text">
          <MDBInput
            id="txtPassword"
            icon="lock"
            name="password"
            value={password}
            onChange={changeHandler}
            type="password"
            label="Your password"
            validate
            required
            error="wrong"
            success="right"
          >
            <div className="invalid-feedback">
              You must enter your password before submitting.
            </div>
            <div className="valid-feedback">Looks good!</div>
          </MDBInput>
        </div>
        <p className="font-small black-text d-flex justify-content-end pb-3">
          Forgot
          <a
            href="#!"
            className="blue-text ml-1"
            onClick={(event) => forgotPasswordHandler(event)}
          >
            Password?
          </a>
        </p>
        <div className="text-center mb-3">
          <MDBBtn
            form="frmLogin"
            type="submit"
            gradient="cloudy-knoxville-gradient"
            rounded
            className="btn-block z-depth-1a"
          >
            Sign in
          </MDBBtn>
        </div>
      </form>
    ) : (
      <form
        id="frmForgotPassword"
        className="needs-validation "
        onSubmit={forgotPasswordSubmitHandler}
        noValidate
      >
        <div className="text-center">
          <h3 className="dark-grey-text mb-5">
            <strong>Forgot password</strong>
          </h3>
        </div>
        <div className="grey-text">
          <MDBInput
            id="txtEmail"
            name="email"
            value={email}
            onChange={changeHandler}
            type="email"
            label="Your email"
            icon="envelope"
            required
            validate
          >
            <div className="invalid-feedback">
              You must enter your password before submitting.
            </div>
            <div className="valid-feedback">Looks good!</div>
          </MDBInput>
        </div>
        <div className="text-center mb-3">
          <MDBBtn
            form="frmForgotPassword"
            type="submit"
            gradient="cloudy-knoxville-gradient"
            rounded
            className="btn-block z-depth-1a"
          >
            Send
          </MDBBtn>
          {props.loading && <Spinner />}
          {props.emailSent && (
            <MDBAnimation type="tada">
              <h3>Password reset email sent successfully</h3>
            </MDBAnimation>
          )}
        </div>
      </form>
    );

  let authRedirect = null;

  if (props.isAuthenticated || props.isRegistered)
    authRedirect = <Redirect to={props.authRedirectPath} />;
  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol md="6" className="offset-md-3">
          {errorMessage}
          {authRedirect}
          <MDBAnimation type={animation}>
            <MDBCard>
              <MDBCardBody className="mx-4">{rform}</MDBCardBody>
              <MDBModalFooter className="mx-5 pt-3 mb-1">
                <p className="font-small grey-text d-flex justify-content-end">
                  {isSignUP === 'register'
                    ? 'Registered? '
                    : 'Not registered? '}
                  <a
                    href="#!"
                    onClick={() => switchAuthModeHandler()}
                    className="blue-text ml-1"
                  >
                    {' '}
                    {isSignUP === 'register'
                      ? 'Switch to sign in'
                      : 'Switch to sign up'}
                  </a>
                </p>
              </MDBModalFooter>
            </MDBCard>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    isRegistered: state.auth.isRegistered,
    emailSent: state.auth.emailSent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (displayName, email, password, isSignup) =>
      dispatch(actions.auth(displayName, email, password, isSignup)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
    onForgotPassword: (email) => dispatch(actions.forgotPasswordStart(email)),
    onClearError: () => dispatch(actions.authClearError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
