import React from 'react';
import { connect } from 'react-redux';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { useHistory } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import Spinner from '../../Spinner';
import SpecimenInfo from '../../Patient/SpecimenInfo';
import AlertModal from '../../AlertModal';

export const NewSpecimen = ({
  match: {
    params: { patientId },
  },
  doctors,
  loading,
  error,
  onAddSpecimen,
  derror,
  dloading,
  ...props
}) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [alertType, setAlertType] = React.useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!!props.success) {
      props.onResetSpecimen();
      setOpen(false);
      history.push(`/patients/${patientId}`);
    } else {
      props.onClearError();
      setOpen(false);
    }
  };

  if (!!props.error && !open) {
    handleOpen();
    setTitle('Error');
    setMessage(props.error);
    setAlertType('error');
  }

  if (props.success && !open) {
    handleOpen();
    setTitle('Success');
    setMessage('Changes saved successfully!');
    setAlertType('success');
  }

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol md="10" className="offset-md-1">
          {doctors ? (
            <>
              <SpecimenInfo
                Doctors={doctors}
                justSpecimen={true}
                onAddSpecimen={onAddSpecimen}
                error={error}
                Patient={patientId}
                specimen={loading}
              />
              <AlertModal
                alertType={alertType}
                open={open}
                onClose={handleClose}
                title={title}
                message={message}
              />
            </>
          ) : (
            <Spinner />
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    doctors: state.doctor.doctors,
    loading: state.specimen.loading,
    error: state.specimen.error,
    dloading: state.doctor.loading,
    derror: state.doctor.error,
    success: state.specimen.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddSpecimen: (specimen) => dispatch(actions.addSpecimenStart(specimen)),
    onClearError: () => dispatch(actions.clearError()),
    onAddError: (error) => dispatch(actions.addSpecimenFail(error)),
    onResetSpecimen: () => dispatch(actions.resetSpecimen()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSpecimen);
