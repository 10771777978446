import MatrixRenderer from "../MatrixRenderer";


export
class TextRenderer extends MatrixRenderer{

     render(matrix, boundary, prevMatrix, renderingState, textColor = MatrixRenderer.COLOR_TEXT){
        var text = matrix.value;
        var matrixColumns = matrix.columns;

        var displacement = 0;
        if(text.length > 0){
            matrixColumns.forEach(matrixColumn => {
                var styleRange = matrixColumn.range;
                var subText = text.substring(styleRange.getStartIndex(), styleRange.getEndIndex());
                renderingState.getPdfDocument().setTextColor(textColor);
                displacement += this.renderText({
                    text: subText,
                    type: matrixColumn.type,
                    styles: styleRange.getStyles(),
                    extraData: matrixColumn.data,
                    color: styleRange.getColor()
                }
                , boundary
                , renderingState);
             });
        }

        var currentPosition = renderingState.getPosition();
        currentPosition.incrementY(renderingState.getLineHeight());
        currentPosition.setX(0);

        return displacement;
    }
}