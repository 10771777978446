

export default
class Position{
    #x
    #y


    constructor(x = 0, y = 0, positionAnchor = undefined){
        var anchorX = positionAnchor !== undefined ? positionAnchor.getX() : 0;
        var anchorY = positionAnchor !== undefined ? positionAnchor.getY() : 0;

        this.#x = anchorX + x;
        this.#y = anchorY + y;
    }

    setX(x){ this.#x = x; }
    setY(y){ this.#y = y; }

    incrementX(inc){ this.#x += inc; }
    incrementY(inc){ this.#y += inc; }

    getX(){ return this.#x; }
    getY(){ return this.#y; }

    copy(){
        return new Position(this.#x, this.#y);
    }
}