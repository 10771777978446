import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBFormInline,
} from 'mdbreact';
import { connect } from 'react-redux';
import './Main.css';
import Footer from './Footer/';
import Routes from './Routes';
import { ReactComponent as Logo } from '../assets/shplogo.svg';

function Main(props) {
  const [collapseID, setCollapseID] = useState('');
  const [setCurrentPage] = useState('');
  const [windowWidth] = useState(0);
  const [breakWidth] = useState(1400);

  const toggleCollapse = (ID) => {
    setCollapseID(ID !== collapseID ? ID : '');
  };
  const dynamicLeftPadding = () =>
    windowWidth > breakWidth ? 'paddingLeft: 240px' : 'paddingLeft: 0';
  const assessLocation = (location) => {
    let locationString;
    switch (location) {
      // Dashboards:
      case '/dashboards/v1':
        locationString = 'Dashboard v.1';
        break;
      case '/dashboards/v2':
        locationString = 'Dashboard v.2';
        break;
      case '/dashboards/v3':
        locationString = 'Dashboard v.3';
        break;
      case '/login':
        locationString = 'Sign-in/Sign-up page';
        break;
      default:
    }
    setCurrentPage(locationString);
  };
  let routes = '';
  if (props.role === 'admin')
    routes = (
      <MDBNavbarNav left>
        <MDBNavItem active={window.location.pathname === '/'}>
          <MDBNavLink to="/">Home</MDBNavLink>
        </MDBNavItem>
        <MDBNavItem active={window.location.pathname === '/doctors'}>
          <MDBNavLink to="/doctors/">Doctors</MDBNavLink>
        </MDBNavItem>
        <MDBNavItem active={window.location.pathname === '/patients'}>
          <MDBNavLink to="/patients/">Patients</MDBNavLink>
        </MDBNavItem>
        <MDBNavItem active={window.location.pathname === '/specimens'}>
          <MDBNavLink to="/specimens">Specimens</MDBNavLink>
        </MDBNavItem>
        <MDBNavItem active={window.location.pathname === '/reports'}>
          <MDBNavLink to="/reports">Reports</MDBNavLink>
        </MDBNavItem>
        <MDBNavItem active={window.location.pathname === '/logout'}>
          <MDBNavLink to="/logout">Logout</MDBNavLink>
        </MDBNavItem>
      </MDBNavbarNav>
    );
  else if (props.role === 'publisher')
    routes = (
      <MDBNavbarNav left>
        <MDBNavItem active={window.location.pathname === '/'}>
          <MDBNavLink to="/">Home</MDBNavLink>
        </MDBNavItem>
        <MDBNavItem active={window.location.pathname === '/reports'}>
          <MDBNavLink to="/reports">Reports</MDBNavLink>
        </MDBNavItem>
        <MDBNavItem active={window.location.pathname === '/logout'}>
          <MDBNavLink to="/logout">Logout</MDBNavLink>
        </MDBNavItem>
      </MDBNavbarNav>
    );
  else
    routes = (
      <MDBNavbarNav left>
        <MDBNavItem active={window.location.pathname === '/'}>
          <MDBNavLink to="/">Home</MDBNavLink>
        </MDBNavItem>
        <MDBNavItem active={window.location.pathname === '/login'}>
          <MDBNavLink to="/login">Login</MDBNavLink>
        </MDBNavItem>
      </MDBNavbarNav>
    );
  return (
    <div id="about">
      <MDBNavbar
        color="black"
        dark
        expand="md"
        fixed="top"
        scrolling
        transparent
      >
        <MDBContainer>
          <MDBNavbarBrand>
            <MDBNavbarBrand href="/" className="py-0 font-weight-bold">
              <Logo style={{ height: '3rem', width: '6.5rem' }} />
              <strong className="align-middle">SHP Laboratories</strong>
            </MDBNavbarBrand>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={() => toggleCollapse('navbarCollapse')} />
          <MDBCollapse id="navbarCollapse" isOpen={collapseID} navbar>
            {routes}
            <MDBNavbarNav right>
              <MDBNavItem>
                <MDBFormInline waves>
                  <div className="md-form my-0">
                    <input
                      className="form-control mr-sm-2"
                      type="text"
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </div>
                </MDBFormInline>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
      {window.location.pathname === '/' ? (
        <Routes onChange={() => assessLocation()} />
      ) : (
        <main style={{ ...dynamicLeftPadding, margin: '8rem 6% 6rem' }}>
          <Routes onChange={() => assessLocation()} />
        </main>
      )}
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps, null)(Main);
