import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBadge,
  MDBNavLink,
  MDBNav,
  MDBAnimation,
} from 'mdbreact';
import './PatientHome.css';
import Spinner from '../Spinner';
import Error from '../Error';
import MatTable from '../MatTable';

function PatientHome({
  patients,
  onFetchMedicalAids,
  onFetchPatients,
  onFetchDoctors,
  error,
}) {
  const history = useHistory();
  const handleRowClick = (event, rowData) => {
    history.push(`/patient/${rowData.id}`);
  };

  const scrollToTop = () => window.scrollTo(0, 0);

  const [columns] = useState([
    {
      title: 'Name',
      field: 'name',
      editable: 'never',
      filtering: false,
    },
    { title: 'Surname', field: 'surname', editable: 'never' },
    {
      title: 'ID Number',
      field: 'idnumber',
    },
    {
      title: 'Gender',
      field: 'gender',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Mobile',
      field: 'cellphone',
    },
    {
      title: 'Phone',
      field: 'phone',
    },
    { title: 'id', field: 'id', hidden: true },
  ]);

  useEffect(() => {
    onFetchMedicalAids();
    onFetchPatients();
    onFetchDoctors();
  }, []);

  return (
    <MDBContainer className="mt-3">
      <MDBRow className="py-3">
        <MDBCol md="3">
          <MDBAnimation reveal type="flipInX">
            <MDBNav color="black" className="font-weight-bold py-1 px-2 mb-4">
              <MDBNavLink
                tag="button"
                to="/patients/new"
                color="black"
                className="text-center white-text"
                onClick={scrollToTop}
              >
                Add New Patient
              </MDBNavLink>
            </MDBNav>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
      <MDBRow className="py-3">
        <MDBCol md="12">
          <MDBAnimation reveal type="fadeInLeft">
            <h1>
              <MDBBadge color="warning">Patients</MDBBadge>
            </h1>
            <MDBCard>
              <MDBCardBody>
                {patients ? (
                  <>
                    <Error show={error} error={error} />
                    <MatTable
                      title="Patient Dictionary"
                      columns={columns}
                      data={patients}
                      handleRowClick={handleRowClick}
                    />
                  </>
                ) : (
                  <Spinner />
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    medicalAids: state.patient.medicalAids,
    doctors: state.doctor.doctors,
    patients: state.patient.patients,
    loading: state.patient.loading,
    error: state.patient.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchMedicalAids: () => dispatch(actions.fetchMedicalAidsStart()),
    onFetchPatients: () => dispatch(actions.fetchPatientsStart()),
    onFetchDoctors: () => dispatch(actions.fetchDoctorsStart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientHome);
