import { put, call, delay } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import * as actions from '../actions/index';
import { config } from '../../Constants';

const endUrl = config.url.API_URL;

export function* logoutSaga(action) {
  yield call([localStorage, 'removeItem'], 'token');
  let endPointUrl = endUrl + `/api/v1/auth/logout`;
  yield fetch(endPointUrl, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((d) => d.json());
  yield call([localStorage, 'removeItem'], 'expirationDate');
  yield call([localStorage, 'removeItem'], 'userId');
  yield call([localStorage, 'removeItem'], 'role');
  yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime);
  yield put(actions.logout());
}

export function* authUserSaga(action) {
  yield put(actions.authStart());
  let authData = {
    email: action.email,
    password: action.password,
    displayName: action.displayName,
    role: 'publisher',
  };
  let endPointUrl = endUrl + '/api/v1/auth/register';
  if (action.isSignup === 'login') {
    authData = {
      email: action.email,
      password: action.password,
    };
    endPointUrl = endUrl + `/api/v1/auth/login`;
  }
  let response = {};
  try {
    response = yield fetch(endPointUrl, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(authData),
    })
      .then((d) => d.json())
      .catch((err) => {
        throw err;
      });
    if (response.error) throw response.error;
    if (response.token) {
      var token = response.token;
      var bearer = 'Bearer ' + token;
      var decoded = jwtDecode(token);
      endPointUrl = endUrl + `/api/v1/auth/me`;
      const meresponse = yield fetch(endPointUrl, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: bearer,
        },
      }).then((d) => d.json());
      const userRole = meresponse.user.role;
      localStorage.setItem('token', token);
      localStorage.setItem('expirationDate', decoded.exp);
      localStorage.setItem('userId', decoded.id);
      localStorage.setItem('role', userRole);
      localStorage.setItem('email', action.email);
      yield put(
        actions.authSuccess(response.token, decoded.id, userRole, action.email)
      );
      yield put(actions.checkAuthTimeout(decoded.exp));
    } else {
      yield put(actions.authRegistrationSuccess(action.email));
    }
  } catch (error) {
    yield put(actions.authFail(response.error));
  }
}

export function* authConfirmEmailSaga(action) {
  const endUrl =
    config.url.API_URL +
    `/api/v1/auth/confirmemail/${action.confirmationToken}`;
  try {
    const response = yield fetch(endUrl, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((d) => d.json());
    const token = response.token;
    var decoded = jwtDecode(token);
    var bearer = 'Bearer ' + token;
    const rResponse = yield fetch(config.url.API_URL + `/api/v1/auth/me`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    }).then((d) => d.json());
    const userRole = rResponse.user.role;
    //localStorage.setItem('token', token);
    localStorage.setItem('expirationDate', decoded.exp);
    localStorage.setItem('userId', decoded.id);
    localStorage.setItem('role', userRole);
    yield put(actions.authSuccess(token, decoded.id, userRole));
    yield put(actions.checkAuthTimeout(decoded.exp));
  } catch (error) {
    yield put(actions.confirmEmailFail(error.message));
  }
}

export function* authCheckStateSaga(action) {
  const token = yield localStorage.getItem('token');
  var bearer = 'Bearer ' + token;
  const endPointUrl = config.url.API_URL + `/api/v1/auth/refresh`;
  const meresponse = yield fetch(endPointUrl, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: bearer,
    },
  }).then((d) => d.json());
  if (!meresponse.token) {
    yield put(actions.logout());
  } else {
    var decoded = jwtDecode(meresponse.token);
    const expirationDate = yield new Date(decoded.exp * 1000);
    if (expirationDate <= new Date()) {
      yield put(actions.logout());
    } else {
      const userId = decoded.id;
      const role = meresponse.role;
      const email = meresponse.email;
      yield put(actions.authSuccess(meresponse.token, userId, role, email));
      yield put(
        actions.checkAuthTimeout(
          Math.floor((expirationDate.getTime() - new Date().getTime()) * 1000)
        )
      );
    }
  }
}

export function* authforgotPasswordSaga(action) {
  const endUrl = config.url.API_URL + `/api/v1/auth/forgotpassword`;
  try {
    const response = yield fetch(endUrl, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: action.email }),
    }).then((d) => d.json());
    if (response.error) throw response.error;
    yield put(actions.forgotPasswordSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(actions.forgotPasswordFail(error));
  }
}

export function* authResetPasswordSaga(action) {
  const endUrl =
    config.url.API_URL + `/api/v1/auth/resetpassword/${action.resetToken}`;
  try {
    const response = yield fetch(endUrl, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password: action.password }),
    }).then((d) => d.json());
    if (response.error) throw response.error;
    const token = response.token;
    var decoded = jwtDecode(token);
    var bearer = 'Bearer ' + token;
    const rResponse = yield fetch(config.url.API_URL + `/api/v1/auth/me`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    }).then((d) => d.json());
    const userRole = rResponse.user.role;
    localStorage.setItem('token', token);
    localStorage.setItem('expirationDate', decoded.exp);
    localStorage.setItem('userId', decoded.id);
    localStorage.setItem('role', userRole);
    yield put(actions.authSuccess(token, decoded.id, userRole));
    yield put(actions.checkAuthTimeout(decoded.exp));
  } catch (error) {
    console.log(error);
    if (error === 'Invalid token')
      yield put(
        actions.resetPasswordFail(
          'This email has expired. Click the "Request Email" button below'
        )
      );
    else yield put(actions.resetPasswordFail(error));
  }
}

export function* authResendConfirmationEmailSaga(action) {
  const endUrl = config.url.API_URL + `/api/v1/auth/resendconfirmemail/`;
  try {
    const response = yield fetch(endUrl, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: action.payload }),
    }).then((d) => d.json());
    if (response.error) throw response.error;
    yield put(actions.resendConfirmEmailSuccess());
  } catch (error) {
    yield put(actions.resendConfirmEmailFail(error));
  }
}
