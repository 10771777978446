import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  token: null,
  userEmail: null,
  userId: null,
  role: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  isRegistered: false,
  confirmationToken: null,
  emailSent: null,
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    userEmail: action.email,
    role: action.role,
    error: null,
    loading: false,
  });
};

const authRegistrationSuccess = (state, action) => {
  return updateObject(state, {
    isRegistered: true,
    userEmail: action.email,
    error: null,
    loading: false,
    authRedirectPath: '/confirm',
  });
};

const authConfirmEmailStart = (state, payload) => {
  return updateObject(state, {
    error: null,
    loading: true,
    confirmationToken: payload.confirmationToken,
  });
};

const authConfirmEmailSuccess = (state, payload) => {
  return updateObject(state, {
    error: null,
    loading: false,
    authRedirectPath: '/',
    token: payload.token,
  });
};

const authConfirmEmailFail = (state, payload) => {
  return updateObject(state, {
    error: payload.error,
    loading: false,
    authRedirectPath: '/confirm',
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, { token: null, userId: null, role: null });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const authClearError = (state) => {
  return updateObject(state, { error: null });
};

const authForgotPasswordStart = (state) => {
  return updateObject(state, { error: null, loading: true, emailSent: null });
};

const authForgotPasswordSuccess = (state) => {
  return updateObject(state, { loading: false, emailSent: true });
};

const authForgotPasswordFail = (state, payload) => {
  return updateObject(state, {
    error: payload.error,
    loading: false,
    emailSent: null,
  });
};

const authResetPasswordStart = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const authResetPasswordFail = (state, payload) => {
  return updateObject(state, {
    error: payload.error,
    loading: false,
  });
};

const authResendConfirmationEmailStart = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const authResendConfirmationEmailSuccess = (state) => {
  return updateObject(state, { emailSent: true, loading: false });
};

const authResendConfirmationEmailFail = (state, payload) => {
  return updateObject(state, {
    error: payload.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_REGISTRATION_SUCCESS:
      return authRegistrationSuccess(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.CONFIRM_EMAIL_START:
      return authConfirmEmailStart(state, action);
    case actionTypes.CONFIRM_EMAIL_SUCCESS:
      return authConfirmEmailSuccess(state, action);
    case actionTypes.CONFIRM_EMAIL_FAIL:
      return authConfirmEmailFail(state, action);
    case actionTypes.FORGOT_PASSWORD_START:
      return authForgotPasswordStart(state);
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return authForgotPasswordSuccess(state);
    case actionTypes.FORGOT_PASSWORD_FAIL:
      return authForgotPasswordFail(state, action);
    case actionTypes.RESEND_CONFIRM_EMAIL_START:
      return authResendConfirmationEmailStart(state);
    case actionTypes.RESEND_CONFIRM_EMAIL_SUCCESS:
      return authResendConfirmationEmailSuccess(state);
    case actionTypes.RESEND_CONFIRM_EMAIL_FAIL:
      return authResendConfirmationEmailFail(state, action);
    case actionTypes.RESET_PASSWORD_START:
      return authResetPasswordStart(state);
    case actionTypes.RESET_PASSWORD_FAIL:
      return authResetPasswordFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    case actionTypes.AUTH_CLEAR_ERROR:
      return authClearError(state);
    default:
      return state;
  }
};

export default reducer;
