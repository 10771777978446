import React, { useState, forwardRef } from 'react'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBAnimation,
} from 'mdbreact'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { config } from '../../../../Constants'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

function DoctorReports({ sdata }) {
  const [columns] = useState([
    {
      title: 'Report Title',
      field: 'title',
      editable: 'never',
      filtering: false,
    },
    { title: 'Status', field: 'status', editable: 'never' },
    {
      title: 'Author',
      field: 'Author.displayName',
      editable: 'never',
      filtering: false,
    },
    { title: 'Specimen', field: 'specimen.specimenNo', editable: 'never' },
    {
      title: 'Patient ID',
      field: 'specimen.patient.idnumber',
      editable: 'never',
    },
    {
      title: 'Doctor Mnemonic',
      field: 'specimen.doctors.mnemonic',
      editable: 'never',
    },
    {
      title: 'Report Link',
      field: 'pdf.url',
      editable: 'never',
      render: (row) =>
      row.status === 'Final' ? (
        <a
          href={config.url.API_URL + '/uploads/pdf/' + row.pdf.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Final Report
        </a>
      ) : row.status === 'addended' ? (
        <div>
          <a
            href={config.url.API_URL + '/uploads/pdf/' + row.pdf.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Report
          </a>
          <br/>
          <ul>
          {row.pdfAddendum.map(
              (r, i) => <li key={r._id}><a
              href={config.url.API_URL + '/uploads/pdf/' + r.url}
              target="_blank"
              rel="noopener noreferrer"
            ><span STYLE="font-size:medium">Addendum - {i+1}</span>
            </a></li>
            )}
          </ul>
          
        </div>
      ) : (
        'In progress'
      ),
    },
    { title: 'id', field: 'id', hidden: true },
    { title: 'specimen id', field: 'specimen.id', hidden: true },
  ])

  return (
    <MDBContainer className="mt-3">
      <MDBRow className="py-3">
        <MDBCol md="12">
          <MDBAnimation reveal type="fadeInLeft">
            <MDBCard>
              <MDBCardBody>
                {sdata.length === 0 ? (
                  <h3>No reports</h3>
                ) : (
                  <MaterialTable
                    icons={tableIcons}
                    title="Specimen Reports"
                    columns={columns}
                    data={sdata}
                    options={{
                      filtering: true,
                    }}
                  />
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default DoctorReports
