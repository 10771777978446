import React from 'react';
import { MDBContainer, MDBAlert } from 'mdbreact';

export default function Success(props) {
  return (
    <MDBContainer className="mt-3 mb-5">
      {props.show && (
        <MDBAlert color="success" dismiss onClosed={props.clearMsg}>
          <strong>{props.message}</strong>
        </MDBAlert>
      )}
    </MDBContainer>
  );
}
