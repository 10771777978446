import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import FormCytology from './FormCytology';
import FormMacro from './FormMacro';
import FormMicro from './FormMicro';
import FormDiagnosis from './FormDiagnosis';
import FormComments from './FormComments';
import FormFinal from './FormFinal';
import FormCytoFinal from './FormCytoFinal';
import Spinner from '../../Spinner/';
import Doctor from '../../Doctors';
import Patient from '../../Patient/';
import SpecimenInfo from '../../Patient/SpecimenInfo';
import ICD10Lookup from '../../ICD10Lookup';
import SectionContainer from '../../sectionContainer';
import {
  MDBBtn,
  MDBCollapse,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBLink,
} from 'mdbreact';
import FormClinicalHistory from './FormClinicalHistory';
import FormRecommendations from './FormRecommendations';

function MainForm(props) {
  const { match } = props;
  const [step, setStep] = useState(0);
  const [animation, setAnimaion] = useState('zoomInLeft');
  const [title, setTitle] = useState('Histology Report');
  const [collapseID, setCollapseID] = useState('');
  const [toggleBtn, setToggleBtn] = useState('Show Specimen Info');
  const [activeItemPills, setActiveItemPills] = useState('3');
  const [specimen, setSpecimen] = useState({});
  const [bComments, setbComments] = useState(false);
  const [bRecommendations, setbRecommendations] = useState(false);
  const [icd10codeDescr, setICD10codeDescr] = useState('');
  // const [macroscopic, setMacroscopic] = useState(() =>
  //   JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()))
  // );
  const [clinicalData, setClinicalData] = useState('');
  const [macroscopic, setMacroscopic] = useState('');
  const [specimenNature, setSpecimenNature] = useState('');
  const [adequacy, setAdequacy] = useState('');
  const [flora, setFlora] = useState([]);
  const [background, setBackground] = useState('');
  const [morphologySquamous, setMorphologySquamous] = useState([]);
  const [morphologyGlandular, setMorphologyGlandular] = useState([]);
  const [microscopic, setMicroscopic] = useState('');
  const [diagnosis, setDiagnosis] = useState('');
  const [cytoDiagnosis, setCytoDiagnosis] = useState([]);
  const [cytoSubDiagnosis, setCytoSubDiagnosis] = useState([]);
  const [comments, setComments] = useState('');
  const [recommendations, setRecommendations] = useState('');

  const [addendum, setAddendum] = useState('');

  const handleCheckedChange = (event) => {
    setbComments(event.target.checked);
  };

  const handleRecommendationsCheckedChange = (event) => {
    setbRecommendations(event.target.checked);
  };

  const handleUrgentCheckedChange = (event) => {
    setSpecimen({ ...specimen, urgent: !specimen.urgent });
  };

  const toggleCollapse = (ID) => {
    setCollapseID(ID !== collapseID ? ID : '');
    setToggleBtn(
      ID !== collapseID ? 'Hide Specimen Info' : 'Show Specimen Info'
    );
  };

  const togglePills = (tab) => {
    activeItemPills !== tab && setActiveItemPills(tab);
  };
  const nextStep = () => {
    setAnimaion('zoomInLeft');
    if ((step === 2) && (specimen.reportType === 'CYT')) setICD10codeDescr(props.report.icd10code);
    setStep(step + 1);
  };
  // Go back to prev step
  const prevStep = () => {
    setAnimaion('zoomInRight');
    setStep(step - 1);
  };
  // Go back to prev step
  const onChange = (field, value, idx) => {
    switch (field) {
      case 'adequacy':
        props.updateCytoSelection('report', 'adequacy', { text: `{"blocks":[{"key":"49bv6","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id })
        setAdequacy({ text: `{"blocks":[{"key":"49bv6","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id });
        break;
      case 'flora':
        props.UpdateSelection({ selection: 'report', subSelection: 'flora', text: `{"blocks":[{"key":"73dp9","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id, arrayIndex: value.arrayIndex })
        var newFlora = [...flora, { text: `{"blocks":[{"key":"73dp9","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id }];
        setFlora(newFlora);
        break;
      case 'morphologySquamous':
        props.UpdateSelection({ selection: 'report', subSelection: 'morphologySquamous', text: `{"blocks":[{"key":"b5fki","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id, arrayIndex: value.arrayIndex });
        var newMorphSquamous = [...morphologySquamous, { text: `{"blocks":[{"key":"b5fki","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id }];
        setMorphologySquamous(newMorphSquamous);
        break;
      case 'morphologyGlandular':
        props.UpdateSelection({ selection: 'report', subSelection: 'morphologyGlandular', text: `{"blocks":[{"key":"b3fki","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id, arrayIndex: value.arrayIndex });
        var newMorphGlandular = [...morphologyGlandular, { text: `{"blocks":[{"key":"b3fki","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id }];
        setMorphologyGlandular(newMorphGlandular);
        break;
      case 'cytoDiagnosis':
        props.UpdateSelection({ selection: 'report', subSelection: 'cytoDiagnosis', text: `{"blocks":[{"key":"b22vx","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id, arrayIndex: value.arrayIndex, icd: value.icd })
        var newCytoDiagnosis = [...cytoDiagnosis, { text: `{"blocks":[{"key":"b22vx","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id, icdcode: value.icd }];
        setCytoDiagnosis(newCytoDiagnosis);
        break;
      case 'cytoSubDiagnosis':
        props.UpdateSelection({ selection: 'report', subSelection: 'cytoSubDiagnosis', text: `{"blocks":[{"key":"56y2x","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id, arrayIndex: value.arrayIndex, icd: value.icd })
        var newCytoSubDiagnosis = [...cytoSubDiagnosis, { text: `{"blocks":[{"key":"56y2x","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id, icdcode: value.icd }];
        setCytoSubDiagnosis(newCytoSubDiagnosis);
        break;
      case 'background':
        props.UpdateSelection({selection: 'report', subSelection: 'background', text: `{"blocks":[{"key":"bnfki","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id, arrayIndex: value.arrayIndex });
        var newBackground = [...background, { text: `{"blocks":[{"key":"bnfki","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id }];
        setBackground(newBackground);
        break;
      case 'specimenNature':
        props.updateCytoSelection('report', 'specimenNature', { text: `{"blocks":[{"key":"35ax6","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id });
        setSpecimenNature({ text: `{"blocks":[{"key":"35ax6","text":"${value.text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`, id: value.id });
        break;
      case 'clinicalData':
        //props.updateCytoSelection('report', 'clinicalData', value);
        setClinicalData(value);
        break;
      case 'icd10code':
        setICD10codeDescr(value);
        break;
      case 'title':
        setTitle(value);
        break;
      case 'microscopic':
        setMicroscopic(value);
        break;
      case 'macroscopic':
        setMacroscopic(value);
        break;
      case 'diagnosis':
        setDiagnosis(value);
        break;
      case 'comments':
        setComments(value);
        break;
      case 'recommendations':
        setRecommendations(value);
        break;
      case 'addendum':
        setAddendum(value);
        break;
      default:
        console.log('This is a multi-step form built with React.');
    }
  };

  const wizard = () => {
    switch (step) {
      case 0:
        return (
          <FormClinicalHistory
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
            onChange={onChange}
            title={title}
            clinicalData={clinicalData}
            report={props.report}
            specimenID={match.params.specimenId}
            addReport={props.onAddReport}
            reportId={
              match.params.reportId ? match.params.reportId : props.reportId
            }
            userId={props.userId}
            updateReport={props.onUpdateReport}
          />
        );
      case 1:
        return (
          <FormMacro
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
            onChange={onChange}
            title={title}
            macroscopic={macroscopic}
            report={props.report}
            specimenID={match.params.specimenId}
            addReport={props.onAddReport}
            reportId={props.reportId}
            userId={props.userId}
            updateReport={props.onUpdateReport}
          />
        );
      case 2:
        return (
          <FormMicro
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
            onChange={onChange}
            microscopic={microscopic}
            report={props.report}
            specimenID={match.params.specimenId}
            updateReport={props.onUpdateReport}
            reportId={props.reportId}
          />
        );
      case 3:
        return (
          <FormDiagnosis
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
            diagnosis={diagnosis}
            icd10codeDescr={icd10codeDescr}
            onChange={onChange}
            report={props.report}
            updateReport={props.onUpdateReport}
            reportId={props.reportId}
          />
        );
      case 4:
        return (
          <FormComments
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
            onChange={onChange}
            comments={comments}
            report={props.report}
            optcomments={bComments}
            handleCheckChange={handleCheckedChange}
            updateReport={props.onUpdateReport}
            reportId={props.reportId}
          />
        );
      default:
        return (
          <FormFinal
            onChange={onChange}
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
            title={title}
            report={props.report}
            specimen={specimen}
            optcomments={bComments}
            addendum={addendum}
            updateReport={props.onUpdateReport}
            reportId={props.reportId}
            sendEmail={props.onSendEmail}
          />
        );
    }
  };

  const cytoWizard = () => {
    switch (step) {
      case 0:
        return (
          <FormClinicalHistory
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
            onChange={onChange}
            title={title}
            clinicalData={clinicalData}
            report={props.report}
            specimenID={match.params.specimenId}
            addReport={props.onAddReport}
            reportId={
              match.params.reportId ? match.params.reportId : props.reportId
            }
            userId={props.userId}
            updateReport={props.onUpdateReport}
          />
        );
      case 1:
        return (
          <FormCytology
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
            onChangeMain={onChange}
            title={title}
            specimenNature={specimenNature}
            report={props.report}
            specimenID={match.params.specimenId}
            addReport={props.onAddReport}
            reportId={
              match.params.reportId ? match.params.reportId : props.reportId
            }
            userId={props.userId}
            updateReport={props.onUpdateReport}
            texts={props.texts}
            flora={flora}
            background={background}
            adequacy={adequacy}
            morphologySquamous={morphologySquamous}
            morphologyGlandular={morphologyGlandular}
            cytoDiagnosis={cytoDiagnosis}
            loading={props.Loading}
            AddCytoSelection={props.onAddSelection}
            fetchCytologyTexts={props.onFetchTexts}
            deleteCytoSelection={props.onDeleteCytoSelection}
          />
        );
        case 2:
          return (
            <FormComments
              animation={animation}
              nextStep={nextStep}
              prevStep={prevStep}
              onChange={onChange}
              comments={comments}
              report={props.report}
              optcomments={bComments}
              handleCheckChange={handleCheckedChange}
              updateReport={props.onUpdateReport}
              reportId={props.reportId}
            />
          );
        case 3:
          return (
            <FormRecommendations
              animation={animation}
              nextStep={nextStep}
              prevStep={prevStep}
              onChangeMain={onChange}
              handleCheckChange={handleRecommendationsCheckedChange}
              report={props.report}
              reportId={
                match.params.reportId ? match.params.reportId : props.reportId
              }
              userId={props.userId}
              updateReport={props.onUpdateReport}
              optrecommendations={bRecommendations}
              recommendations={recommendations}
            />
          );
      default:
        return (
          <FormCytoFinal
            onChange={onChange}
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
            title={title}
            report={props.report}
            specimen={specimen}
            bComments={bComments}
            optrecommendations={bRecommendations}
            addendum={addendum}
            updateReport={props.onUpdateReport}
            reportId={props.reportId}
            sendEmail={props.onSendEmail}
          />
        );
    }
  };

  useEffect(() => {
    const { specimenId, reportId } = match.params;
    props.onFetchTexts();
    fetch(`/api/v1/specimens/${specimenId}`)
      .then((res) => res.json())
      .then((json) => {
        let { data } = json;
        setSpecimen(data);
        data.specimenType === 'CYT' && setTitle('Cytology Report');
      })
      .then(() => {
        if (reportId) props.onfetchReport(reportId);
        else props.onResetState();
      })
      .catch((error) => console.log(error));
  }, [match.params]);

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol className="mt-3">
          {!Object.keys(specimen).length ? (
            <Spinner />
          ) : (
            <Fragment>
              <SectionContainer header="Specimen Info">
                <MDBBtn
                  color="black"
                  onClick={() => toggleCollapse('specInfo')}
                  style={{ marginBottom: '1rem' }}
                >
                  {toggleBtn}
                </MDBBtn>
                <MDBCollapse id="specInfo" isOpen={collapseID}>
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="12">
                        <SectionContainer header="Specimen Details">
                          <MDBNav className="nav-pills">
                            <MDBNavItem>
                              <MDBLink
                                to="#"
                                active={activeItemPills === '1'}
                                onClick={() => togglePills('1')}
                                link
                              >
                                Patient
                              </MDBLink>
                            </MDBNavItem>
                            <MDBNavItem>
                              <MDBLink
                                to="#"
                                active={activeItemPills === '2'}
                                onClick={() => togglePills('2')}
                                link
                              >
                                Doctor
                              </MDBLink>
                            </MDBNavItem>
                            <MDBNavItem>
                              <MDBLink
                                to="#"
                                active={activeItemPills === '3'}
                                onClick={() => togglePills('3')}
                                link
                              >
                                Specimen
                              </MDBLink>
                            </MDBNavItem>
                            <MDBNavItem>
                              <MDBLink
                                to="#"
                                active={activeItemPills === '4'}
                                onClick={() => togglePills('4')}
                                link
                              >
                                ICD10 Lookup
                              </MDBLink>
                            </MDBNavItem>
                          </MDBNav>
                          <MDBTabContent activeItem={activeItemPills}>
                            <MDBTabPane tabId="1">
                              <Patient patient={specimen.patient} />
                            </MDBTabPane>
                            <MDBTabPane tabId="2">
                              <Doctor doctor={specimen.doctors} />
                            </MDBTabPane>
                            <MDBTabPane tabId="3">
                              <SpecimenInfo
                                Specimen={specimen}
                                handleUrgentCheckedChange={
                                  handleUrgentCheckedChange
                                }
                              />
                            </MDBTabPane>
                            <MDBTabPane tabId="4">
                              <ICD10Lookup codes={props.codes} />
                            </MDBTabPane>
                          </MDBTabContent>
                        </SectionContainer>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </MDBCollapse>
              </SectionContainer>
              <SectionContainer header="Report Wizard">
                {specimen.specimenType === 'CYT' ? cytoWizard() : wizard()}
              </SectionContainer>
            </Fragment>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    texts: state.report.texts,
    codes: state.report.codes,
    report: state.report.report,
    reportId: state.report.reportId,
    Loading: state.report.loading,
    Error: state.report.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteCytoSelection: (selection, subSelection, value) => dispatch(actions.deleteCytoSelection(selection, subSelection, value)),
    updateCytoSelection: (selection, subSelection, value) => dispatch(actions.updateCytoSingleSelection(selection, subSelection, value)),
    UpdateSelection: (payload) => dispatch(actions.updateCytoSelection(payload)),
    onAddSelection: (payload) => dispatch(actions.addCytoSelection(payload)),
    onFetchTexts: () => dispatch(actions.fetchCytologyTextStart()),
    onFetchICD10: () => dispatch(actions.fetchICD10Start()),
    onfetchReport: (reportId) => dispatch(actions.fetchReportStart(reportId)),
    onAddReport: (report) => dispatch(actions.addReportStart(report)),
    onUpdateReport: (report, reportId) =>
      dispatch(actions.updateReportStart(report, reportId)),
    onClearError: () => dispatch(actions.clearReportError()),
    onResetState: () => dispatch(actions.resetState()),
    onSendEmail: (payload) => dispatch(actions.sendReportStart(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainForm);
