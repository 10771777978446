import * as actionTypes from './actionTypes';

export const fetchPatientsStart = () => {
  return {
    type: actionTypes.FETCH_PATIENTS_START,
  };
};

export const fetchPatientsSuccess = (patients) => {
  return {
    type: actionTypes.FETCH_PATIENTS_SUCCESS,
    patients: patients,
  };
};

export const fetchPatientsFail = (error) => {
  return {
    type: actionTypes.FETCH_PATIENTS_FAIL,
    error: error,
  };
};

export const fetchPatientStart = (patientId) => {
  return {
    type: actionTypes.FETCH_PATIENT_START,
    patientId,
  };
};

export const fetchPatientSuccess = (patient) => {
  return {
    type: actionTypes.FETCH_PATIENT_SUCCESS,
    patient: patient,
  };
};

export const fetchPatientFail = (error) => {
  return {
    type: actionTypes.FETCH_PATIENT_FAIL,
    error: error,
  };
};

export const fetchMedicalAidsStart = () => {
  return {
    type: actionTypes.FETCH_MEDICALAIDS_START,
  };
};

export const fetchMedicalAidsSuccess = (medicalAids) => {
  return {
    type: actionTypes.FETCH_MEDICALAIDS_SUCCESS,
    medicalAids,
  };
};

export const fetchMedicalAidsFail = (error) => {
  return {
    type: actionTypes.FETCH_MEDICALAIDS_FAIL,
    error: error,
  };
};

export const fetchPatients = (token) => {
  return {
    type: actionTypes.FETCH_PATIENTS,
    token,
  };
};

export const addPatientStart = (patient, medicalId, specimen) => {
  return {
    type: actionTypes.ADD_PATIENT_START,
    patient,
    medicalId,
    specimen,
  };
};

export const addPatientSuccess = (patient, mmId, spec) => {
  return {
    type: actionTypes.ADD_PATIENT_SUCCESS,
    patient,
    mmId,
    spec,
  };
};

export const addPatientFail = (error) => {
  return {
    type: actionTypes.ADD_PATIENT_FAIL,
    error: error,
  };
};

export const editPatientStart = (payload) => {
  return {
    type: actionTypes.EDIT_PATIENT_START,
    payload,
  };
};

export const editPatientSuccess = (payload) => {
  return {
    type: actionTypes.EDIT_PATIENT_SUCCESS,
    payload,
  };
};

export const editPatientFail = (error) => {
  return {
    type: actionTypes.EDIT_PATIENT_FAIL,
    error: error,
  };
};

export const addMainMemberStart = (mm, pat, spec) => {
  return {
    type: actionTypes.ADD_MAINMEMBER_START,
    mm,
    pat,
    spec,
  };
};

export const addMainMemberSuccess = (mainmember) => {
  return {
    type: actionTypes.ADD_MAINMEMBER_SUCCESS,
    mainmember,
  };
};

export const addMainMemberFail = (error) => {
  return {
    type: actionTypes.ADD_MAINMEMBER_FAIL,
    error,
  };
};

export const editMainMemberStart = (payload) => {
  return {
    type: actionTypes.EDIT_MAINMEMBER_START,
    payload,
  };
};

export const editMainMemberSuccess = (payload) => {
  return {
    type: actionTypes.EDIT_MAINMEMBER_SUCCESS,
    payload,
  };
};

export const editMainMemberFail = (error) => {
  return {
    type: actionTypes.EDIT_MAINMEMBER_FAIL,
    error,
  };
};

export const clearPatient = () => {
  return {
    type: actionTypes.CLEAR_PATIENT,
  };
};

export const clearError = () => {
  return {
    type: actionTypes.CLEAR_ERROR,
  };
};
