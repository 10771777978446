import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import authReducer from './store/reducers/auth';
import paitentReducer from './store/reducers/patient';
import reportReducer from './store/reducers/report';
import specimenReducer from './store/reducers/specimen';
import doctorReducer from './store/reducers/doctor';

import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {
  watchAuth,
  watchPatient,
  watchReport,
  watchSpecimen,
  watchDoctor,
} from './store/sagas';

const customHistory = createBrowserHistory();
const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  patient: paitentReducer,
  report: reportReducer,
  specimen: specimenReducer,
  doctor: doctorReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchPatient);
sagaMiddleware.run(watchReport);
sagaMiddleware.run(watchSpecimen);
sagaMiddleware.run(watchDoctor);

const app = (
  <Provider store={store}>
    <Router history={customHistory}>
      <App />
    </Router>
    ,
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
