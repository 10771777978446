import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import AboutUs from './pages/AboutUs';
import SignIn from './Auth/Login/';
import Logout from './Auth/Logout/';
import PatientHome from './pages/PatientHome';
import DoctorHome from './pages/DoctorHome';
import Doctor from '../components/Doctors/';
import Patient from './Patient/Patient';
import UserForm from './Patient/NewPatient';
import SpecimensHome from './pages/SpecimensHome';
//const Specimen = React.lazy(() => import('./Specimen/Specimenm'));
import ReportHome from './pages/ReportHome';
import MainForm from './Reports/NewReport/MainForm';
import ConfirmEmail from '../components/ConfirmEmail';
import EmailConfirmed from '../components/EmailConfirmed';
import ResetPassword from '../components/ResetPassword';
import FourOFour from './FourOFour';
import NewSpecimen from '../components/Specimen/NewSpecimen/index';

function Routes(props) {
  let routes = (
    <Switch>
      <Route path="/" exact component={AboutUs} />
      <Route path="/login" exact component={SignIn} />
      <Route path="/confirm" exact component={ConfirmEmail} />
      <Route
        path="/confirmemail/:confirmationtoken"
        component={EmailConfirmed}
      />
      <Route
        path="/resetpassword/:resetPasswordToken"
        component={ResetPassword}
      />
      <Route component={FourOFour} />
    </Switch>
  );
  if (props.role === 'admin')
    routes = (
      <Switch>
        <Route path="/" exact component={AboutUs} />
        <Route exact path="/doctors" component={DoctorHome} />
        <Route path="/doctors/new" render={() => <Doctor add={true} />} />
        <Route
          path="/doctors/:doctorId"
          render={(props) => <Doctor edit={true} {...props} />}
        />
        <Route exact path="/patients" component={PatientHome} />
        <Route
          path="/patient/:patientId"
          render={(props) => <Patient {...props} />}
        />
        <Route path="/patients/new/" component={UserForm} />
        <Route exact path="/specimens" component={SpecimensHome} />
        <Route path="/specimen/new/:patientId" component={NewSpecimen} />
        {/* <Route path="/specimens/:specimenId" componnt={Specimen} /> */}
        <Route exact path="/reports" component={ReportHome} />
        <Route
          path="/reports/new/:specimenId/:reportId"
          exact
          component={MainForm}
        />
        <Route path="/reports/new/:specimenId/" component={MainForm} />
        {/* <Route path="/report/:reportId" component={Report} /> */}
        <Route path="/login" component={SignIn} />
        <Route path="/logout" component={Logout} />
        <Route component={FourOFour} />
      </Switch>
    );
  else if (props.role === 'publisher')
    routes = (
      <Switch>
        <Route path="/" exact component={AboutUs} />
        <Route exact path="/reports" component={ReportHome} />
        <Route path="/login" component={SignIn} />
        <Route path="/logout" component={Logout} />
        <Route component={FourOFour} />
      </Switch>
    );
  return <>{routes}</>;
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps, null)(Routes);
