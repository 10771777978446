import * as actionTypes from './actionTypes';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
    error: null,
  };
};

export const authSuccess = (token, userId, userRole, email) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    role: userRole,
    email,
  };
};

export const authRegistrationSuccess = (email) => {
  return {
    type: actionTypes.AUTH_REGISTRATION_SUCCESS,
    email,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const confirmEmailStart = (token) => {
  return {
    type: actionTypes.CONFIRM_EMAIL_START,
    error: null,
    confirmationToken: token,
    loading: true,
  };
};

export const confirmEmailSuccess = (token) => {
  return {
    type: actionTypes.CONFIRM_EMAIL_SUCCESS,
    token,
    loading: false,
  };
};

export const confirmEmailFail = (error) => {
  return {
    type: actionTypes.CONFIRM_EMAIL_FAIL,
    error,
    loading: false,
  };
};

export const resendConfirmEmailStart = (payload) => {
  return {
    type: actionTypes.RESEND_CONFIRM_EMAIL_START,
    error: null,
    payload,
    loading: true,
  };
};

export const resendConfirmEmailSuccess = () => {
  return {
    type: actionTypes.RESEND_CONFIRM_EMAIL_SUCCESS,
    loading: false,
  };
};

export const resendConfirmEmailFail = (error) => {
  return {
    type: actionTypes.RESEND_CONFIRM_EMAIL_FAIL,
    error,
    loading: false,
  };
};

export const forgotPasswordStart = (email) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_START,
    error: null,
    email,
    loading: true,
  };
};

export const forgotPasswordSuccess = (message) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_SUCCESS,
    message,
    loading: false,
  };
};

export const forgotPasswordFail = (error) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_FAIL,
    error,
    loading: false,
  };
};

export const resetPasswordStart = (resetToken, password) => {
  return {
    type: actionTypes.RESET_PASSWORD_START,
    error: null,
    password,
    loading: true,
    resetToken,
  };
};

export const resetPasswordFail = (error) => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    error,
    loading: false,
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  return {
    type: actionTypes.AUTH_INITIATE_LOGOUT,
  };
};

export const logoutSucceed = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return {
    type: actionTypes.AUTH_CHECK_TIMEOUT,
    expirationTime: expirationTime,
  };
};

export const auth = (displayName, email, password, isSignup) => {
  return {
    type: actionTypes.AUTH_USER,
    displayName,
    email,
    password,
    isSignup,
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const authCheckState = () => {
  return {
    type: actionTypes.AUTH_CHECK_STATE,
  };
};

export const authClearError = () => {
  return {
    type: actionTypes.AUTH_CLEAR_ERROR,
  };
};
