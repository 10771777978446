import * as actionTypes from '../actions/actionTypes';
import {
  updateObject,
  updateObjectInArray,
  removeObjectInArray,
} from '../../shared/utility';

const initialState = {
  loading: false,
  error: null,
  specimens: [],
  success: null,
};

const fetchSpecimensStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const fetchSpecimensSuccess = (state, action) => {
  return updateObject(state, {
    specimens: action.specimens,
    loading: false,
  });
};

const fetchSpecimensFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const fetchPatientSpecimensStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const fetchPatientSpecimensSuccess = (state, action) => {
  return updateObject(state, {
    specimens: action.specimens,
    loading: false,
  });
};

const fetchPatientSpecimensFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const addSpecimenStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const addSpecimenSuccess = (state, action) => {
  return updateObject(state, {
    specimens: updateObjectInArray(state.specimens, { item: action.specimen }),
    loading: false,
    success: true,
  });
};

const addSpecimenFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const editSpecimenStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const editSpecimenSuccess = (state, action) => {
  return updateObject(state, {
    specimens: updateObjectInArray(state.specimens, { item: action.specimen }),
    loading: false,
    success: true,
  });
};

const editSpecimenFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const deleteSpecimenStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const deleteSpecimenSuccess = (state, action) => {
  return updateObject(state, {
    specimens: removeObjectInArray(state.specimens, { item: action.specimen }),
    loading: false,
    success: true,
  });
};

const deleteSpecimenFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const resetSpecimenState = (state) => {
  return updateObject(state, initialState);
};

const resetSpecimenError = (state) => {
  return updateObject(state, {
    error: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SPECIMENS_START:
      return fetchSpecimensStart(state);
    case actionTypes.FETCH_SPECIMENS_SUCCESS:
      return fetchSpecimensSuccess(state, action);
    case actionTypes.FETCH_SPECIMENS_FAIL:
      return fetchSpecimensFail(state, action);
    case actionTypes.FETCH_PATIENT_SPECIMENS_START:
      return fetchPatientSpecimensStart(state);
    case actionTypes.FETCH_PATIENT_SPECIMENS_SUCCESS:
      return fetchPatientSpecimensSuccess(state, action);
    case actionTypes.FETCH_PATIENT_SPECIMENS_FAIL:
      return fetchPatientSpecimensFail(state, action);
    case actionTypes.ADD_SPECIMEN_START:
      return addSpecimenStart(state, action);
    case actionTypes.ADD_SPECIMEN_SUCCESS:
      return addSpecimenSuccess(state, action);
    case actionTypes.ADD_SPECIMEN_FAIL:
      return addSpecimenFail(state, action);
    case actionTypes.UPDATE_SPECIMEN_START:
      return editSpecimenStart(state, action);
    case actionTypes.UPDATE_SPECIMEN_SUCCESS:
      return editSpecimenSuccess(state, action);
    case actionTypes.UPDATE_SPECIMEN_FAIL:
      return editSpecimenFail(state, action);
    case actionTypes.DELETE_SPECIMEN_START:
      return deleteSpecimenStart(state, action);
    case actionTypes.DELETE_SPECIMEN_SUCCESS:
      return deleteSpecimenSuccess(state, action);
    case actionTypes.DELETE_SPECIMEN_FAIL:
      return deleteSpecimenFail(state, action);
    case actionTypes.RESET_SPECIMEN:
      return resetSpecimenState(state, action);
    case actionTypes.CLEAR_SPECIMEN_ERROR:
      return resetSpecimenError(state);
    default:
      return state;
  }
};

export default reducer;
