import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { config } from '../../Constants';

export function* fetchSpecimensSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/specimens/`;
    const response = yield fetch(endpointURL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    }).then((d) => d.json());
    if (response.error) throw response.error;
    const specimens = response.data;
    yield put(actions.fetchSpecimensSuccess(specimens));
  } catch (error) {
    yield put(actions.fetchSpecimensFail(error));
  }
}

export function* fetchPatientSpecimensSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/patients/${action.patientId}/specimens`;
    const response = yield fetch(endpointURL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    }).then((d) => d.json());
    if (response.error) throw response.error;
    const specimens = response.data;
    yield put(actions.fetchPatientSpecimenSuccess(specimens));
  } catch (error) {
    yield put(actions.fetchPatientSpecimenFail(error));
  }
}

export function* addSpecimenSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  const specimenData = action.specimen;
  delete specimenData.selectedCopyDoctors;
  delete specimenData.selIndex;
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/patients/${action.specimen.patient}/specimens`;
    const responseSpecimen = yield fetch(endpointURL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(specimenData),
    }).then((d) => d.json());
    if (responseSpecimen.error) throw responseSpecimen.error;
    yield put(actions.addSpecimenSuccess(responseSpecimen.data));
  } catch (error) {
    if (error.includes('Duplicate'))
      yield put(actions.addSpecimenFail('Duplicate specimen number entered!'));
    else yield put(actions.addSpecimenFail(error));
  }
}

export function* deleteSpecimenSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  const specimenData = action.payload;

  try {
    let endpointURL = `${config.url.API_URL}/api/v1/specimens/${action.payload.id}`;
    const responseSpecimen = yield fetch(endpointURL, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(specimenData),
    }).then((d) => d.json());
    if (responseSpecimen.error) throw responseSpecimen.error;
    yield put(actions.deleteSpecimenSuccess(action.payload));
  } catch (error) {
    yield put(actions.addSpecimenFail('Duplicate specimen number entered!'));
  }
}

export function* editSpecimenSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  const specimenData = action.specimen;
  delete specimenData.copyDoctorsDetail;
  delete specimenData.selectedCopyDoctors;
  delete specimenData.selIndex;
  delete specimenData.patient;
  delete specimenData.patient;
  delete specimenData.doctors;
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/specimens/${action.specimen.specimenId}`;
    const responseSpecimen = yield fetch(endpointURL, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(specimenData),
    }).then((d) => d.json());
    if (responseSpecimen.error) throw responseSpecimen.error;
    yield put(actions.addSpecimenSuccess(responseSpecimen.data));
  } catch (error) {
    yield put(actions.addSpecimenFail('Duplicate specimen number entered!'));
  }
}
