import React, { useRef, useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBBtn,
  MDBAnimation,
  MDBAlert,
} from 'mdbreact';
import BackupIcon from '@material-ui/icons/Backup';
import { convertToRaw } from 'draft-js';
import UploadImagePopover from '../../Reports/UploadImagePopover';
import { uploadImage } from '../../../shared/utility';
import MUIRichTextEditor from 'mui-rte';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

function FormMacro(props) {
  const {
    onChange,
    nextStep,
    prevStep,
    title,
    macroscopic,
    specimenID,
    userId,
  } = props;

  const refClinical = useRef(null);
  const [anchor, setAnchor] = useState(null);
  const [pError, setPerror] = useState(false);
  const handleFileUpload = (file, reportId, caption) => {
    refClinical.current.insertAtomicBlockAsync(
      'IMAGE',
      uploadImage(file, reportId, caption),
      'Uploading now...'
    );
  };

  const onClose = () => setPerror(false);

  const nextClick = () => {
    if (props.reportId === '') {
      if (JSON.parse(macroscopic).blocks[0].text === '') {
        setPerror(true);
        return;
      } else
        props.addReport({
          title,
          macroscopic,
          specimen: specimenID,
          Author: userId,
          status: 'Initial draft',
        });
    } else {
      props.updateReport(
        {
          macroscopic,
        },
        props.reportId
      );
    }
    nextStep();
  };

  const defaultTheme = createTheme();

  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          marginTop: 5,
          minHeight: 150,
        },
        editor: {
          borderBottom: '1px solid gray',
        },
      },
    },
  });

  return (
    <MDBContainer className="mt-2 mb-3">
      <MDBAnimation reveal type={props.animation}>
        <MDBRow>
          <MDBCol className="mt-3">
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Macroscopic</MDBCardTitle>
                <div className="grey-text">
                  {pError && (
                    <MDBAlert color="danger" dismiss onClosed={onClose}>
                      <strong>Error!</strong> Please enter some text.
                    </MDBAlert>
                  )}
                  <MuiThemeProvider theme={defaultTheme}>
                    <UploadImagePopover
                      anchor={anchor}
                      onSubmit={(data, insert) => {
                        if (insert && data.file) {
                          handleFileUpload(data.file, specimenID, data.caption);
                        }
                        setAnchor(null);
                      }}
                    />
                    <MUIRichTextEditor
                      label="Drop a file inside the editor or press the last icon in the toolbar to simulate uploading an image...."
                      ref={refClinical}
                      value={props.report.macroscopic}
                      controls={[
                        'bold',
                        'italic',
                        'underline',
                        'media',
                        'upload-image',
                      ]}
                      customControls={[
                        {
                          name: 'upload-image',
                          icon: <BackupIcon />,
                          type: 'callback',
                          onClick: (_editorState, _name, anchor) => {
                            setAnchor(anchor);
                          },
                        },
                      ]}
                      draftEditorProps={{
                        handleDroppedFiles: (_selectionState, files) => {
                          if (files.length && files[0].name !== undefined) {
                            handleFileUpload(files[0], specimenID);
                            return 'handled';
                          }
                          return 'not-handled';
                        },
                      }}
                      onChange={(state) =>
                        onChange(
                          'macroscopic',
                          JSON.stringify(
                            convertToRaw(state.getCurrentContent())
                          )
                        )
                      }
                    />
                  </MuiThemeProvider>
                </div>
                <MDBBtn color="black" variant="contained" onClick={prevStep}>
                  Back
                </MDBBtn>
                <MDBBtn color="orange" variant="contained" onClick={nextClick}>
                  Continue
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBAnimation>
    </MDBContainer>
  );
}
export default FormMacro;
