import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
}));

export default function MList({ items, render, id }) {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {items.map((item) => (
        <>
          <ListItem key={id}>{render(item)}</ListItem>
          <Divider />
        </>
      ))}
    </List>
  );
}
