import React from 'react';
import PropTypes from 'prop-types';
import { MDBInput } from 'mdbreact';

export default function TextField({
  value,
  onChange,
  placeholder,
  type = 'text',
}) {
  return (
    <MDBInput
      className="__dml_text-field"
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      label={placeholder}
    />
  );
}

TextField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number']).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
