import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import MatTable from '../MatTable';
import Spinner from '../Spinner';
import Error from '../Error';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBadge,
  MDBAnimation,
} from 'mdbreact';
import './Specimens.css';

function SpecimensHome(props) {

  const [columns] = useState([
    {
      title: 'Histology No.',
      field: 'histologyNo',
      editable: 'never',
      filtering: false,
    },
    {
      title: 'Urgent',
      field: 'urgent',
      editable: 'never',
      render: (row) => (row.urgent === true ? 'Yes' : 'No'),
    },
    {
      title: 'Specimen Date',
      field: 'specimenDate',
      editable: 'never',
      render: (row) => row.specimenDate.substring(0, 10),
    },
    { title: 'Specimen Time', field: 'specimenTime', editable: 'never' },
    { title: 'Specimen Type', field: 'specimenNature', editable: 'never' },
    {
      title: 'Patient Name',
      field: 'patient.name',
    },
    {
      title: 'Patient Surname',
      field: 'patient.surname',
    },
    {
      title: 'Patient ID',
      field: 'patient.idnumber',
    },
    {
      title: 'Doctor Mnemonic',
      field: 'doctors.mnemonic',
    },
    { title: 'id', field: 'id', hidden: true },
  ]);

  useEffect(() => {
    props.onFetchSpecimens();
  }, []);

  return (
    <MDBContainer className="mt-3">
      <MDBRow className="py-3">
        <MDBCol md="12">
          <MDBAnimation reveal type="fadeInLeft">
            <h1>
              <MDBBadge color="warning">Patients</MDBBadge>
            </h1>
            <MDBCard>
              <MDBCardBody>
                {props.specimens ? (
                  <>
                    <Error show={props.error} error={props.error} />
                    <MatTable
                      title="Patient Dictionary"
                      columns={columns}
                      data={props.specimens}
                      selection={true}
                      actions={[
                        {
                          tooltip: 'Remove All Selected Users',
                          icon: 'delete',
                          onClick: (evt, data) =>
                            alert(
                              'You want to delete ' + data.length + ' rows'
                            ),
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Spinner />
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    specimens: state.specimen.specimens,
    loading: state.patient.loading,
    error: state.patient.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchSpecimens: () => dispatch(actions.fetchSpecimensStart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecimensHome);
