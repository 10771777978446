import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MDBBtn } from 'mdbreact';
import Error from '../Error';
import Spinner from '../Spinner';
import * as actions from '../../store/actions/index';

function EmailConfirmed({
  match,
  onEmailConfirmed,
  loading,
  error,
  onAuthClearError,
}) {
  const { confirmationtoken } = match.params;

  useEffect(() => {
    if (!error) onEmailConfirmed(confirmationtoken);
  });
  return (
    <div>
      <Error show={error} error={error} clearErr={onAuthClearError} />
      <hr className="my-5" />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <h3>
            Your confirmation has expired, click below to request a valid
            confirmation.
          </h3>
          <MDBBtn href="/confirm" color="deep-orange">
            Request Confirmation Email
          </MDBBtn>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEmailConfirmed: (emailToken) =>
      dispatch(actions.confirmEmailStart(emailToken)),
    onAuthClearError: () => dispatch(actions.authClearError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmed);
