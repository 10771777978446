import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  medicalAids: [],
  specimens: [],
  patient: null,
  patients: null,
  mainmember: null,
  specimen: null,
  loading: false,
  error: null,
  medicalAidId: null,
  patientId: null,
  specimenId: null,
};

const fetchMedicalAidStart = (state, action) => {
  return updateObject(state, { loading: true, medicalAids: null });
};

const fetchMedicalAidSuccess = (state, action) => {
  return updateObject(state, {
    medicalAids: action.medicalAids,
    loading: false,
  });
};

const fetchMedicalAidsFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const fetchPatientsStart = (state) => {
  return updateObject(state, { loading: true });
};

const fetchPatientsSuccess = (state, action) => {
  return updateObject(state, {
    patients: action.patients,
    loading: false,
  });
};

const fetchPatientsFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const fetchPatientStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const fetchPatientSuccess = (state, action) => {
  return updateObject(state, {
    patient: action.patient,
    loading: false,
  });
};

const fetchPatientFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addPatientStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addPatientSuccess = (state, action) => {
  return updateObject(state, {
    patient: action.patient,
    patientId: action.patient.id,
    loading: false,
  });
};

const addPatientFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error.response.data.error,
  });
};

const addMainMemberStart = (state, action) => {
  return updateObject(state, { loading: true, error: null });
};

const addMainMemberSuccess = (state, action) => {
  return updateObject(state, {
    mainmember: action.mainmember,
    medicalAidId: action.mainmember.id,
    loading: false,
  });
};

const addMainMemberFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const editMainMemberStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const editMainMemberSuccess = (state, action) => {
  return updateObject(state, {
    mainmember: action.payload,
    loading: false,
  });
};

const editMainMemberFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const clearError = (state) => {
  return updateObject(state, { error: null });
};

const clearPatient = (state) => {
  return updateObject(state, {
    patient: null,
    patientId: null,
    mainmember: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PATIENT_START:
      return fetchPatientStart(state);
    case actionTypes.FETCH_PATIENT_SUCCESS:
      return fetchPatientSuccess(state, action);
    case actionTypes.FETCH_PATIENT_FAIL:
      return fetchPatientFail(state, action);
    case actionTypes.FETCH_PATIENTS_START:
      return fetchPatientsStart(state);
    case actionTypes.FETCH_PATIENTS_SUCCESS:
      return fetchPatientsSuccess(state, action);
    case actionTypes.FETCH_PATIENTS_FAIL:
      return fetchPatientsFail(state, action);
    case actionTypes.FETCH_MEDICALAIDS_START:
      return fetchMedicalAidStart(state, action);
    case actionTypes.FETCH_MEDICALAIDS_SUCCESS:
      return fetchMedicalAidSuccess(state, action);
    case actionTypes.FETCH_MEDICALAIDS_FAIL:
      return fetchMedicalAidsFail(state, action);
    case actionTypes.ADD_PATIENT_START:
      return addPatientStart(state, action);
    case actionTypes.ADD_PATIENT_SUCCESS:
      return addPatientSuccess(state, action);
    case actionTypes.ADD_PATIENT_FAIL:
      return addPatientFail(state, action);
    case actionTypes.ADD_MAINMEMBER_START:
      return addMainMemberStart(state, action);
    case actionTypes.ADD_MAINMEMBER_SUCCESS:
      return addMainMemberSuccess(state, action);
    case actionTypes.ADD_MAINMEMBER_FAIL:
      return addMainMemberFail(state, action);
    case actionTypes.EDIT_MAINMEMBER_START:
      return editMainMemberStart(state, action);
    case actionTypes.EDIT_MAINMEMBER_SUCCESS:
      return editMainMemberSuccess(state, action);
    case actionTypes.EDIT_MAINMEMBER_FAIL:
      return editMainMemberFail(state, action);
    case actionTypes.CLEAR_ERROR:
      return clearError(state);
    case actionTypes.CLEAR_PATIENT:
      return clearPatient(state);
    default:
      return state;
  }
};

export default reducer;
