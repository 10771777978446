import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

export default function Spinner() {
  return (
    <MDBContainer className="m-3">
      <MDBRow>
        <MDBCol className="text-center">
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
