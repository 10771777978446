import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import DoctorInfo from './DoctorInfo';
import { isFormValid, contactNumberPresent } from '../../shared/utility';
import AlertModal from '../AlertModal';
//import AlertSnackbar from '../AlertSnackbar';

const initialState = {
  salutation:'',
  initials: '',
  name: '',
  surname: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  code: '',
  province: '',
  country: '',
  phone: '',
  cellphone: '',
  practiceNumber: '',
  speciality: '',
  message: 'Client saved successfully',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return initialState;
    }
    case 'field': {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    default:
  }
};

function Doctor(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [message, setMessage] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [alertType, setAlertType] = React.useState('');
  const {
    id,
    salutation,
    initials,
    name,
    surname,
    email,
    address1,
    address2,
    city,
    code,
    province,
    country,
    phone,
    cellphone,
    practiceNumber,
    speciality,
  } = state;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.doctorId) {
      props.onClearDoctor();
      setOpen(false);
      history.push('/doctors');
    } else if (props.error) {
      props.onClearDoctorError();
      setOpen(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setOpen(false);
    contactNumberPresent('txtPhone', 'txtMobile');
    e.target.className += ' was-validated';
    if (!isFormValid(e)) return;
    const doc = {
      id,
      title: salutation,
      initials,
      name,
      surname,
      email,
      address: `${address1}, ${address2}, ${city}, ${code}, ${province}, ${country}`,
      address1,
      address2,
      city,
      code,
      province,
      country,
      phone,
      cellphone,
      practiceNumber,
      speciality,
    };
    props.add ? props.onAddDoctor(doc) : props.onEditDoctor(doc);
    e.target.reset();
  };

  if (props.error && !open) {
    handleOpen();
    setTitle('Error');
    setMessage(props.error);
    setAlertType('error');
  }

  if (props.doctorId && !open) {
    handleOpen();
    setTitle('Success');
    setMessage('Changes saved successfully!');
    setAlertType('success');
  }

  return props.add ? (
    <>
      <DoctorInfo
        loading={props.loading}
        add={true}
        submitHandler={submitHandler}
        state={state}
        dispatch={dispatch}
      />
      <AlertModal
        alertType={alertType}
        open={open}
        onClose={handleClose}
        title={title}
        message={message}
      />
    </>
  ) : props.edit ? (
    <>
      <DoctorInfo
        loading={props.loading}
        edit={true}
        onFetchDoctor={props.onFetchDoctor}
        doctor={props.Doctor}
        submitHandler={submitHandler}
        state={state}
        dispatch={dispatch}
        doctorId={props.match.params.doctorId}
      />
      <AlertModal
        alertType={alertType}
        open={open}
        onClose={handleClose}
        title={title}
        message="Doctor successfully updated!"
      />
    </>
  ) : (
    <DoctorInfo doctor={props.doctor} state={state} dispatch={dispatch} />
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.doctor.loading,
    error: state.doctor.error,
    doctorId: state.doctor.doctorId,
    Doctor: state.doctor.doctor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDoctor: (doctorId) => dispatch(actions.fetchDoctorStart(doctorId)),
    onAddDoctor: (doctor) => dispatch(actions.addDoctorStart(doctor)),
    onEditDoctor: (doctor) => dispatch(actions.editDoctorStart(doctor)),
    onClearError: () => dispatch(actions.clearError()),
    onClearDoctor: () => dispatch(actions.clearDoctor()),
    onClearDoctorError: () => dispatch(actions.clearDoctorError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Doctor);
