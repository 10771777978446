import React from 'react';
import { MDBContainer, MDBAlert } from 'mdbreact';

export default function Error(props) {
  return (
    <MDBContainer className="mt-3 mb-5">
      {props.show ? (
        <MDBAlert color="danger" dismiss onClosed={props.clearErr}>
          <strong>Error!</strong> {props.error}
        </MDBAlert>
      ) : null}
    </MDBContainer>
  );
}
