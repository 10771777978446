import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import * as selectors from '../selectors/selectors';
import { select } from 'redux-saga/effects';
import { config } from '../../Constants';

export function* fetchReportSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/reports/${action.payload}`;
    const response = yield fetch(endpointURL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    }).then((d) => d.json());
    if (response.error) throw response.error;
    const fetchedReport = response.data;
    yield put(actions.fetchReportSuccess(fetchedReport));
  } catch (error) {
    yield put(actions.fetchReportFail(error));
  }
}

export function* fetchICD10Saga() {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/icd10code?limit=40000/`;
    const response = yield fetch(endpointURL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    }).then((d) => d.json());
    const fetchedICD10codes = response.data;
    yield put(actions.fetchICD10Success(fetchedICD10codes));
  } catch (error) {
    yield put(actions.fetchICD10Fail(error));
  }
}

export function* fetchCytologyTextSaga() {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/cytologytext`;
    const response = yield fetch(endpointURL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    }).then((d) => d.json());
    const fetchedCytologyTexts = response.data;
    yield put(actions.fetchCytologyTextSuccess(fetchedCytologyTexts));
  } catch (error) {
    yield put(actions.fetchCytologyTextFail(error));
  }
}

export function* fetchReportsSaga() {
  const role = yield select(selectors.role);
  const email = yield select(selectors.email);
  let endpointURL = `${config.url.API_URL}/api/v1/reports?limit=5000/`;
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    if (role !== 'admin')
      endpointURL = `${config.url.API_URL}/api/v1/reports/${email}/email`;

    const response = yield fetch(endpointURL, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    }).then((d) => d.json());
    const fetchedReports = response.data;
    if (response.count === 0) yield put(actions.fetchReportsSuccess([]));
    else yield put(actions.fetchReportsSuccess(fetchedReports));
  } catch (error) {
    yield put(actions.fetchReportsFail(error));
  }
}

export function* addReportSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/reports`;
    const response = yield fetch(endpointURL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(action.report),
    }).then((d) => d.json());
    if (response.error) throw response.error;
    const addedReport = response.data;
    yield put(actions.addReportSuccess(addedReport));
  } catch (error) {
    yield put(actions.addReportFail(error));
  }
}

export function* updateReportSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  const reportData = action.report;
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/reports/${action.reportId}`;
    const response = yield fetch(endpointURL, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(reportData),
    }).then((d) => d.json());
    const updatedReport = response.data;
    yield put(actions.updateReportSuccess(updatedReport));
  } catch (error) {
    yield put(actions.updateReportFail(error));
  }
}

export function* sendReportSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/reports/email1`;
    const response = yield fetch(endpointURL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(action.payload),
    }).then((d) => d.json());
    if (response.error) throw response.error;
    const sentReport = response.data;
    yield put(actions.updateReportSuccess(sentReport));
  } catch (error) {
    yield put(actions.updateReportFail(error));
  }
}
