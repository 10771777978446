import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import PreviewIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { format } from 'date-fns';
import { config } from '../../Constants';
import Avatar from '@material-ui/core/Avatar';
import { red, orange, amber } from '@material-ui/core/colors';
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
// import Spinner from '../Spinner';
import { isFormValid } from '../../shared/utility';
import ShareModal from '../ShareReportModal';
import ConfirmDialog from '../ConfirmModal';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  urgent: {
    backgroundColor: red[500],
  },
  noturgent: {
    backgroundColor: orange[500],
  },
  hsp: {
    backgroundColor: amber[700],
  },
  media: {
    height: 140,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SpecimenItem = (props) => {
  const classes = useStyles();
  const { specimen, sendReport, deleteSpecimen } = props;
  const {
    id,
    histologyNo,
    specimenNo,
    urgent,
    specimenDate,
    specimenTime,
    specimenNature,
    clinicalData,
    hospitalName,
    hospitalWard,
  } = specimen;
  const [reportCount, setReportCount] = useState(-1);
  const [reportStatus, setReportStatus] = useState('Updated Draft');
  const [reportURL, setReportURL] = useState('');
  const [reportId, setReportId] = useState('');
  const [open, setOpen] = React.useState(false);
  const [dOpen, setDopen] = React.useState(false);
  const [loading] = React.useState(false);
  const [emails, setEmails] = React.useState('');

  const handleDOpen = () => {
    setDopen(true);
  };

  const handleDClose = () => {
    setDopen(false);
  };

  const DeleteSpecimen = () => {
    deleteSpecimen(specimen);
    handleDClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.className += ' was-validated';
    if (!isFormValid(e)) return;
    sendReport({
      email: emails,
      patientName: `${specimen.patient.name} ${specimen.patient.surname}`,
      histologyNo: specimen.histologyNo,
      specimenNature: specimen.specimenNature,
      copyDoctors: [],
      recepientName: `${specimen.doctors.name} ${specimen.doctors.surname}`,
      filename: reportURL,
    });
    handleClose();
  };
  // const preventDefault = (event) => event.preventDefault();

  useEffect(() => {
    var bearer = 'Bearer ' + localStorage.getItem('token');
    if (id)
      fetch(`${config.url.API_URL}/api/v1/specimens/${id}/reports`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: bearer,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          let { data } = json;
          if (data) {
            setReportId(data[0].id);
            setReportCount(1);
            setReportStatus(data[0].status);
            if (data[0].pdf) setReportURL(data[0].pdf.url);
          } else setReportCount(0);
        })
        .catch((error) => console.log(error));
  }, [id]);

  return (
    <>
      <Card className={classes.root} key={specimen.id}>
        <CardHeader
          avatar={
            urgent ? (
              <Avatar aria-label="urgent" className={classes.urgent}>
                Y
              </Avatar>
            ) : (
              <Avatar aria-label="nonurgent" className={classes.noturgent}>
                N
              </Avatar>
            )
          }
          title={`${histologyNo} - ${specimenNo}`}
          subheader={`${format(
            new Date(specimenDate),
            'dd-MM-yyyy'
          )} - ${specimenTime}`}
        />
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="subtitle1" component="h2">
              {specimenNature}
            </Typography>
            <Typography variant="body1" color="textSecondary" component="p">
              {clinicalData}
            </Typography>
            {!!hospitalName && (
              <Typography
                variant="subtitle2"
                className={classes.hsp}
                component="p"
              >
                {hospitalName} - {hospitalWard}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
          <IconButton
            aria-label="View Report"
            href={`${config.url.API_URL}/uploads/pdf/${reportURL}`}
            disabled={reportStatus !== 'Final'}
          >
            <PreviewIcon />
          </IconButton>
          {reportCount > 0 ? (
            <IconButton
              aria-label="add to favorites"
              href={`/reports/new/${id}/${reportId}`}
            >
              <EditIcon />
            </IconButton>
          ) : (
            <IconButton
              aria-label="add to favorites"
              href={`/reports/new/${id}/`}
            >
              <EditIcon />
            </IconButton>
          )}
          {reportCount === 0 && (
            <IconButton aria-label="Delete" onClick={handleDOpen}>
              <DeleteIcon />
            </IconButton>
          )}
          <IconButton
            aria-label="share"
            disabled={reportStatus !== 'Final'}
            onClick={handleOpen}
          >
            <ShareIcon />
          </IconButton>
        </CardActions>
      </Card>
      <ShareModal
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        loading={loading}
        emails={emails}
        setEmails={setEmails}
      />
      {dOpen && (
        <ConfirmDialog
          modalTitle="Delete Specimen"
          onClose={handleDClose}
          open={dOpen}
          fn={DeleteSpecimen}
          message={'Are you sure you want to delete the specimen?'}
        />
      )}
    </>
  );
};
export default SpecimenItem;
