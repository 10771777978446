import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBadge,
  MDBAnimation,
} from 'mdbreact';
import DoctorReports from '../Reports/DisplayReports/DoctorReports';
import PathologistReports from '../Reports/DisplayReports/PathologistReports';
import Spinner from '../Spinner';
import Error from '../Error';

function ReportHome({
  onFetchReports,
  onSendReport,
  reports,
  role,
  loading,
  error,
}) {
  useEffect(() => {
    onFetchReports();
  }, []);

  return (
    <MDBContainer className="mt-3">
      <MDBRow className="py-3">
        <MDBCol md="12">
          <MDBAnimation reveal type="fadeInLeft">
            <h1>
              <MDBBadge color="warning">Reports</MDBBadge>
            </h1>
            <MDBCard>
              <MDBCardBody>
                <Error show={!!error} error={error} />
                {loading ? (
                  <Spinner />
                ) : role !== 'admin' ? (
                  <DoctorReports sdata={reports} />
                ) : (
                  <PathologistReports
                    sdata={reports}
                    sendReport={onSendReport}
                    loading={loading}
                    error={error}
                  />
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    reports: state.report.reports,
    loading: state.report.loading,
    error: state.report.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchReports: () => dispatch(actions.fetchReportsStart()),
    onSendReport: (payload) => dispatch(actions.sendReportStart(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportHome);
