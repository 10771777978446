import MatrixRenderer from '../MatrixRenderer';
import Position from '../Position';
import { TextRenderer } from './TextRenderer';

export class CodeRenderer extends MatrixRenderer {
  static RATIO_HEIGHT_OFFSET = 0.2;
  static RATIO_BAR_WIDTH = 0.3;

  static COLOR_BAR = '#ececec';
  static COLOR_TEXT = '#3d3d3d';

  #textRender;

  constructor() {
    super();
    this.#textRender = new TextRenderer();
  }

  onRenderText(line, absX, absY, width, height, renderingState) {
    var pdfDocument = renderingState.getPdfDocument();
    pdfDocument.setFillColor(CodeRenderer.COLOR_BAR);
    pdfDocument.rect(
      absX - renderingState.getTabSize(),
      absY +
        renderingState.getLineHeight() * (CodeRenderer.RATIO_HEIGHT_OFFSET - 1),
      width,
      renderingState.getLineHeight(),
      'F'
    );
  }

  drawBackground(boundary, renderingState) {
    var pdfDocument = renderingState.getPdfDocument();
    var absX = renderingState.getAbsPositionX(boundary);
    var absY = renderingState.getAbsPositionY(boundary);

    pdfDocument.setFillColor(CodeRenderer.COLOR_BAR);
    pdfDocument.rect(
      absX,
      absY + renderingState.getLineHeight() * CodeRenderer.RATIO_HEIGHT_OFFSET,
      boundary.getWidth(),
      renderingState.getLineHeight(),
      'F'
    );
  }

  render(matrix, boundary, prevMatrix, renderingState) {
    var barHieght = renderingState.getLineHeight();
    var barYOffset = barHieght;
    var preMatrixType = prevMatrix === undefined ? 'unknown' : prevMatrix.type;

    renderingState.getPosition().setX(0);
    if (preMatrixType === 'code-block') {
      if (!renderingState.movePositionY(2 * barHieght, boundary)) {
        this.drawBackground(boundary, renderingState);
        renderingState.movePositionY(barHieght, boundary);
      } else {
        renderingState.movePositionY(-2 * barHieght, boundary);
      }
      barYOffset = 0;
    } else {
      if (!renderingState.movePositionY(3 * barHieght, boundary)) {
      } else {
        renderingState.movePositionY(-3 * barHieght, boundary);
      }
      this.drawBackground(boundary, renderingState);
      renderingState.movePositionY(barHieght, boundary);
    }

    var tab = renderingState.getTabSize();
    var boundaryWidth = boundary.getWidth();
    var widthTextBoundary = Math.max(0, boundaryWidth - 2 * tab);
    var positionTextBoundary = new Position(tab, 0, boundary.getPosition());

    var boundaryText = renderingState.requestBoundary(
      positionTextBoundary,
      widthTextBoundary
    );

    this.drawBackground(boundary, renderingState);
    this.#textRender.setOnRenderTextListener(
      (line, absX, absY, width, height) => {
        this.onRenderText(
          line,
          absX,
          absY,
          boundary.getWidth(),
          height,
          renderingState
        );
      }
    );
    this.#textRender.render(
      matrix,
      boundaryText,
      prevMatrix,
      renderingState,
      CodeRenderer.COLOR_TEXT
    );
    this.#textRender.setOnRenderTextListener(undefined);

    this.drawBackground(boundary, renderingState);

    var currentPosition = renderingState.getPosition();
    currentPosition.incrementY(renderingState.getLineHeight());
    currentPosition.setX(0);
    return this;
  }
}
