import React from 'react';
import { useHistory } from 'react-router-dom';
import { MDBBtn } from 'mdbreact';
import SpecimenItem from './SpecimenItem';
import Spinner from '../Spinner';
import Error from '../Error';
import MList from '../FilteredList/list';
import Filter from '../FilteredList/filter';
import './styles.css';

export default function SpecimenCollection(props) {
  const history = useHistory();
  const { specimens, sendReport, deleteSpecimen } = props;

  function filterSpecimens(query, specimens) {
    return specimens.filter(
      (specimen) =>
        specimen.specimenNo
          .toLocaleLowerCase()
          .indexOf(query.toLocaleLowerCase()) > -1
    );
  }

  return (
    <div className="d-flex flex-column">
      <MDBBtn
        color="orange"
        onClick={() => history.push(`/specimen/new/${props.patientId}`)}
      >
        Register New Specimen
      </MDBBtn>
      {props.Loading && <Spinner />}
      {props.Error && (
        <Error error={props.Error} clearErr={props.onClearError} />
      )}
      {/* {props.Specimen && specimens.push(props.Specimen)} */}
      <Filter
        placeholder="Type specimen number to filter..."
        render={(query) => (
          <MList
            items={filterSpecimens(query, specimens)}
            render={(specimen) => (
              <SpecimenItem
                specimen={specimen}
                sendReport={sendReport}
                key={specimen.id}
                deleteSpecimen={deleteSpecimen}
              />
            )}
          />
        )}
      />
    </div>
  );
}
