import React from 'react';
import {
  MDBBtn,
  MDBCard,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBIcon,
} from 'mdbreact';
import Image from '../../assets/bg1.jpg';
import './style.css';
import { Redirect } from 'react-router-dom';

export default function FourOFour() {
  localStorage.getItem('token');
  if (
    (window.location.href.includes('confirm') ||
      window.location.href.includes('resetpassword')) &&
    localStorage.getItem('token')
  )
    return <Redirect to="/" />;

  return (
    <MDBRow>
      <MDBCol md="6" className="offset-md-3" style={{ maxWidth: '40rem' }}>
        <MDBCard
          className="text-center card-image text-white  d-flex"
          style={{
            backgroundImage: `url(${Image})`,
          }}
        >
          <div className="rgba-black-strong py-3 px-4">
            <div>
              <h5 className="orange-text">
                <MDBIcon icon="desktop" /> Page not found
              </h5>
              <MDBCardTitle tag="h3" className="pt-2">
                <strong>404</strong>
              </MDBCardTitle>
              <p>Oops! Something went wrong.</p>
              <MDBBtn href="/" color="deep-orange">
                <MDBIcon icon="clone left" /> Home
              </MDBBtn>
            </div>
          </div>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}
