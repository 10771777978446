import React from 'react';
import {
  MDBContainer,
  MDBRow,
  // MDBCol,
  // MDBAvatar,
  MDBMask,
  MDBIcon,
  MDBView,
  MDBCard,
  MDBCardBody,
} from 'mdbreact';
//import Lightbox from 'react-image-lightbox';
import './AboutUs.css';
import weblogo from '../../assets/ashpbg.png';
//import Toggle from '../Toggle/';
// const images = [
//   'https://mdbootstrap.com/img/Photos/Horizontal/People/12-col/img%20(132).jpg',
//   'https://mdbootstrap.com/img/Photos/Horizontal/Work/12-col/img%20(40).jpg',
//   'https://mdbootstrap.com/img/Photos/Horizontal/Work/12-col/img%20(14).jpg',
// ];

// const smallImages = [
//   'https://mdbootstrap.com/img/Photos/Horizontal/People/4-col/img%20(132).jpg',
//   'https://mdbootstrap.com/img/Photos/Horizontal/Work/12-col/img%20(40).jpg',
//   'https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20(14).jpg',
// ];

function AboutUs(props) {
  // const [photoIndex, setPhotoIndex] = useState(0);
  // const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <section id="home">
        <MDBView src={weblogo} fixed style={{ height: '60vh' }}>
          <MDBMask>
            {/* <MDBContainer className="h-100 d-flex justify-content-center align-items-center">
              <MDBRow>
                <MDBCol md="12" className="mt-5 mx-auto text-center">
                  <h1 className="h4 white-text mb-5">
                    DR SITHOLE AND PARTNER{' '}
                    <a className="white-text font-weight-bold" href="!#">
                      PATHOLOGISTS INC
                    </a>
                  </h1>
                  <h5 className="text-uppercase white-text mb-5 font-weight-bold">
                    Experts in Clinical Histopathology
                    <br />
                    SHP Labs is a full-service CRO offering medically relevant
                    diagnoses and clinical research services to medical
                    professionals in the private and public sector.
                  </h5>
                  <MDBBtn color="orange" size="lg">
                    Read{' '}
                    <a href="!#" className="white-text font-weight-bold">
                      More
                    </a>
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBContainer> */}
          </MDBMask>
        </MDBView>
      </section>
      <MDBContainer>
        <hr className="my-5" />
        <section id="products" className="text-center">
          <MDBCard>
            <MDBCardBody>
              <h1 className="text-center my-5 h1">Our Services</h1>
              <p className="text-center mb-5 w-responsive mx-auto lead grey-text">
                SHP laboratories is a full-service histopathology laboratory
                offering diagnostic histology and cytology services to
                clinicians in the private sector.
              </p>
              <MDBRow>
                <div className="col-md-6 mb-4">
                  <MDBIcon icon="microscope orange-text" size="4x" />
                  <h4 className="font-weight-bold my-4">Histopathology</h4>
                  <p className="grey-text">
                    At SHP laboratories, we offer histopathology resources
                    provided by our experienced pathologists. Our team is ready
                    to provide solutions to meet client needs that are
                    economical, efficient and flexible.
                  </p>
                  <div className="grey-text">
                    Our services and field of expertise include:
                    <ol start="1">
                      <li>
                        <strong>Routine histology support</strong>
                      </li>
                    </ol>
                    <ul>
                      <li>
                        Routine paraffin processing of clinical tissues
                        including bone decalcification methods
                      </li>
                      <li>Customized tissue trimming and processing</li>
                      <li>Wide selection of special stains.&nbsp;</li>
                    </ul>
                  </div>
                  <div className="grey-text">
                    <ol start="2">
                      <li>
                        <strong>
                          Clinical pathology correlations (multidisciplinary) to
                          histopathology and interpretations and consultation.
                        </strong>
                      </li>
                    </ol>
                    <ul>
                      <li>Dermatology</li>
                      <li>Neurosurgery&nbsp;</li>
                      <li>Other surgical disciplines&nbsp;</li>
                    </ul>
                  </div>
                  <div className="grey-text">
                    <ol start="3">
                      <li>
                        <strong>
                          Intra-Operative Frozen Section Consultation.
                        </strong>
                      </li>
                    </ol>
                  </div>
                  <div className="grey-text">
                    <ol start="4">
                      <li>
                        <strong>Specialized histology support.</strong>
                      </li>
                    </ol>
                    <ul>
                      <li>Breast pathology</li>
                      <li>Bone pathology</li>
                      <li>Dermatopathology</li>
                      <li>Gastrointestinal pathology</li>
                      <li>Gynaepathology</li>
                      <li>Lung pathology</li>
                      <li>Neuropathology</li>
                      <li>Oncology</li>
                      <li>Renal pathology &nbsp;</li>
                    </ul>
                  </div>
                  <p className="grey-text">
                    We remain committed to growing our capabilities to support
                    routine and specialized histopathology; immunohistochemistry
                    (IHC) and digital pathology.
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <MDBIcon icon="notes-medical orange-text" size="4x" />
                  <h4 className="font-weight-bold my-4">CYTOLOGY</h4>
                  <p className="grey-text">
                    The cytology department provides a quality service,
                    processing both non-gynaecological and gynaecological
                    specimens. These are prepared, stained and microscopically
                    examined by our well qualified staff.
                  </p>
                  <div className="grey-text">
                    Our services and field of expertise include:
                    <ol start="1">
                      <li>
                        <strong>Non-gynaecological</strong>
                      </li>
                    </ol>
                    <ul>
                      <li>Effusions</li>
                      <li>Urines</li>
                      <li>Respiratory</li>
                      <li>Fine Needle Aspirates (FNA) from various sources:</li>
                      <ul className="lead divcenter bottommargin">
                        <li>Lymph nodes</li>
                        <li>Breast</li>
                        <li>Thyroid</li>
                      </ul>
                    </ul>
                  </div>
                  <div className="grey-text">
                    Our pathologists perform FNAs and provide histology &amp;
                    cytology correlation.
                    <ol start="2">
                      <li>
                        <strong>Gynaecological</strong>
                      </li>
                    </ol>
                    <ul>
                      <li>Liquid Based Cytology</li>
                      <li>Pap smears</li>
                    </ul>
                  </div>
                  <p className="grey-text">
                    The cases are screened by technologists, then checked and
                    reported by a pathologist.
                  </p>
                </div>
              </MDBRow>
              <MDBRow>
                <div className="col-md-12 mb-4">
                  <MDBIcon icon="laptop orange-text" size="4x" />
                  <h4 className="font-weight-bold my-4">QUALITY</h4>
                  <div className="grey-text">
                    Quality is our number one priority and we devote the time
                    and attention necessary to achieve consistent reliable
                    results.
                    <ul>
                      <li>
                        We are committed to ongoing growth and enhancement of
                        our laboratory services
                      </li>
                      <li>
                        We adhere to all applicable Standard Operating
                        Procedures (SOP) and follow best practices.
                      </li>
                      <li>
                        Quality checks and oversight checks are performed by our
                        experienced pathologists.&nbsp;
                      </li>
                    </ul>
                  </div>
                  <p className="grey-text">
                    Our clients can rely upon us to provide the diagnostic
                    support needed to allow them to make accurate, timely
                    assessments of their patients and to effect appropriate
                    treatment.
                  </p>
                </div>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </section>
        <hr className="my-5" />
      </MDBContainer>
    </>
  );
}

export default AboutUs;
