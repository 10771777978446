import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  MDBAnimation,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
} from 'mdbreact';
import { Divider, Box, InputLabel, MenuItem, Select } from '@material-ui/core';
import Spinner from '../../Spinner';
import { contactNumberPresent } from '../../../shared/utility';

function DoctorInfo({
  add,
  edit,
  loading,
  dispatch,
  state,
  doctor,
  submitHandler,
  onFetchDoctor,
  doctorId,
}) {
  const history = useHistory();
  const {
    mnemonic,
    salutation,
    initials,
    name,
    surname,
    email,
    address1,
    address2,
    city,
    code,
    province,
    country,
    phone,
    cellphone,
    practiceNumber,
    speciality,
  } = state;

  React.useEffect(() => {
    document.getElementById('txtPhone').oninput = () =>
      contactNumberPresent('txtPhone', 'txtMobile');
    document.getElementById('txtMobile').oninput = () =>
      contactNumberPresent('txtPhone', 'txtMobile');
    if (!!doctorId && !!!surname) onFetchDoctor(doctorId);
    if (!!doctor && !!!surname) {
      Object.keys(doctor).map((x) => {
        if (x === 'location') {
          dispatch({
            type: 'field',
            field: 'address1',
            value: doctor[x].street,
          });
          dispatch({
            type: 'field',
            field: 'address2',
            value: doctor[x].formattedAddress,
          });
          dispatch({
            type: 'field',
            field: 'city',
            value: doctor[x].city,
          });
          dispatch({
            type: 'field',
            field: 'code',
            value: doctor[x].zipcode,
          });
          dispatch({
            type: 'field',
            field: 'province',
            value: doctor[x].state,
          });
        }
        dispatch({
          type: 'field',
          field: x,
          value: doctor[x],
        });
        return null;
      });
    }
  }, [doctor, dispatch]);

  return loading ? (
    <Spinner />
  ) : (
    <MDBAnimation type="bounce">
      <MDBCard>
        <MDBCardBody className="mx-4">
          <h5 className="pb-2">{`Client Details - ${mnemonic}`}</h5>
          <Box mb={5}>
            <Divider className="orange" />
          </Box>
          <form
            id="doctorFrm"
            className="needs-validation"
            onSubmit={submitHandler}
            noValidate
          >
            <MDBRow>
            <MDBCol md="2">
            <InputLabel id="demo-simple-select-label">Title</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={salutation}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'salutation',
                      value: e.target.value,
                    })
                  }
                >
                  <MenuItem value="Ms.">Sr.</MenuItem>
                  <MenuItem value="Dr.">Dr.</MenuItem>
                  <MenuItem value="Prof.">Prof</MenuItem>
                </Select>
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  icon="user"
                  value={name}
                  name="name"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'name',
                      value: e.target.value,
                    })
                  }
                  onBlur={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'initials',
                      value: e.target.value
                        .split(' ')
                        .map((x) => x.substring(0, 1))
                        .join(''),
                    })
                  }
                  type="text"
                  id="txtName"
                  label="Name"
                />
              </MDBCol>
              <MDBCol md="2">
                <MDBInput
                  icon="user-tag"
                  value={initials}
                  name="name"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'initials',
                      value: e.target.value,
                    })
                  }
                  type="text"
                  id="txtInitials"
                  label="Initials"
                  validate
                />
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  icon="user-check"
                  value={surname}
                  name="surname"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'surname',
                      value: e.target.value,
                    })
                  }
                  type="text"
                  id="txtSurname"
                  label="Surnane"
                  validate
                  required
                >
                  <div className="invalid-feedback">Surname is required.</div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="4">
                <MDBInput
                  icon="envelope"
                  value={email}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'email',
                      value: e.target.value,
                    })
                  }
                  type="email"
                  id="txtEmail"
                  name="email"
                  label="Email"
                  validate
                >
                  <div className="invalid-feedback">
                    Please provide a valid email.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  icon="phone"
                  value={phone}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'phone',
                      value: e.target.value,
                    })
                  }
                  type="text"
                  id="txtPhone"
                  name="phone"
                  label="Phone"
                  pattern="^(\+27|0)[0-9]{9}$"
                  validate
                >
                  <div className="invalid-feedback">
                    Please provide a valid phone number e.g. 02112312345 or
                    +27211231234.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  icon="mobile"
                  value={cellphone}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'cellphone',
                      value: e.target.value,
                    })
                  }
                  type="text"
                  id="txtMobile"
                  name="cellphone"
                  label="Mobile Number"
                  pattern="^(\+27|0)[0-9]{9}$"
                  validate
                >
                  <div className="invalid-feedback">
                    Please provide a valid phone number e.g. 08112312345 or
                    +27811231234.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6">
                <MDBInput
                  icon="map-marker"
                  value={address1}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'address1',
                      value: e.target.value,
                    })
                  }
                  type="text"
                  id="txtAddress1"
                  name="address1"
                  label="Physical address line 1"
                  validate
                  required
                >
                  <div className="invalid-feedback">
                    Please provide a valid physical address.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="6">
                <MDBInput
                  icon="map-marker-alt"
                  value={address2}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'address2',
                      value: e.target.value,
                    })
                  }
                  type="text"
                  id="txtAddress2"
                  name="address2"
                  label="Physical address line 2"
                >
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="4">
                <MDBInput
                  icon="city"
                  value={city}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'city',
                      value: e.target.value,
                    })
                  }
                  type="text"
                  id="txtCity"
                  name="city"
                  label="City/Town"
                  validate
                  required
                >
                  <div className="invalid-feedback">
                    Please provide a city/town.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <MDBInput
                  icon="map-marker"
                  value={code}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'code',
                      value: e.target.value,
                    })
                  }
                  type="text"
                  id="txtCode"
                  name="code"
                  label="Physical Code"
                  pattern="\d{4}"
                  validate
                >
                  <div className="invalid-feedback">
                    Please provide a valid physical address code.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              <MDBCol md="4">
                <Box mt={1}>
                  <InputLabel id="ddlprov-label">Province</InputLabel>
                  <Select
                    style={{ width: '25ch' }}
                    required
                    error={province.trim().length === 0}
                    labelId="ddlprov-label"
                    id="ddlProv"
                    value={province}
                    onChange={(e) =>
                      dispatch({
                        type: 'field',
                        field: 'province',
                        value: e.target.value,
                      })
                    }
                  >
                     <MenuItem value="N/A">N/A</MenuItem>
                    <MenuItem value="Gauteng">Gauteng</MenuItem>
                    <MenuItem value="Eastern Cape">Eastern Cape</MenuItem>
                    <MenuItem value="Free State">Free State</MenuItem>
                    <MenuItem value="Kwa-Zulu/Natal">Kwa-Zulu/Natal</MenuItem>
                    <MenuItem value="Limpopo">Limpopo</MenuItem>
                    <MenuItem value="Mpumalanga">Mpumalanga</MenuItem>
                    <MenuItem value="North West">North West</MenuItem>
                    <MenuItem value="Northern Cape">Northern Cape</MenuItem>
                    <MenuItem value="Western Cape">Western Cape</MenuItem>
                    <MenuItem value="Western Cape">eSwatini</MenuItem>
                  </Select>
                </Box>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="12">
                <MDBInput
                  icon="country"
                  value={country}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'country',
                      value: e.target.value,
                    })
                  }
                  type="text"
                  id="txtCountry"
                  name="country"
                  label="Country"
                  validate
                  required
                >
                  <div className="invalid-feedback">
                    Please provide a country.
                  </div>
                  <div className="valid-feedback">Looks good!</div>
                </MDBInput>
              </MDBCol>
              </MDBRow>
            <MDBRow>
              <MDBCol md="6">
                <MDBInput
                  icon="clinic-medical"
                  value={practiceNumber}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'practiceNumber',
                      value: e.target.value,
                    })
                  }
                  type="number"
                  id="txtPracticeNumber"
                  name="practiceNumber"
                  label="Practice Number"
                ></MDBInput>
              </MDBCol>
              <MDBCol md="6">
                <MDBInput
                  icon="user-md"
                  value={speciality}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      field: 'speciality',
                      value: e.target.value,
                    })
                  }
                  type="text"
                  id="txtSpeciality"
                  name="speciality"
                  label="Speciality"
                ></MDBInput>
              </MDBCol>
            </MDBRow>
            {(add || edit) && (
              <div className="text-center mb-3">
                <MDBBtn color="warning" type="submit" disabled={loading}>
                  Save
                </MDBBtn>
                <MDBBtn
                  color="black"
                  type="button"
                  onClick={() => history.push('/doctors/')}
                >
                  Cancel
                </MDBBtn>
              </div>
            )}
          </form>
        </MDBCardBody>
      </MDBCard>
    </MDBAnimation>
  );
}

export default DoctorInfo;
