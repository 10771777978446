import MatrixRenderer from '../MatrixRenderer';
import Position from '../Position';
import { TextRenderer } from './TextRenderer';

export class QuoteRenderer extends MatrixRenderer {
  static RATIO_HEIGHT_OFFSET = 0.2;
  static RATIO_BAR_WIDTH = 0.3;

  static COLOR_BAR = '#ececec';
  static COLOR_TEXT = '#424242';

  #textRender;

  constructor() {
    super();
    this.#textRender = new TextRenderer();
  }

  onRenderText(line, absX, absY, width, height, renderingState) {
    //e.log("rendering");
    var pdfDocument = renderingState.getPdfDocument();
    pdfDocument.setFillColor(QuoteRenderer.COLOR_BAR);
    pdfDocument.rect(
      absX - renderingState.getTabSize(),
      absY +
        renderingState.getLineHeight() *
          (QuoteRenderer.RATIO_HEIGHT_OFFSET - 1),
      renderingState.getLineHeight() * QuoteRenderer.RATIO_BAR_WIDTH,
      renderingState.getLineHeight(),
      'F'
    );
  }

  render(matrix, boundary, prevMatrix, renderingState) {
    var barHieght = renderingState.getLineHeight();
    var barYOffset = barHieght;
    var preMatrixType = prevMatrix === undefined ? 'unknown' : prevMatrix.type;
    var pdfDocument = renderingState.getPdfDocument();

    renderingState.getPosition().setX(0);
    if (preMatrixType === 'blockquote') {
      if (!renderingState.movePositionY(barHieght, boundary)) {
        renderingState.movePositionY(barHieght, boundary);
      } else {
        renderingState.movePositionY(-2 * barHieght, boundary);
        var absX = renderingState.getAbsPositionX(boundary);
        var absY = renderingState.getAbsPositionY(boundary);

        pdfDocument.setFillColor(QuoteRenderer.COLOR_BAR);
        pdfDocument.rect(
          absX,
          absY + barHieght * QuoteRenderer.RATIO_HEIGHT_OFFSET,
          barHieght * QuoteRenderer.RATIO_BAR_WIDTH,
          barHieght,
          'F'
        );
        renderingState.movePositionY(barHieght, boundary);
      }
      barYOffset = 0;
    } else if (prevMatrix === undefined || !prevMatrix.hasPadding) {
      renderingState.movePositionY(barHieght, boundary, false);
    }

    var tab = renderingState.getTabSize();
    var boundaryWidth = boundary.getWidth();
    var widthTextBoundary = Math.max(0, boundaryWidth - 2 * tab);
    var positionTextBoundary = new Position(tab, 0, boundary.getPosition());

    var boundaryText = renderingState.requestBoundary(
      positionTextBoundary,
      widthTextBoundary
    );

    var absX = renderingState.getAbsPositionX(boundary);
    var absY = renderingState.getAbsPositionY(boundary);
    pdfDocument.setFillColor(QuoteRenderer.COLOR_BAR);
    pdfDocument.rect(
      absX,
      absY + barHieght * QuoteRenderer.RATIO_HEIGHT_OFFSET,
      barHieght * QuoteRenderer.RATIO_BAR_WIDTH,
      barHieght,
      'F'
    );

    this.#textRender.setOnRenderTextListener(
      (line, absX, absY, width, height) => {
        this.onRenderText(line, absX, absY, width, height, renderingState);
      }
    );
    this.#textRender.render(
      matrix,
      boundaryText,
      prevMatrix,
      renderingState,
      QuoteRenderer.COLOR_TEXT
    );
    this.#textRender.setOnRenderTextListener(undefined);

    var currentPosition = renderingState.getPosition();
    currentPosition.incrementY(renderingState.getLineHeight());
    currentPosition.setX(0);
    return this;
  }
}
