import StyleRange from './Range';

export class DraftJsCompiler {
  static TYPE_TEXT = 'unstyled';
  static TYPE_CODE = 'code-block';
  static TYPE_UNORDERED_LIST = 'unordered-list-item';
  static TYPE_ORDERED_LIST = 'ordered-list-item';
  static TYPE_HEADER = 'header-two';
  static TYPE_ATOMIC = 'atomic';
  static TYPE_QUOTE = 'blockquote';

  #onCompileBlockListener;

  createMatric(block, entities, hasPadding, ...Styles) {
    return {
      type: block.type,
      value: block.text,
      hasPadding,
      columns: this.buildColumns(block, entities, ...Styles),
    };
  }

  createAtomicMatric(block, entities, hasPadding, ...Styles) {
    return {
      type: block.type,
      value: block.text,
      hasPadding,
      columns: this.buildAtomicColumn(block, entities, ...Styles),
    };
  }

  filterBlocks(blocks) {
    var result = [];
    for (var pos = 0; pos < blocks.length; pos++) {
      var currentBlock = blocks[pos];
      var nextBlock = blocks[pos + 1];
      var nextnextBlock = blocks[pos + 2];

      if (
        nextnextBlock !== undefined &&
        nextnextBlock.type === 'atomic' &&
        currentBlock.type === 'atomic'
      ) {
        if (nextBlock.type === 'unstyled' && nextBlock.text === '') {
          result.push(currentBlock);
          pos++;
          continue;
        }
      }

      result.push(currentBlock);
    }
    return result;
  }

  async compile(rawContentState) {
    var blocks = this.filterBlocks(rawContentState.blocks);
    var entities = rawContentState.entityMap;
    const compiledMatrix = [];

    let prevMatrix = undefined;
    for (var pos = 0; pos < blocks.length; pos++) {
      var currentBlock = blocks[pos];
      var callback = this.#onCompileBlockListener || (() => {});
      var currentMatrix;

      switch (currentBlock.type) {
        case DraftJsCompiler.TYPE_TEXT:
        case DraftJsCompiler.TYPE_CODE:
          currentMatrix = this.createMatric(currentBlock, entities, false);
          await callback(currentMatrix, prevMatrix);
          break;
        case DraftJsCompiler.TYPE_UNORDERED_LIST:
        case DraftJsCompiler.TYPE_ORDERED_LIST:
          currentMatrix = this.createMatric(currentBlock, entities, true);
          await callback(currentMatrix, prevMatrix);
          break;
        case DraftJsCompiler.TYPE_QUOTE:
          currentMatrix = this.createMatric(
            currentBlock,
            entities,
            true,
            'ITALIC'
          );
          await callback(currentMatrix, prevMatrix);
          break;
        case DraftJsCompiler.TYPE_ATOMIC:
          currentMatrix = this.createAtomicMatric(
            currentBlock,
            entities,
            false
          );
          await callback(currentMatrix, prevMatrix);
          break;
        case DraftJsCompiler.TYPE_HEADER:
          currentMatrix = this.createMatric(
            currentBlock,
            entities,
            true,
            'BOLD'
          );
          await callback(currentMatrix, prevMatrix);
          break;
        default:
      }

      compiledMatrix.push(currentMatrix);
      if (currentMatrix !== undefined) {
        prevMatrix = Object.assign({}, currentMatrix);
      }
    }

    return compiledMatrix;
  }

  onCompileBlockListener(onCompileBlockListener) {
    this.#onCompileBlockListener = onCompileBlockListener;
  }

  buildAtomicColumn(block, entityMap) {
    var entityRanges = block.entityRanges;
    var result = [];

    entityRanges.forEach((entityRange) => {
      // var newStyleRange = new StyleRange(
      //   entityRange.offset,
      //   entityRange.offset + entityRange.length
      // );
      var key = entityRange.key;

      var entity = entityMap[key];
      if (entity.type === 'IMAGE') {
        var data = entity.data;
        var defualtCaption = '';
        var value = {
          alignment: data.alignment,
          url: data.url,
          caption: data.caption || defualtCaption,
        };
        result.push({ type: 'IMAGE', value });
      }
    });
    return result;
  }

  buildColumns(block, entities, ...pstyles) {
    var text = block.text;
    var styles = [
      {
        type: 'text',
        range: new StyleRange(0, text.length, ...pstyles),
        data: undefined,
      },
    ];

    var inlineStyleRange = block.inlineStyleRanges;
    inlineStyleRange.forEach((inlineStyle) => {
      var newStyles = [];
      var newStyleRange = new StyleRange(
        inlineStyle.offset,
        inlineStyle.offset + inlineStyle.length,
        inlineStyle.style
      );

      styles.forEach((style) => {
        var range = style.range;
        if (StyleRange.Intersects(newStyleRange, range)) {
          var union = StyleRange.UnionLeft(range, newStyleRange);
          var e = union.map((_range) => {
            return {
              type: 'text',
              range: _range,
              data: undefined,
            };
          });
          newStyles.push(...e);
        } else {
          newStyles.push(style);
        }
      });

      styles = newStyles;
    });

    var entityRanges = block.entityRanges;

    entityRanges.forEach((entityRange) => {
      var newStyles = [];
      var newStyleRange = new StyleRange(
        entityRange.offset,
        entityRange.offset + entityRange.length
      );
      var key = entityRange.key;
      var entity = entities[key];
      var entityType = entity.type;

      styles.forEach((style) => {
        var range = style.range;
        if (StyleRange.Intersects(newStyleRange, range)) {
          var union = StyleRange.UnionLeft(range, newStyleRange);
          var e = union.map((_range, pos) => {
            return {
              type: pos === 0 ? entityType : 'text',
              range: _range,
              data: entity.data,
            };
          });
          newStyles.push(...e);
        } else {
          newStyles.push(style);
        }
      });

      styles = newStyles;
    });

    return styles.sort(
      (sr1, sr2) => sr1.range.getStartIndex() - sr2.range.getStartIndex()
    );
  }
}
