import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useHistory } from 'react-router-dom';
import {
  MDBAnimation,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSwitch,
  MDBTimePicker,
} from 'mdbreact';
import {
  Box,
  Divider,
  TextField,
  Tooltip,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Success from '../Success';
// import Error from '../Error';
import Spinner from '../Spinner';
import { isFormValid } from '../../shared/utility';
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker } from '@material-ui/pickers';

const initialState = {
  selIndex: -1,
  specimenId: '',
  patient: '',
  doctors: '',
  urgent: false,
  specimenType: '',
  histologyNo: '',
  specimenNo: '',
  icd10code: '',
  specimenDate: new Date(),
  specimenTime: new Date(),
  specimenNature: '',
  clinicalData: '',
  hospitalPatient: false,
  hospitalName: '',
  hospitalWard: '',
  copyDoctors: '',
  selectedCopyDoctors: [],
  copyDoctorsDetail: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return initialState;
    }
    case 'field': {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case 'date': {
      return {
        ...state,
        specimenDate: action.value,
      };
    }
    default:
  }
};

function AddSpecimen({
  Doctors,
  Patient,
  Specimen,
  loading,
  error,
  onAddPatient,
  onAddSpecimen,
  onClearDoctor,
  onClearError,
  justSpecimen,
  ...props
}) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    selIndex,
    patient,
    urgent,
    specimenId,
    specimenType,
    histologyNo,
    specimenNo,
    icd10code,
    specimenDate,
    specimenTime,
    specimenNature,
    //clinicalData,
    hospitalPatient,
    hospitalName,
    hospitalWard,
    doctors,
    selectedCopyDoctors,
  } = state;

  const handleSpecimenDate = (e) => {
    dispatch({
      type: 'date',
      value: e,
    });
  };

  const handleTag = (event, value) => {
    if (value) {
      const index = Doctors.findIndex((x) => x.mnemonic === value.mnemonic);
      if (index !== -1) {
        dispatch({
          type: 'field',
          field: 'patient',
          value: Patient,
        });
        dispatch({
          type: 'field',
          value: value.id,
          field: 'doctors',
        });
        dispatch({
          type: 'field',
          value: index,
          field: 'selIndex',
        });
      }
    } else {
      dispatch({
        type: 'field',
        value: '',
        field: 'doctors',
      });
      dispatch({
        type: 'field',
        value: -1,
        field: 'selIndex',
      });
    }
  };

  const handleTag2 = (event, value) => {
    if (value.length > 0) {
      Doctors.findIndex((x) => x.mnemonic === value.mnemonic);
      const indexes = value.map((x) =>
        Doctors.findIndex((y) => y.mnemonic === x.mnemonic)
      );
      const cdocs = indexes.map((x) => Doctors[x]);
      const copydrs = value.map((x) => x.email).join(',');
      const copydrsdetail = value.map((x) => x.id);
      dispatch({
        type: 'field',
        value: copydrs,
        field: 'copyDoctors',
      });
      dispatch({
        type: 'field',
        value: cdocs,
        field: 'selectedCopyDoctors',
      });
      dispatch({
        type: 'field',
        value: copydrsdetail,
        field: 'copyDoctorsDetail',
      });
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    event.target.className += ' was-validated';
    if (!isFormValid(event)) return;
    if (!!specimenId) props.onEditSpecimen(state);
    else if (justSpecimen) onAddSpecimen(state);
    else onAddPatient(patient.mainMember, patient, state);
    while (loading) { }
    history.push('/patients/');
  };

  if (Specimen && !!!doctors) {
    dispatch({
      type: 'field',
      value: Specimen.doctors.id,
      field: 'doctors',
    });
    // dispatch({
    //   type: 'field',
    //   value: Doctors.findIndex((x) => x.id === Specimen.doctors.id),
    //   field: 'selIndex',
    // });
    // const ids = Specimen.copyDoctorsDetail
    //   .map((x) => x.id)
    //   .map((x) => Doctors.findIndex((y) => y.id === x));
    // console.log(`ids`, ids);
    // dispatch({
    //   type: 'field',
    //   value: ids.map((x) => Doctors[x]),
    //   field: 'copyDoctorsDetail',
    // });
    dispatch({
      type: 'field',
      field: 'specimenId',
      value: Specimen.id,
    });
    dispatch({
      type: 'field',
      field: 'specimenType',
      value: Specimen.specimenType,
    });
    dispatch({
      type: 'field',
      field: 'urgent',
      value: Specimen.urgent,
    });
    dispatch({
      type: 'field',
      field: 'hospitalPatient',
      value: !!Specimen.hospitalName,
    });
    dispatch({
      type: 'field',
      field: 'specimenTime',
      value: Specimen.specimenTime,
    });
    dispatch({
      type: 'field',
      field: 'specimenDate',
      value: Specimen.specimenDate,
    });
    dispatch({
      type: 'field',
      field: 'histologyNo',
      value: Specimen.histologyNo,
    });
    dispatch({
      type: 'field',
      field: 'specimenNo',
      value: Specimen.specimenNo,
    });
    dispatch({
      type: 'field',
      field: 'icd10code',
      value: Specimen.icd10code,
    });
    dispatch({
      type: 'field',
      field: 'specimenNature',
      value: Specimen.specimenNature,
    });
    dispatch({
      type: 'field',
      field: 'clinicalData',
      value: Specimen.clinicalData,
    });
    dispatch({
      type: 'field',
      field: 'hospitalName',
      value: Specimen.hospitalName,
    });
    dispatch({
      type: 'field',
      field: 'hospitalWard',
      value: Specimen.hospitalWard,
    });
  }

  return (
    <MDBAnimation type="bounce">
      {props.sloading === false ? (
        <MDBCard className="mt-3 mb-5">
          <MDBCardBody>
            <h5 className="pb-2">Specimen Details</h5>
            <Box mb={5}>
              <Divider className="orange" />
            </Box>
            <form
              className="needs-validation"
              onSubmit={submitHandler}
              noValidate
            >
              <MDBRow>
                {Doctors && !Specimen ? (
                  <>
                    <MDBCol md="6" className="mb-2">
                      <Autocomplete
                        value={Doctors[selIndex]}
                        style={{ width: 370 }}
                        options={Doctors}
                        getOptionLabel={(option) =>
                          option.mnemonic +
                          ' - Dr. ' +
                          option.name +
                          ' ' +
                          option.surname
                        }
                        // onBlur={(event) => handleBlur(event)}
                        onChange={(event, value) => handleTag(event, value)}
                        autoHighlight
                        renderOption={({
                          mnemonic,
                          name,
                          surname,
                          location,
                          email,
                          ...props
                        }) => {
                          return (
                            <div>
                              <Tooltip
                                title={`${email} - ${location.formattedAddress}`}
                                placement="bottom"
                              >
                                <div>
                                  {mnemonic} - {`Dr ${name} ${surname}`}
                                </div>
                              </Tooltip>
                            </div>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Doctors"
                            margin="normal"
                            error={!!!doctors}
                            required
                          />
                        )}
                      />
                    </MDBCol>
                    <MDBCol md="6" className="mb-2">
                      <Box mt={2}>
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={Doctors}
                          getOptionLabel={(option) =>
                            option.mnemonic +
                            ' - Dr. ' +
                            option.name +
                            ' ' +
                            option.surname
                          }
                          onChange={(event, value) => handleTag2(event, value)}
                          value={selectedCopyDoctors}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Copy Doctors"
                              placeholder="Select copy Doctors"
                            />
                          )}
                        />
                      </Box>
                    </MDBCol>
                  </>
                ) : (
                  <>
                    <MDBCol md="6" className="mb-2">
                      <MDBInput
                        name="doctor"
                        label="Copy Doctor"
                        icon="file-medical-alt"
                        type="text"
                        value={`${Specimen.doctors.mnemonic} - Dr. ${Specimen.doctors.name} ${Specimen.doctors.surname}`}
                      />
                    </MDBCol>
                    <MDBCol md="6" className="mb-2">
                      <MDBInput
                        name="copydoctors"
                        label="Copy Doctor"
                        icon="file-medical-alt"
                        type="text"
                        value={Specimen.copyDoctorsDetail
                          .map(
                            (x) => `${x.mnemonic} - Dr. ${x.name} ${x.surname}`
                          )
                          .join('')}
                      />
                    </MDBCol>
                  </>
                )}
              </MDBRow>
              <MDBRow>
                <MDBCol md="2" className="mb-2">
                  <Box mt={2}>
                    <label>Urgent</label>
                    <MDBSwitch
                      labelLeft="No"
                      labelRight="Yes"
                      checked={urgent}
                      onChange={() =>
                        dispatch({
                          type: 'field',
                          field: 'urgent',
                          value: !urgent,
                        })
                      }
                    />
                  </Box>
                </MDBCol>
                <MDBCol md="2" className="mb-2">
                  <Box mt={2}>
                    <label>Hospital Patient</label>
                    <MDBSwitch
                      labelLeft="No"
                      labelRight="Yes"
                      checked={hospitalPatient}
                      onChange={() =>
                        dispatch({
                          type: 'field',
                          field: 'hospitalPatient',
                          value: !hospitalPatient,
                        })
                      }
                    />
                  </Box>
                </MDBCol>
                <MDBCol md="4" className="mb-2">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      required
                      disableFuture
                      format="dd-MM-yyyy"
                      margin="normal"
                      id="dtSpecimenDate"
                      label="Specimen Date"
                      value={specimenDate}
                      onChange={handleSpecimenDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    >
                      <div className="invalid-feedback">
                        Specimen date is required.
                      </div>
                      <div className="valid-feedback">Looks good!</div>
                    </KeyboardDatePicker>
                  </MuiPickersUtilsProvider>
                </MDBCol>
                <MDBCol md="4" className="mb-2">
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <TimePicker className='mt-3'
                        ampm={false}
                        openTo="hours"
                        views={['hours', 'minutes']}
                        format="HH:mm"
                        label="Specimen Time"
                        value={specimenTime}
                        onChange={(value) =>
                          dispatch({
                            type: 'field',
                            field: 'specimenTime',
                            value: value,
                          })}
                      /></MuiPickersUtilsProvider>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="3" className="mb-2">
                  <Box mt={1}>
                    <InputLabel id="ddlspecType-label">
                      Specimen Type
                    </InputLabel>
                    <Select
                      style={{ width: '25ch' }}
                      required
                      error={
                        !!specimenType
                          ? specimenType.trim().length === 0
                          : false
                      }
                      labelId="ddlspecType-label"
                      id="ddlSpecType"
                      value={specimenType}
                      onChange={(e) =>
                        dispatch({
                          type: 'field',
                          field: 'specimenType',
                          value: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="HIS">Histology</MenuItem>
                      <MenuItem value="CYT">Cytology</MenuItem>
                    </Select>
                  </Box>
                </MDBCol>
                <MDBCol md="3" className="mb-2">
                  <MDBInput
                    required
                    name="histologyNo"
                    label="Histology Number"
                    icon="vial"
                    type="text"
                    validate
                    value={histologyNo}
                    onChange={(event) =>
                      dispatch({
                        type: 'field',
                        field: 'histologyNo',
                        value: event.target.value,
                      })
                    }
                  >
                    <div className="invalid-feedback">
                      Histology number is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="3" className="mb-2">
                  <MDBInput
                    required
                    name="specimenNo"
                    label="Specimen Number"
                    icon="vial"
                    type="text"
                    validate
                    value={specimenNo}
                    onChange={(event) =>
                      dispatch({
                        type: 'field',
                        field: 'specimenNo',
                        value: event.target.value,
                      })
                    }
                  >
                    <div className="invalid-feedback">
                      Specimen number is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput>
                </MDBCol>
                <MDBCol md="3" className="mb-2">
                  <MDBInput
                    name="icd10code"
                    label="ICD10 Code"
                    icon="laptop-medical"
                    type="text"
                    validate
                    value={icd10code}
                    onChange={(event) =>
                      dispatch({
                        type: 'field',
                        field: 'icd10code',
                        value: event.target.value,
                      })
                    }
                  ></MDBInput>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    required
                    name="specimenNature"
                    type="textarea"
                    label="Nature of Sepcimen"
                    icon="book-medical"
                    value={specimenNature}
                    onChange={(event) =>
                      dispatch({
                        type: 'field',
                        field: 'specimenNature',
                        value: event.target.value,
                      })
                    }
                    validate
                  >
                    <div className="invalid-feedback">
                      Nature of specimen number is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>
              {/* <MDBRow>
                <MDBCol>
                  <MDBInput
                    required
                    name="clinicalData"
                    type="textarea"
                    label="Clinical Data"
                    icon="notes-medical"
                    value={clinicalData}
                    onChange={(event) =>
                      dispatch({
                        type: 'field',
                        field: 'clinicalData',
                        value: event.target.value,
                      })
                    }
                    validate
                  >
                    <div className="invalid-feedback">
                      Clinical data is required.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput>
                </MDBCol>
              </MDBRow> */}
              {hospitalPatient && (
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      required
                      validate
                      name="hospitalName"
                      label="Hospital Name"
                      icon="hospital"
                      type="text"
                      value={hospitalName}
                      onChange={(event) =>
                        dispatch({
                          type: 'field',
                          field: 'hospitalName',
                          value: event.target.value,
                        })
                      }
                    >
                      <div className="invalid-feedback">
                        Hospital name number is required.
                      </div>
                      <div className="valid-feedback">Looks good!</div>
                    </MDBInput>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput
                      required
                      name="hospitalWard"
                      label="Hospital Ward"
                      icon="id-card-alt"
                      type="text"
                      value={hospitalWard}
                      onChange={(event) =>
                        dispatch({
                          type: 'field',
                          field: 'hospitalWard',
                          value: event.target.value,
                        })
                      }
                    >
                      <div className="invalid-feedback">
                        Hospital ward number is required.
                      </div>
                      <div className="valid-feedback">Looks good!</div>
                    </MDBInput>
                  </MDBCol>
                </MDBRow>
              )}
              <div className="text-center mb-3">
                <MDBBtn color="warning" type="submit">
                  Save
                </MDBBtn>
                <MDBBtn color="black" type="button">
                  Cancel
                </MDBBtn>
              </div>
            </form>
          </MDBCardBody>
        </MDBCard>
      ) : (
        <Spinner />
      )}
    </MDBAnimation>
  );
}

const mapStateToProps = (appstate) => {
  return {
    sloading: appstate.specimen.loading,
    serror: appstate.specimen.error,
    success: appstate.specimen.success,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEditSpecimen: (specimen) => dispatch(actions.editSpecimenStart(specimen)),
    onResetSpecimen: () => dispatch(actions.resetSpecimen()),
    onClearSpecimenError: () => dispatch(actions.clearSpecimenError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSpecimen);
