import * as actionTypes from '../actions/actionTypes';
import { updateObject, updateObjectInArray } from '../../shared/utility';

const initialState = {
  loading: false,
  success: false,
  error: null,
  doctorId: null,
  doctor: null,
  doctors: null,
};

const fetchDoctorStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const fetchDoctorSuccess = (state, action) => {
  return updateObject(state, {
    doctor: action.doctor,
    loading: false,
  });
};

const fetchDoctorFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const fetchDoctorsStart = (state) => {
  return updateObject(state, { loading: true, error: '' });
};

const fetchDoctorsSuccess = (state, action) => {
  return updateObject(state, {
    doctors: action.doctors.filter((x) => x.mnemonic !== undefined),
    loading: false,
  });
};

const fetchDoctorsFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addDoctorStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
    doctor: null,
    doctorId: null,
  });
};

const addDoctorSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    doctor: action.doctor,
    doctorId: action.doctor.id,
  });
};

const addDoctorFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const clearDoctor = (state) => {
  return updateObject(state, {
    loading: false,
    error: null,
    doctorId: null,
  });
};

const clearDoctorError = (state) => {
  return updateObject(state, {
    loading: false,
    error: null,
  });
};

const editDoctorStart = (state) => {
  return updateObject(state, {
    loading: true,
    error: null,
    doctorId: null,
  });
};

const editDoctorSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    doctorId: action.doctor.id,
    doctors: updateObjectInArray(state.doctors, { item: action.doctor }),
  });
};

const editDoctorFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCTOR_START:
      return fetchDoctorStart(state);
    case actionTypes.FETCH_DOCTOR_SUCCESS:
      return fetchDoctorSuccess(state, action);
    case actionTypes.FETCH_DOCTOR_FAIL:
      return fetchDoctorFail(state, action);
    case actionTypes.FETCH_DOCTORS_START:
      return fetchDoctorsStart(state);
    case actionTypes.FETCH_DOCTORS_SUCCESS:
      return fetchDoctorsSuccess(state, action);
    case actionTypes.FETCH_DOCTORS_FAIL:
      return fetchDoctorsFail(state, action);
    case actionTypes.ADD_DOCTOR_START:
      return addDoctorStart(state);
    case actionTypes.ADD_DOCTOR_SUCCESS:
      return addDoctorSuccess(state, action);
    case actionTypes.ADD_DOCTOR_FAIL:
      return addDoctorFail(state, action);
    case actionTypes.EDIT_DOCTOR_START:
      return editDoctorStart(state);
    case actionTypes.EDIT_DOCTOR_SUCCESS:
      return editDoctorSuccess(state, action);
    case actionTypes.EDIT_DOCTOR_FAIL:
      return editDoctorFail(state, action);
    case actionTypes.CLEAR_DOCTOR:
      return clearDoctor(state);
    case actionTypes.CLEAR_DOCTOR_ERROR:
      return clearDoctorError(state);
    default:
      return state;
  }
};

export default reducer;
