import * as actionTypes from '../actions/actionTypes';
import { updateObject, updateObjectSection, addObjectSection, updatedNestedObject, deleteObjectSection } from '../../shared/utility';

const initialState = {
  step: 1,
  report: {},
  codes: null,
  status: '',
  reportId: '',
  title: 'Histopathology Report',
  clinicalData: '',
  macroscopic: '',
  microscopic: '',
  diagnosis: '',
  comments: '',
  addendum: '',
  adequacy: '',
  specimenNature: '',
  flora: '',
  background: '',
  morphologySquamous: '',
  morphologyGlandular: '',
  cytoDiagnosis: '',
  cytoSubDiagnosis:'',
  loading: false,
  error: '',
  reports: [],
  texts: [],
  name: null,
};

const resetState = (state) => {
  return updateObject(state, initialState);
};

const fetchICD10Start = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const fetchICD10Success = (state, action) => {
  return updateObject(state, { loading: false, codes: action.codes });
};

const fetchICD10Fail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const fetchCytologyTextStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const fetchCytologyTextSuccess = (state, action) => {
  return updateObject(state, { loading: false, texts: action.texts });
};

const fetchCytologyTextFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const fetchReportStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const fetchReportSuccess = (state, action) => {
  return updateObject(state, {
    report: action.report,
    reportId: action.report.id,
    loading: false,
  });
};

const updateCytoSingle = (state, action) => {
  return updateObject(state, { report: updatedNestedObject(state, action) });
};

const updateCytoSelection = (state, action) => {
  return updateObject(state, { report: updateObjectSection(state, action) });
};

const addCytoSelection = (state, action) => {
  return updateObject(state, { report: addObjectSection(state, action) });
};

// const addCytoSelection = (state, action) => {
//   return updateObject(state, { [action.item.subSelection]: addObjectSection(state, action) });
// };


const deleteCytoSingle = (state, action) => {
  return updateObject(state, { report: deleteObjectSection(state, action) });
};

const fetchReportFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const fetchReportsStart = (state) => {
  return updateObject(state, { loading: true, error: null });
};

const fetchReportsSuccess = (state, action) => {
  return updateObject(state, {
    reports: action.reports,
    loading: false,
  });
};

const fetchReportsFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateSectionStart = (state) => {
  return updateObject(state, { loading: true, error: '' });
};

const updateSectionSuccess = (state, action) => {
  return updateObject(state, {
    reportId: action.data.reportId,
    report: action.data.report,
    loading: false,
  });
};

const updateSectionFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateAuditTrailStart = (state) => {
  return updateObject(state, { loading: true, error: '' });
};

const updateAuditTrailSuccess = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateAuditTrailFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addReportStart = (state) => {
  return updateObject(state, { loading: true, error: '' });
};

const addReportSuccess = (state, action) => {
  return updateObject(state, {
    report: action.report,
    reportId: action.report.id,
    loading: false,
  });
};

const addReportFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updatedReportStart = (state) => {
  return updateObject(state, { loading: true, error: '' });
};

const updateReportSuccess = (state, action) => {
  return updateObject(state, { loading: false, report: action.report });
};

const updateReportFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const clearReportError = (state) => {
  return updateObject(state, { error: '' });
};

const sendReportStart = (state) => {
  return updateObject(state, { loading: true, error: null, report: {} });
};

const sendReportSuccess = (state, action) => {
  return updateObject(state, { loading: false, name: action.name });
};

const sendReportFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ICD10CODES_START:
      return fetchICD10Start(state);
    case actionTypes.FETCH_ICD10CODES_SUCCESS:
      return fetchICD10Success(state, action);
    case actionTypes.FETCH_ICD10CODES_FAIL:
      return fetchICD10Fail(state, action);
    case actionTypes.FETCH_CYTOLOGYTEXT_START:
      return fetchCytologyTextStart(state);
    case actionTypes.FETCH_CYTOLOGYTEXT_SUCCESS:
      return fetchCytologyTextSuccess(state, action);
    case actionTypes.FETCH_CYTOLOGYTEXT_FAIL:
      return fetchCytologyTextFail(state, action);
    case actionTypes.FETCH_REPORT_START:
      return fetchReportStart(state, action);
    case actionTypes.FETCH_REPORT_SUCCESS:
      return fetchReportSuccess(state, action);
    case actionTypes.FETCH_REPORT_FAIL:
      return fetchReportFail(state, action);
    case actionTypes.FETCH_REPORTS_START:
      return fetchReportsStart(state);
    case actionTypes.FETCH_REPORTS_SUCCESS:
      return fetchReportsSuccess(state, action);
    case actionTypes.FETCH_REPORTS_FAIL:
      return fetchReportsFail(state, action);
    case actionTypes.UPDATE_AUDITTRAIL_START:
      return updateAuditTrailStart(state);
    case actionTypes.UPDATE_AUDITTRAIL_SUCCESS:
      return updateAuditTrailSuccess(state, action);
    case actionTypes.UPDATE_AUDITTRAIL_FAIL:
      return updateAuditTrailFail(state, action);
    case actionTypes.UPDATE_SECTION_START:
      return updateSectionStart(state);
    case actionTypes.UPDATE_SECTION_SUCCESS:
      return updateSectionSuccess(state, action);
    case actionTypes.UPDATE_SECTION_FAIL:
      return updateSectionFail(state, action);
    case actionTypes.ADD_REPORT_START:
      return addReportStart(state);
    case actionTypes.ADD_REPORT_SUCCESS:
      return addReportSuccess(state, action);
    case actionTypes.ADD_REPORT_FAIL:
      return addReportFail(state, action);
    case actionTypes.UPDATE_REPORT_START:
      return updatedReportStart(state, action);
    case actionTypes.UPDATE_REPORT_SUCCESS:
      return updateReportSuccess(state, action);
    case actionTypes.UPDATE_REPORT_FAIL:
      return updateReportFail(state, action);
    case actionTypes.CLEAR_REPORT_ERROR:
      return clearReportError(state, action);
    case actionTypes.RESET_STATE:
      return resetState(state);
    case actionTypes.SEND_REPORT_START:
      return sendReportStart(state);
    case actionTypes.SEND_REPORT_SUCCESS:
      return sendReportSuccess(state, action);
    case actionTypes.SEND_REPORT_FAIL:
      return sendReportFail(state, action);
    case actionTypes.UPDATE_SELECTION:
      return updateCytoSelection(state, action);
    case actionTypes.ADD_SELECTION:
      return addCytoSelection(state, action);
    case actionTypes.UPDATE_SINGLE_SELECTION:
      return updateCytoSingle(state, action)
    case actionTypes.DELETE_SELECTION:
      return deleteCytoSingle(state,action)
    default:
      return state;
  }
};

export default reducer;
