import React from 'react';
import MUIRichTextEditor from 'mui-rte';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBInput,
  MDBCardBody,
  MDBBtn,
  MDBAnimation,
} from 'mdbreact';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  MontserratBold,
  MontserratBoldItalic,
  MontserratItalic,
  MontserratRegular,
} from '../../../assets/fonts/MontserratFont';
import { headerImage } from '../../../assets/longHeaderb64.js';
import { format } from 'date-fns';
import { parseISOString, uploadReportToServer, uploadAddendumReportToServer, titleCase } from '../../../shared/utility';
import '../../../assets/fonts/Montserrat-Regular-normal';
import { DraftJsCompiler } from '../../../shared/jspdf/DraftJsCompiler';
import StyleRange from '../../../shared/jspdf/Range';
import PDFRenderer from '../../../shared/jspdf/PDFRenderer';
import Position from '../../../shared/jspdf/Position';
import ConfirmDialog from '../../ConfirmModal';
import Success from '../../Success';
import FormAddendum from './FormAddendum';

function FormFinal(props) {
  const {
    onChange,
    prevStep,
    title,
    report,
    animation,
    specimen,
    optcomments,
    sendEmail,
    addendum,
    updateReport
  } = props;

  const {
    //urgent,
    specimenDate,
    specimenTime,
    //clinicalData,
    histologyNo,
    specimenNature,
    specimenType,
    patient,
    doctors,
    copyDoctors,
  } = specimen;

  const [open, setOpen] = React.useState(false);
  const [sopen, setSopen] = React.useState(false);
  const [bAddendun, setBaddendun] = React.useState(false);

  const defaultTheme = createTheme();
  var blob;

  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          marginTop: 5,
          minHeight: 150,
        },
        editor: {
          borderBottom: '1px solid gray',
        },
      },
    },
  });

  const FinaliseReport = () => {
    createPDF(false);
    handleClose();
  };

  const AddAddendumReport = () => {
    createAddendumPDF(false);
    handleClose();
  };

  const previewReport = () => {
    createPDF(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSOpen = () => {
    setSopen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSClose = () => {
    setSopen(false);
  };

  const handleAddendumPreview = () => {
    createAddendumPDF(true);
  }

  const handleAddendumClose = () => {
    setBaddendun(false);
  };

  const createAddendumPDF = async (preview) => {
    const fonts = [
      {
        fileName: 'montserrat-normal.ttf',
        fBase64: MontserratRegular,
        name: 'montserrat',
        style: 'normal',
      },
      {
        fileName: 'montserrat-bold.ttf',
        fBase64: MontserratBold,
        name: 'montserrat',
        style: 'bold',
      },
      {
        fileName: 'montserrat-italic.ttf',
        fBase64: MontserratItalic,
        name: 'montserrat',
        style: 'italic',
      },
      {
        fileName: 'montserrat-bolditalic.ttf',
        fBase64: MontserratBoldItalic,
        name: 'montserrat',
        style: 'bolditalic',
      },
    ];
    const rAddendum = JSON.parse(report.addendum);

    const draftCompiler = new DraftJsCompiler();
    const pdfRenderer = new PDFRenderer();
    pdfRenderer.addCustomFonts(fonts).then(async () => {
      pdfRenderer.setFont('montserrat');
      const boundary = pdfRenderer.requestBoundary(new Position(0, 0));
      draftCompiler.onCompileBlockListener(
        async (currentMatrix, prevMatrix) => {
          await pdfRenderer.renderMatrix(currentMatrix, prevMatrix, boundary);
        }
      );
    });
    var fontSize = 9;
    if (specimenType !== 'CYT') {
      pdfRenderer.setFontSize(11);
      fontSize = 11;
    } else {
      pdfRenderer.setFontSize(9);
      fontSize = 9;
    }
    var headerSize = await pdfRenderer.renderHeaderImage(headerImage);
    pdfRenderer.setPosition(new Position(0, headerSize.height - 5));
    let text = `Patient: ${patient.title} ${patient.name} ${patient.surname}, ${patient.location.formattedAddress}`;
    await pdfRenderer.renderComplexText(
      text,
      new StyleRange(0, text.length + 7, 'BOLD')
    );
    await pdfRenderer.renderEmptyLines(1);
    text = `Ref No: ${histologyNo}`;
    await pdfRenderer.renderComplexText(
      text,
      new StyleRange(0, 6, 'BOLD'),
      new StyleRange(6, text.length, '')
    );
    if (patient.mainMember.paymentType === 'Medical Aid') {
      text = `Medical Aid: ${titleCase(patient.mainMember.medicalAid.descr)}`;
      pdfRenderer.setPosition(new Position(pdfRenderer.getJustifyX(text), pdfRenderer.getCurrentPositionY()));
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 13, 'BOLD'),
        new StyleRange(13, text.length, '')
      );
    }
    else {
      text = `Payment Type: CASH`;
      pdfRenderer.setPosition(new Position(pdfRenderer.getJustifyX(text), pdfRenderer.getCurrentPositionY()));
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 14, 'BOLD'),
        new StyleRange(14, text.length, '').setColor('#800000')
      );
    }
    text = `ID Number: ${patient.idnumber}`;
    await pdfRenderer.renderComplexText(
      text,
      new StyleRange(0, 10, 'BOLD'),
      new StyleRange(10, text.length, '')
    );
    if (patient.mainMember.paymentType === 'Medical Aid') {
      text = `Medical Aid Number: ${patient.mainMember.medicalAidNumber}`;
      pdfRenderer.setPosition(new Position(pdfRenderer.getJustifyX(text), pdfRenderer.getCurrentPositionY()));
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 20, 'BOLD'),
        new StyleRange(20, text.length, '')
      );
    }
    await pdfRenderer.renderComplexText(
      `Received: ${format(
        parseISOString(specimenDate),
        'dd MMM yyyy'
      )} ${specimenTime}`,
      new StyleRange(0, 8, 'BOLD'),
      new StyleRange(8, 27, '')
    );
    if (patient.mainMember.paymentType === 'Medical Aid') {
      text = `Medical Aid Option: ${patient.mainMember.option}`;
      pdfRenderer.setPosition(new Position(pdfRenderer.getJustifyX(text), pdfRenderer.getCurrentPositionY()));
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 20, 'BOLD'),
        new StyleRange(20, text.length, '')
      );
    }
    await pdfRenderer.renderComplexText(
      `Issued: ${format(new Date(), 'dd MMM yyyy HH:mm')}`,
      new StyleRange(0, 7, 'BOLD'),
      new StyleRange(7, 27, '')
    );
    if (patient.mainMember.paymentType === 'Medical Aid') {
      text = `Dependant Code: ${patient.dependantCode}`;
      pdfRenderer.setPosition(new Position(pdfRenderer.getJustifyX(text), pdfRenderer.getCurrentPositionY()));
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 16, 'BOLD'),
        new StyleRange(16, text.length, '')
      );
    }
    await pdfRenderer.renderEmptyLines(1);
    var titleText = !doctors.title ? 'Doctor: Dr.' : `Client: ${doctors.title}`;
    text = `${titleText} ${doctors.name} ${doctors.surname}, ${doctors.location.formattedAddress}`;
    await pdfRenderer.renderComplexText(
      text,
      new StyleRange(0, 6, 'BOLD'),
      new StyleRange(6, text.length, 'ITALIC BOLD')
    );
    await pdfRenderer.renderEmptyLines(1);
    await pdfRenderer.renderCenteredText('Addendum', 'BOLD');
    //await pdfRenderer.renderEmptyLines(1);
    //await pdfRenderer.renderHeader('Addendum', fontSize);
    var savableCompiledMatrices = await draftCompiler.compile(rAddendum);

    if (preview) pdfRenderer.save('demo.pdf');
    else {
      await pdfRenderer.renderEmptyLines(1);
      text = 'Signed by: Dr. N.H. Hlatshwayo';
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 10, 'BOLD'),
        new StyleRange(10, text.length, '')
      );
      await pdfRenderer.renderEmptyLines(1);
      text = format(new Date(), 'dd-MMM-yyyy HH:mm');
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, text.length, '')
      );
      await pdfRenderer.renderEmptyLines(1);
      text = 'For consultation: 082 327 9039';
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 17, 'BOLD'),
        new StyleRange(17, text.length, '')
      );
      blob = pdfRenderer.output('blob', histologyNo);
      blob.lastModifiedDate = new Date();
      blob.name = `Report.pdf`;
      uploadAddendumReportToServer(blob, report.id)
        .then((res) => {
          console.log('email sent!');
          handleAddendumClose();
          handleSOpen();
          sendEmail({
            email: specimen.doctors.email,
            patientName: `${patient.name} ${patient.surname}`,
            histologyNo,
            specimenNature,
            copyDoctors,
            title: !doctors.title ? 'Dr.' : doctors.title,
            recepientName: `${specimen.doctors.name} ${specimen.doctors.surname}`,
            filename: res.data.url.substring(res.data.url.lastIndexOf('/') + 1),
          });
        })
        .then(() => {
          props.updateReport(
            {
              status: 'addended',
              addendum
            },
            props.reportId
          );
        });
    }
  };

  const createPDF = async (preview) => {
    const fonts = [
      {
        fileName: 'montserrat-normal.ttf',
        fBase64: MontserratRegular,
        name: 'montserrat',
        style: 'normal',
      },
      {
        fileName: 'montserrat-bold.ttf',
        fBase64: MontserratBold,
        name: 'montserrat',
        style: 'bold',
      },
      {
        fileName: 'montserrat-italic.ttf',
        fBase64: MontserratItalic,
        name: 'montserrat',
        style: 'italic',
      },
      {
        fileName: 'montserrat-bolditalic.ttf',
        fBase64: MontserratBoldItalic,
        name: 'montserrat',
        style: 'bolditalic',
      },
    ];
    const rClinical = JSON.parse(report.clinicalData);
    const rMacro = JSON.parse(report.macroscopic);
    const rMicro = JSON.parse(report.microscopic);
    const rDiag = JSON.parse(report.diagnosis);
    if (optcomments) var rComms = JSON.parse(report.comments);
    const draftCompiler = new DraftJsCompiler();
    const pdfRenderer = new PDFRenderer();
    pdfRenderer.addCustomFonts(fonts).then(async () => {
      pdfRenderer.setFont('montserrat');
      const boundary = pdfRenderer.requestBoundary(new Position(0, 0));
      draftCompiler.onCompileBlockListener(
        async (currentMatrix, prevMatrix) => {
          await pdfRenderer.renderMatrix(currentMatrix, prevMatrix, boundary);
        }
      );
    });
    var fontSize = 9;
    if (specimenType !== 'CYT') {
      pdfRenderer.setFontSize(11);
      fontSize = 11;
    } else {
      pdfRenderer.setFontSize(9);
      fontSize = 9;
    }
    var headerSize = await pdfRenderer.renderHeaderImage(headerImage);
    pdfRenderer.setPosition(new Position(0, headerSize.height - 5));
    let text = `Patient: ${patient.title} ${patient.name} ${patient.surname}, ${patient.address1}, ${patient.city},${patient.country}`;
    await pdfRenderer.renderComplexText(
      text,
      new StyleRange(0, text.length + 7, 'BOLD')
    );
    text = `Ref No: ${histologyNo}`;
    await pdfRenderer.renderComplexText(
      text,
      new StyleRange(0, 6, 'BOLD'),
      new StyleRange(6, text.length, '')
    );
    if (patient.mainMember.paymentType === 'Medical Aid') {
      text = `Medical Aid: ${titleCase(patient.mainMember.medicalAid.descr)}`;
      pdfRenderer.setPosition(new Position(pdfRenderer.getJustifyX(text), pdfRenderer.getCurrentPositionY()));
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 13, 'BOLD'),
        new StyleRange(13, text.length, '')
      );
    }
    else {
      text = `Payment Type: CASH`;
      pdfRenderer.setPosition(new Position(pdfRenderer.getJustifyX(text), pdfRenderer.getCurrentPositionY()));
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 14, 'BOLD'),
        new StyleRange(14, text.length, '').setColor('#800000')
      );
    }
    text = `ID Number: ${patient.idnumber}`;
    await pdfRenderer.renderComplexText(
      text,
      new StyleRange(0, 10, 'BOLD'),
      new StyleRange(10, text.length, '')
    );
    if (patient.mainMember.paymentType === 'Medical Aid') {
      text = `Medical Aid Number: ${patient.mainMember.medicalAidNumber}`;
      pdfRenderer.setPosition(new Position(pdfRenderer.getJustifyX(text), pdfRenderer.getCurrentPositionY()));
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 20, 'BOLD'),
        new StyleRange(20, text.length, '')
      );
    }
    await pdfRenderer.renderComplexText(
      `Received: ${format(
        parseISOString(specimenDate),
        'dd MMM yyyy'
      )} ${specimenTime}`,
      new StyleRange(0, 8, 'BOLD'),
      new StyleRange(8, 27, '')
    );
    if (patient.mainMember.paymentType === 'Medical Aid') {
      text = `Medical Aid Option: ${patient.mainMember.option}`;
      pdfRenderer.setPosition(new Position(pdfRenderer.getJustifyX(text), pdfRenderer.getCurrentPositionY()));
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 20, 'BOLD'),
        new StyleRange(20, text.length, '')
      );
    }
    await pdfRenderer.renderComplexText(
      `Issued: ${format(new Date(), 'dd MMM yyyy HH:mm')}`,
      new StyleRange(0, 7, 'BOLD'),
      new StyleRange(7, 27, '')
    );
    
    if (patient.mainMember.paymentType === 'Medical Aid') {
      text = `Dependant Code: ${patient.dependantCode}`;
      pdfRenderer.setPosition(new Position(pdfRenderer.getJustifyX(text), pdfRenderer.getCurrentPositionY()));
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 16, 'BOLD'),
        new StyleRange(16, text.length, '')
      );
    }
    await pdfRenderer.renderEmptyLines(1);
    var titleText = !doctors.title ? 'Doctor: Dr.' : `Client: ${doctors.title}`;
    text = `${titleText} ${doctors.name} ${doctors.surname}, ${doctors.address1}, ${doctors.address2}, ${doctors.city}, ${doctors.country}`;
    await pdfRenderer.renderComplexText(
      text,
      new StyleRange(0, 6, 'BOLD'),
      new StyleRange(6, text.length, 'ITALIC BOLD')
    );
    await pdfRenderer.renderEmptyLines(1);
    await pdfRenderer.renderCenteredText(title, 'BOLD');
    //await pdfRenderer.renderEmptyLines(1);
    await pdfRenderer.renderHeader('CLINICAL HISTORY', fontSize);
    var savableCompiledMatrices = await draftCompiler.compile(rClinical);
    if (specimenType !== 'CYT') {
      await pdfRenderer.renderHeader('MACROSCOPY', fontSize);
      savableCompiledMatrices = await draftCompiler.compile(rMacro);
    }
    await pdfRenderer.renderHeader('MICROSCOPY', fontSize);
    savableCompiledMatrices = await draftCompiler.compile(rMicro);
    await pdfRenderer.renderHeader('DIAGNOSIS', fontSize);
    savableCompiledMatrices = await draftCompiler.compile(rDiag);
    await pdfRenderer.renderEmptyLines(1);
    await pdfRenderer.renderHeader('ICD10 CODE', fontSize);
    await pdfRenderer.renderComplexText(
      report.icd10code,
      new StyleRange(0, report.icd10code.length, '')
    );
    if (optcomments) {
      await pdfRenderer.renderHeader('COMMENTS', fontSize);
      savableCompiledMatrices = await draftCompiler.compile(rComms);
    }
    if (preview) pdfRenderer.save('demo.pdf');
    else {
      await pdfRenderer.renderEmptyLines(1);
      text = 'Signed by: Dr. N.H. Hlatshwayo';
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 10, 'BOLD'),
        new StyleRange(10, text.length, '')
      );
      await pdfRenderer.renderEmptyLines(1);
      text = format(new Date(), 'dd-MMM-yyyy HH:mm');
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, text.length, '')
      );
      await pdfRenderer.renderEmptyLines(1);
      text = 'For consultation: 082 327 9039';
      await pdfRenderer.renderComplexText(
        text,
        new StyleRange(0, 17, 'BOLD'),
        new StyleRange(17, text.length, '')
      );
      blob = pdfRenderer.output('blob', histologyNo);
      blob.lastModifiedDate = new Date();
      blob.name = `Report.pdf`;
      uploadReportToServer(blob, report.id)
        .then((res) => {
          sendEmail({
            email: specimen.doctors.email,
            patientName: `${patient.name} ${patient.surname}`,
            histologyNo,
            specimenNature,
            copyDoctors,
            title: !doctors.title ? 'Dr.' : doctors.title,
            recepientName: `${specimen.doctors.name} ${specimen.doctors.surname}`,
            filename: res.data.url.substring(res.data.url.lastIndexOf('/') + 1),
          });
        })
        .then(() => {
          props.updateReport(
            {
              status: 'Final',
            },
            props.reportId
          );
        });
    }
  };

  return (
    <MDBContainer className="mt-2 mb-3">
      <MDBAnimation reveal type={animation}>
        <MDBRow>
          <MDBCol className="mt-3">
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Review</MDBCardTitle>
                <MDBInput
                  label="Type your title"
                  icon="clipboard"
                  group
                  type="text"
                  value={title}
                />
                <MuiThemeProvider theme={defaultTheme}>
                  <MDBCard>
                    <MDBCardTitle className="m-3">
                      Clinical History
                    </MDBCardTitle>
                    <MDBCardBody>
                      <MUIRichTextEditor
                        label="Type something here..."
                        inlineToolbar={true}
                        value={report.clinicalData}
                        disabled
                      />
                    </MDBCardBody>
                  </MDBCard>
                  <MDBCard>
                    <MDBCardTitle className="m-3">Macroscopic</MDBCardTitle>
                    <MDBCardBody>
                      <MUIRichTextEditor
                        label="Type something here..."
                        inlineToolbar={true}
                        value={report.macroscopic}
                        disabled
                      />
                    </MDBCardBody>
                  </MDBCard>
                  <MDBCard>
                    <MDBCardTitle className="m-3">Microscopic</MDBCardTitle>
                    <MDBCardBody>
                      <MUIRichTextEditor
                        label="Type something here..."
                        inlineToolbar={true}
                        value={report.microscopic}
                        disabled
                      />
                    </MDBCardBody>
                  </MDBCard>
                  <MDBCard>
                    <MDBCardTitle className="m-3">Diagnosis</MDBCardTitle>
                    <MDBCardBody>
                      <MUIRichTextEditor
                        label="Type something here..."
                        inlineToolbar={true}
                        value={report.diagnosis}
                        disabled
                      />
                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            required
                            name="icd10codeDescr"
                            label="ICD10 code"
                            icon="file-medical-alt"
                            type="text"
                            disabled
                            value={report.icd10code}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                  {optcomments && (
                    <MDBCard>
                      <MDBCardTitle className="m-3">Comments</MDBCardTitle>
                      <MDBCardBody>
                        <MUIRichTextEditor
                          label="Type something here..."
                          inlineToolbar={true}
                          value={report.comments}
                        />
                      </MDBCardBody>
                    </MDBCard>
                  )}
                </MuiThemeProvider>
                <MDBBtn color="black" variant="contained" onClick={prevStep}>
                  Back
                </MDBBtn>
                <MDBBtn
                  color="black"
                  variant="contained"
                  onClick={previewReport}
                >
                  Preview
                </MDBBtn>
                <MDBBtn
                  color="orange"
                  variant="contained"
                  onClick={handleOpen}
                  disabled={report.status === 'Final' || report.status === 'addended'}
                >
                  Finalise
                </MDBBtn>
                <MDBBtn
                  color="black"
                  variant="contained"
                  onClick={setBaddendun}
                  disabled={report.status?.includes('draft')}
                >
                  Add Addendum
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBAnimation>
      {open && (
        <ConfirmDialog
          modalTitle="Finalise Report"
          onClose={handleClose}
          open={open}
          fn={FinaliseReport}
          message={'Are you sure you want to finalise report?'}
        />
      )}
      {bAddendun && (
        <FormAddendum
          onClose={handleAddendumClose}
          onChange={onChange}
          report={report}
          addendum={addendum}
          animation={animation}
          updateReport={updateReport}
          reportId={props.reportId}
          upload={AddAddendumReport}
          preview={handleAddendumPreview}
        />
      )}
      {sopen && (
        <Success
          clearMsg={handleSClose}
          show={sopen}
          message={'Addendum sent to medical practitioner sent successfully!'}
        />
      )}
    </MDBContainer>
  );
}
export default FormFinal;
