import React, { useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBBtn,
  MDBAnimation,
  MDBAlert
} from 'mdbreact';
import { convertToRaw } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

function FormAddendum(props) {
    const {
      onChange,
      report,
      addendum,
      animation,
      updateReport
    } = props;
    const defaultTheme = createTheme();
  
    Object.assign(defaultTheme, {
      overrides: {
        MUIRichTextEditor: {
          root: {
            marginTop: 5,
            minHeight: 150,
          },
          editor: {
            borderBottom: '1px solid gray',
          },
        },
      },
    });
  
    const [pError, setPerror] = useState(false);
  
    const onClose = () => setPerror(false);
  
    const saveClick = () => {
        if (
          Object.values(JSON.parse(addendum))[0].filter(function (e) {
            return e.text.trim() !== '';
          }).length > 0
        ) {
          updateReport(
            {
                addendum,
            },
            props.reportId
          );
          props.upload();
        } else {
          setPerror(true);
          return;
        }
     // else nextStep();
    };
  
    return (
      <MDBContainer className="mt-2 mb-3">
        <MDBAnimation reveal type={animation}>
          <MDBRow>
            <MDBCol className="mt-3">
              <MDBCard>
                <MDBCardBody>
                  <MDBCardTitle>Addendum</MDBCardTitle>
                  {pError && (
                    <MDBAlert color="danger" dismiss onClosed={onClose}>
                      <strong>Error!</strong> Please enter some text.
                    </MDBAlert>
                  )}
                  <MuiThemeProvider theme={defaultTheme}>
                      <MUIRichTextEditor
                        label="Type something here..."
                        onChange={(state) =>
                          onChange(
                            'addendum',
                            JSON.stringify(
                              convertToRaw(state.getCurrentContent())
                            )
                          )
                        }
                        inlineToolbar={true}
                        value={report.addendum}
                        controls={[
                          'bold',
                          'italic',
                          'underline',
                          'media',
                          'upload-image',
                        ]}
                      />
                  </MuiThemeProvider>
                  <MDBBtn color="orange" variant="contained" onClick={props.preview} disabled={!report.addendum}>
                    Preview
                  </MDBBtn>
                  <MDBBtn color="black" variant="contained" onClick={props.onClose}>
                    Cancel
                  </MDBBtn>
                  <MDBBtn color="orange" variant="contained" onClick={saveClick}>
                    Save
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBAnimation>
      </MDBContainer>
    );
  }
  export default FormAddendum;
  