import { takeEvery, all } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import {
  logoutSaga,
  checkAuthTimeoutSaga,
  authUserSaga,
  authCheckStateSaga,
  authConfirmEmailSaga,
  authforgotPasswordSaga,
  authResetPasswordSaga,
  authResendConfirmationEmailSaga,
} from './auth';

import {
  fetchPatientSaga,
  fetchPatientsSaga,
  fetchMedicalAidSaga,
  addMainMemberSaga,
  addPatientSaga,
  editPatientSaga,
  editMainMemberSaga,
} from './patients';

import {
  fetchDoctorSaga,
  fetchDoctorsSaga,
  addDoctorSaga,
  editDoctorSaga,
} from './doctor';

import {
  fetchPatientSpecimensSaga,
  addSpecimenSaga,
  editSpecimenSaga,
  fetchSpecimensSaga,
  deleteSpecimenSaga,
} from './specimen';

import {
  fetchCytologyTextSaga,
  fetchICD10Saga,
  fetchReportsSaga,
  fetchReportSaga,
  addReportSaga,
  updateReportSaga,
  sendReportSaga,
} from './report';

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga),
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
    takeEvery(actionTypes.AUTH_USER, authUserSaga),
    takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga),
    takeEvery(actionTypes.CONFIRM_EMAIL_START, authConfirmEmailSaga),
    takeEvery(actionTypes.FORGOT_PASSWORD_START, authforgotPasswordSaga),
    takeEvery(actionTypes.RESET_PASSWORD_START, authResetPasswordSaga),
    takeEvery(
      actionTypes.RESEND_CONFIRM_EMAIL_START,
      authResendConfirmationEmailSaga
    ),
  ]);
}

export function* watchPatient() {
  //yield takeLatest(actionTypes.ADD_PATIENT_START, addPatientSaga);
  yield all([
    takeEvery(actionTypes.FETCH_PATIENT_START, fetchPatientSaga),
    takeEvery(actionTypes.FETCH_PATIENTS_START, fetchPatientsSaga),
    takeEvery(actionTypes.ADD_PATIENT_START, addPatientSaga),
    takeEvery(actionTypes.ADD_MAINMEMBER_START, addMainMemberSaga),
    takeEvery(actionTypes.EDIT_PATIENT_START, editPatientSaga),
    takeEvery(actionTypes.EDIT_MAINMEMBER_START, editMainMemberSaga),
    takeEvery(actionTypes.FETCH_MEDICALAIDS_START, fetchMedicalAidSaga)
  ]);
}

export function* watchDoctor() {
  //yield takeLatest(actionTypes.ADD_PATIENT_START, addPatientSaga);
  yield all([
    takeEvery(actionTypes.FETCH_DOCTOR_START, fetchDoctorSaga),
    takeEvery(actionTypes.ADD_DOCTOR_START, addDoctorSaga),
    takeEvery(actionTypes.EDIT_DOCTOR_START, editDoctorSaga),
    takeEvery(actionTypes.FETCH_DOCTORS_START, fetchDoctorsSaga),
  ]);
}

export function* watchReport() {
  yield all([
    takeEvery(actionTypes.FETCH_ICD10CODES_START, fetchICD10Saga),
    takeEvery(actionTypes.FETCH_CYTOLOGYTEXT_START, fetchCytologyTextSaga),
    takeEvery(actionTypes.ADD_REPORT_START, addReportSaga),
    takeEvery(actionTypes.UPDATE_REPORT_START, updateReportSaga),
    takeEvery(actionTypes.FETCH_REPORTS_START, fetchReportsSaga),
    takeEvery(actionTypes.FETCH_REPORT_START, fetchReportSaga),
    takeEvery(actionTypes.SEND_REPORT_START, sendReportSaga),
  ]);
}

export function* watchSpecimen() {
  yield all([
    takeEvery(actionTypes.ADD_SPECIMEN_START, addSpecimenSaga),
    takeEvery(actionTypes.UPDATE_SPECIMEN_START, editSpecimenSaga),
    takeEvery(actionTypes.DELETE_SPECIMEN_START, deleteSpecimenSaga),
    takeEvery(actionTypes.FETCH_SPECIMENS_START, fetchSpecimensSaga),
    takeEvery(
      actionTypes.FETCH_PATIENT_SPECIMENS_START,
      fetchPatientSpecimensSaga
    ),
  ]);
}
