import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '../text-field';

export default function Filter({ render, placeholder }) {
  const [query, setQuery] = useState('');
  return (
    <div>
      <TextField
        type="text"
        onChange={(val) => setQuery(val)}
        value={query}
        placeholder={placeholder}
      />
      {render(query)}
    </div>
  );
}

Filter.propTypes = {
  placeholder: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};
