import React, { useRef, useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBBtn,
  MDBAnimation,
  MDBAlert,
} from 'mdbreact';
import BackupIcon from '@material-ui/icons/Backup';
import { convertToRaw } from 'draft-js';
import UploadImagePopover from '../../Reports/UploadImagePopover';
import { uploadImage } from '../../../shared/utility';
import MUIRichTextEditor from 'mui-rte';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

function FormMicro(props) {
  const {
    onChange,
    prevStep,
    nextStep,
    report,
    animation,
    specimenID,
    microscopic,
  } = props;
  const refmicroscopic = useRef(null);
  const [anchor, setAnchor] = useState(null);

  const handleFileUpload = (file, reportId, caption) => {
    refmicroscopic.current.insertAtomicBlockAsync(
      'IMAGE',
      uploadImage(file, reportId, caption),
      'Uploading now...'
    );
  };
  const defaultTheme = createTheme();

  const [pError, setPerror] = useState(false);

  const onClose = () => setPerror(false);

  const nextClick = () => {
    if (
      Object.values(JSON.parse(microscopic))[0].filter(function (e) {
        return e.text.trim() !== '';
      }).length > 0
    ) {
      props.updateReport(
        {
          microscopic,
        },
        props.reportId
      );
      nextStep();
    } else {
      setPerror(true);
      return;
    }
  };

  Object.assign(defaultTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          marginTop: 5,
          minHeight: 150,
        },
        editor: {
          borderBottom: '1px solid gray',
        },
      },
    },
  });

  return (
    <MDBContainer className="mt-2 mb-3">
      <MDBAnimation reveal type={animation}>
        <MDBRow>
          <MDBCol className="mt-3">
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle>Microscopic</MDBCardTitle>
                <MuiThemeProvider theme={defaultTheme}>
                  <UploadImagePopover
                    anchor={anchor}
                    onSubmit={(data, insert) => {
                      if (insert && data.file) {
                        handleFileUpload(data.file, specimenID, data.caption);
                      }
                      setAnchor(null);
                    }}
                  />
                  {pError && (
                    <MDBAlert color="danger" dismiss onClosed={onClose}>
                      <strong>Error!</strong> Please enter some text.
                    </MDBAlert>
                  )}
                  <MUIRichTextEditor
                    label="Drop a file inside the editor or press the last icon in the toolbar to simulate uploading an image...."
                    ref={refmicroscopic}
                    value={report.microscopic}
                    controls={[
                      'bold',
                      'italic',
                      'underline',
                      'media',
                      'upload-image',
                    ]}
                    customControls={[
                      {
                        name: 'upload-image',
                        icon: <BackupIcon />,
                        type: 'callback',
                        onClick: (_editorState, _name, anchor) => {
                          setAnchor(anchor);
                        },
                      },
                    ]}
                    draftEditorProps={{
                      handleDroppedFiles: (_selectionState, files) => {
                        if (files.length && files[0].name !== undefined) {
                          handleFileUpload(files[0], specimenID);
                          return 'handled';
                        }
                        return 'not-handled';
                      },
                    }}
                    onChange={(state) =>
                      onChange(
                        'microscopic',
                        JSON.stringify(convertToRaw(state.getCurrentContent()))
                      )
                    }
                  />
                </MuiThemeProvider>

                <MDBBtn color="black" variant="contained" onClick={prevStep}>
                  Back
                </MDBBtn>

                <MDBBtn color="orange" variant="contained" onClick={nextClick}>
                  Continue
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBAnimation>
    </MDBContainer>
  );
}
export default FormMicro;
