import jsPDF from 'jspdf';
import Position from './Position';
import { TextRenderer } from './renderers/TextRenderer';
import { HeaderRenderer } from './renderers/HeaderRenderer';
import { ListItemRenderer } from './renderers/ListItemRenderer';
import { AtomicRenderer } from './renderers/AtomicRenderer';
import { QuoteRenderer } from './renderers/QuoteRenderer';
import { CodeRenderer } from './renderers/CodeRenderer';
import StyleRange from './Range';

const DEFUALT_POSITION = new Position(0, 0);

export default class PDFRenderer {
  static DEFAULT_ORIENTATION = 'p';
  static DEFAULT_UNIT = 'mm';
  static DEFAULT_MARGINS = { left: 10, top: 10, right: 10, bottom: 10 };
  static DEFAULT_PAGE_SIZE = [210, 297];

  #renderingState;
  #renders;
  #font;
  #lastPosition;

  constructor(
    margins = PDFRenderer.DEFAULT_MARGINS,
    orientation = PDFRenderer.DEFAULT_ORIENTATION,
    unit = PDFRenderer.DEFAULT_UNIT,
    pageSize = PDFRenderer.DEFAULT_PAGE_SIZE
  ) {
    this.#renderingState = new RenderingState(
      orientation,
      unit,
      pageSize,
      margins,
      new Position(0, 0)
    );
    let listItemRenderer = new ListItemRenderer();
    this.#renders = {
      unstyled: new TextRenderer(),
      header_two: new HeaderRenderer(),
      unordered_list_item: listItemRenderer,
      ordered_list_item: listItemRenderer,
      atomic: new AtomicRenderer(),
      blockquote: new QuoteRenderer(),
      code_block: new CodeRenderer(),
    };
    this.setFont('helvetica');
    this.#lastPosition = new Position(0, 0);
  }

  requestBoundary(position, width = -1) {
    return this.#renderingState.requestBoundary(position, width);
  }

  getImageSize(base64) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const imgWidth = img.naturalWidth;
        const imgHeight = img.naturalHeight;

        resolve({ width: imgWidth, height: imgHeight });
      };
      img.src = base64;
    });
  }

  requestCenterBoundary(text) {
    return this.#renderingState.requestCenterBoundary(text);
  }

  setFont(name) {
    this.#renderingState.setFont(name);
  }

  setFontSize(size) {
    this.#renderingState.setFontSize(size);
  }

  getCurrentPositionY = () =>
    this.#renderingState.getPosition().getY() - this.#renderingState.getLineHeight();

  
    getJustifyX = (text) =>
    this.#renderingState.calculateJustifyTextX(text);

  addCustomFonts(fonts) {
    return new Promise(async (resolve, reject) => {
      for (var pos = 0; pos < fonts.length; pos++) {
        var font = fonts[pos];
        this.#renderingState
          .getPdfDocument()
          .addFileToVFS(font.fileName, font.fBase64);
        this.#renderingState
          .getPdfDocument()
          .addFont(font.fileName, font.name, font.style);
      }
      resolve(this);
    });
  }

  moveUp(height) {
    if (height === undefined)
      height =
        this.#renderingState.getPosition().getY() - this.#lastPosition.getY();

    this.#renderingState.movePositionY(-height);
  }

  fileToBase64(filepath, filename) {
    return new Promise((resolve, reject) => {
      //console.log(filepath, filename);
      var file = new File([filename], filepath);
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event) {
        resolve(event.target.result);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  }

  async renderMatrix(drawingMatrix, prevDrawingMatrix, boundary) {
    var renderer = this.#renders[drawingMatrix.type.replaceAll('-', '_')];
    if (renderer) {
      var position = this.#renderingState.getPosition();
      this.#lastPosition = position.copy();
      return await renderer.render(
        drawingMatrix,
        boundary,
        prevDrawingMatrix,
        this.#renderingState
      );
    }
    return undefined;
  }
  createSimpleTextMatrix(text, ...styles) {
    styles = [
      {
        type: 'TEXT',
        range: new StyleRange(0, text.lenght, ...styles),
        data: undefined,
      },
    ];

    var matrix = {
      type: 'unstyled',
      value: text,
      hasPadding: false,
      columns: styles,
    };

    return matrix;
  }

  async renderSimpleText(text) {
    return this.renderMatrix(
      this.createSimpleTextMatrix(text),
      undefined,
      this.requestBoundary(new Position(0, 0))
    );
  }

  createComplexMatrix(text, ...Styles) {
    var r = Styles.map((s) => {
      return {
        type: 'TEXT',
        range: s,
        data: undefined,
      };
    });

    var matrix = {
      type: 'unstyled',
      value: text,
      hasPadding: false,
      columns: r,
    };
    return matrix;
  }

  async renderComplexText(text, ...styles) {
    return this.renderMatrix(
      this.createComplexMatrix(text, ...styles),
      undefined,
      this.requestBoundary(new Position(0, 0))
    );
  }

  async renderCenteredText(text, ...styles) {
    var centerBoundary = this.requestCenterBoundary(text);
    await this.renderMatrix(
      this.createSimpleTextMatrix(text, ...styles),
      undefined,
      centerBoundary
    );
  }

  async renderHeader(text, size = 22) {
    var styles = [
      {
        type: 'TEXT',
        range: new StyleRange(0, text.lenght, 'BOLD', 'UNDERLINE'),
        data: undefined,
      },
    ];

    var matrix = {
      type: 'header-two',
      value: text,
      hasPadding: true,
      columns: styles,
      fontSize: size,
    };
    await this.renderMatrix(
      matrix,
      undefined,
      this.requestBoundary(new Position(0, 0))
    );
  }

  async renderEmptyLines(lines = 1) {
    for (var pos = 0; pos < lines; pos++) {
      var styles = [
        {
          type: 'TEXT',
          range: new StyleRange(0, 0),
          data: undefined,
        },
      ];

      var matrix = {
        type: 'unstyled',
        value: '',
        hasPadding: false,
        columns: styles,
      };

      await this.renderMatrix(
        matrix,
        undefined,
        this.requestBoundary(new Position(0, 0))
      );
    }
  }

  setPosition(position) {
    this.#renderingState.setPosition(position, 0);
  }
  async renderHeaderImage(imageBase64, image = { width: 940, height: 470 }) {
    const u = await this.getImageSize(imageBase64);
    // console.log('image data', u);
    // console.log('image width in mm', u.width * 0.2645833333);
    // console.log('image height in mm', u.height * 0.2645833333);
    var docSize = this.#renderingState.getDocumentSize();
    var width = docSize.width;
    var height = (width / (u.width * 0.2645833333)) * u.height * 0.2645833333;

    this.#renderingState
      .getPdfDocument()
      .addImage(imageBase64, 'PNG', 0, 0, width, height);
    this.#renderingState.movePositionY(
      height,
      new Boundary(width, new Position(0, 0))
    );

    return { width, height };
  }

  save(path) {
    this.#renderingState.save(path);
  }

  output(name, refno) {
    var numPages = this.#renderingState.getPageNumber();
    for (var pos = 1; pos <= numPages; pos++) {
      this.#renderingState.getPdfDocument().setPage(pos);
      var text = refno + '-' + pos + ' / ' + numPages;
      var textDimesions = this.#renderingState.getTextDimensions(text);
      var bottomOffset = Math.min(textDimesions.height + 2, 10);
      var absX =
        this.#renderingState.getDocumentSize().width / 2 -
        textDimesions.width / 2;
      var absY = this.#renderingState.getDocumentSize().height - bottomOffset;
      this.#renderingState.getPdfDocument().text(text, absX, absY);
    }
    console.log(
      `this.getPdfDocument().output(path)`,
      this.#renderingState.getPdfDocument().output(name)
    );
    //return this.#pdfDocument.output(path);
    return this.#renderingState.getPdfDocument().output(name);
  }
}

export class RenderingState {
  static DEFUALT_LINE_HEIGHT_CHAR = '|';
  #pdfDocument;
  #position;
  #margins;
  #lineHeight;
  #savedStyles;
  #absPageRight;
  #absPageBottom;
  #style;
  #font;
  #tabSize;
  #pages;
  constructor(
    orientation,
    unit,
    pageSize,
    margins,
    startPosition = DEFUALT_POSITION
  ) {
    this.#pdfDocument = new jsPDF(orientation, unit, pageSize);
    this.#position = startPosition;
    this.#margins = margins;
    this.#savedStyles = [];
    this.#tabSize = 11;

    var style = {
      font: {
        type: 'helvetica',
        style: 'normal',
        size: 11,
      },
      textColor: '#000000',
      fillColor: '#000000',
    };
    this.#pages = [{ lastPosition: new Position(0, 0) }];
    this.setStyle(style);
    this.setFontSize(9);
    //console.log(`this.fontSize`, this.fontSize);
    pageSize = this.#pdfDocument.internal.pageSize;
    this.#absPageRight = Math.max(0, pageSize.getWidth() - margins.right);
    this.#absPageBottom = Math.max(0, pageSize.getHeight() - margins.bottom);
  }

  requestCenterBoundary(text) {
    var width = this.#absPageRight - this.#margins.left;
    var textDimensions = this.getTextDimensions(text);
    var posX = width / 2 - textDimensions.width / 2;

    return new Boundary(2 * textDimensions.width, new Position(posX, 0));
  }

  requestBoundary(position, width = -1) {
    if (width < 0) {
      width = this.getPageWidth();
    }

    var boundary = new Boundary(width, position);
    var absBoundaryRight = this.getAbsBoundaryRight(boundary);

    var absPageRight = this.getAbsPageRight();
    if (absBoundaryRight > absPageRight) {
      var newWidth = Math.max(
        0,
        absPageRight - this.getAbsBoundaryLeft(boundary)
      );
      boundary = new Boundary(newWidth, position);
    }
    //console.log(boundary);
    return boundary;
  }

  save(path) {
    var numPages = this.getPageNumber();
    for (var pos = 1; pos <= numPages; pos++) {
      this.#pdfDocument.setPage(pos);
      var text = pos + ' / ' + numPages;
      var textDimesions = this.getTextDimensions(text);
      var bottomOffset = Math.min(
        textDimesions.height + 2,
        this.#margins.bottom
      );
      var absX = this.getDocumentSize().width / 2 - textDimesions.width / 2;
      var absY = this.getDocumentSize().height - bottomOffset;
      this.#pdfDocument.text(text, absX, absY);
    }

    this.#pdfDocument.save(path);
  }

  output(path, refNo) {
    var numPages = this.getPageNumber();
    for (var pos = 1; pos <= numPages; pos++) {
      this.#pdfDocument.setPage(pos);
      var text = refNo + '-' + pos + ' / ' + numPages;
      var textDimesions = this.getTextDimensions(text);
      var bottomOffset = Math.min(
        textDimesions.height + 2,
        this.#margins.bottom
      );
      var absX = this.getDocumentSize().width / 2 - textDimesions.width / 2;
      var absY = this.getDocumentSize().height - bottomOffset;
      this.#pdfDocument.text(text, absX, absY);
    }
    console.log(
      `this.getPdfDocument().output(path)`,
      this.#pdfDocument.output(path)
    );
    return this.#pdfDocument.output(path);
    //return this.#pdfDocument.output(path);
  }

  getDocumentSize() {
    var pageSize = this.#pdfDocument.internal.pageSize;
    return { width: pageSize.getWidth(), height: pageSize.getHeight() };
  }

  setPosition(position, pageNumber) {
    this.#position = position.copy();
    this.#pdfDocument.setPage(pageNumber);
  }
  getPosition() {
    return this.#position;
  }

  calculateJustifyTextX(text) {
    const textDimensions = this.getTextDimensions(text);
    var width = this.#absPageRight - this.#margins.left;
    const posX = Math.max(0, width - textDimensions.width);
    return posX - this.#pdfDocument.getFontSize();
  }

  getFont() {
    return this.#font;
  }
  setFont(font, style = 'normal') {
    this.#font = font;
    this.#pdfDocument.setFont(font, style);
    //console.log("++fonts+++", this.#pdfDocument.getFontList())
  }

  setFontSize(size) {
    this.#pdfDocument.setFontSize(size);
    var textDimensions = this.#pdfDocument.getTextDimensions(
      RenderingState.DEFUALT_LINE_HEIGHT_CHAR
    );
    if (size === 9) this.#lineHeight = textDimensions.h + 0.5;
    else this.#lineHeight = textDimensions.h + 1;
  }

  getFontSize() {
    return this.#pdfDocument.getFontSize();
  }
  getTabSize() {
    return this.#tabSize;
  }

  getPdfDocument() {
    return this.#pdfDocument;
  }
  getPageWidth() {
    return Math.max(0, this.getAbsPageRight() - this.#margins.left);
  }
  getPageHeight() {
    return Math.max(0, this.getAbsPageBottom() - this.#margins.top);
  }

  getTextDimensions(text) {
    var dim = this.#pdfDocument.getTextDimensions(text);
    return { width: dim.w, height: dim.h };
  }

  getAbsBoundaryLeft(boundary) {
    return this.#margins.left + boundary.getPosition().getX();
  }
  getAbsBoundaryRight(boundary) {
    return this.getAbsBoundaryLeft(boundary) + boundary.getWidth();
  }

  getAbsPositionX(boundary) {
    return (
      this.#margins.left + boundary.getPosition().getX() + this.#position.getX()
    );
  }
  getAbsPositionY(boundary) {
    return (
      this.#margins.top + boundary.getPosition().getY() + this.#position.getY()
    );
  }

  getLineHeight() {
    return this.#lineHeight;
  }

  getAbsPageRight() {
    return this.#absPageRight;
  }
  getAbsPageBottom() {
    return this.#absPageBottom;
  }

  movePositionX(x, boundary, reset = true) {
    if (
      this.getAbsPositionX(boundary) + x >
      this.getAbsBoundaryRight(boundary)
    ) {
      this.#position.setX(reset ? 0 : x);
    } else {
      this.#position.incrementX(x);
    }
  }

  getPageNumber() {
    return this.#pdfDocument.internal.getNumberOfPages();
  }
  getCurrentPage() {
    let pageInfo = this.#pdfDocument.internal.getCurrentPageInfo();
    return pageInfo.pageNumber;
  }

  movePositionY(y, boundary, reset = true) {
    if (this.getAbsPositionY(boundary) + y > this.#absPageBottom) {
      this.#pages.push({ lastPosition: this.#position.copy() });
      let pageInfo = this.#pdfDocument.internal.getCurrentPageInfo();
      if (pageInfo.pageNumber < this.getPageNumber()) {
        // console.log(
        //   'setting page',
        //   y,
        //   pageInfo.pageNumber,
        //   this.getPageNumber()
        // );
        this.#pdfDocument.setPage(pageInfo.pageNumber + 1);
      } else {
        // console.log(
        //   'creating page',
        //   y,
        //   pageInfo.pageNumber,
        //   this.getPageNumber()
        // );
        this.#pdfDocument.addPage();
      }
      this.#position.setY(reset ? 0 : y);
      return false;
    } else if (this.getAbsPositionY(boundary) + y < this.#margins.top) {
      var diff = this.getAbsPositionY(boundary) + y;
      var prevPage = this.#pages.pop();
      // var currentPage = this.#pages.length;
      if (prevPage) {
        this.#position = prevPage.lastPosition.copy();
        this.#position.incrementY(diff);
        //this.#pdfDocument.setPage(0);
      }
      return false;
    } else {
      this.#position.incrementY(y);
      return true;
    }
  }

  pushStyle() {
    var styleCopy = Object.assign({}, this.#style);
    styleCopy.font = Object.assign({}, this.#style.font);

    this.#savedStyles.push(styleCopy);
  }

  popStyle() {
    var style = this.#savedStyles.pop();
    if (style !== undefined) {
      this.setStyle(style);
    }
  }

  setStyle(style) {
    this.#style = style;
    var font = this.#style.font;
    this.#pdfDocument.setFont(font.type, font.style);
    this.#pdfDocument.setFontSize(font.size);
    this.#pdfDocument.setTextColor(style.textColor);
    this.#pdfDocument.setFillColor(style.fillColor);
  }
}

export class Boundary {
  #width;
  #position;

  constructor(width = 0, position = DEFUALT_POSITION) {
    this.#width = width;
    this.#position = position;
  }

  getWidth() {
    return this.#width;
  }
  setWidth(width) {
    this.#width = width;
  }
  getPosition() {
    return this.#position;
  }
}
