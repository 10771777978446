import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdbreact';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Spinner from '../Spinner';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function ShareModal({
  open,
  handleClose,
  handleSubmit,
  loading,
  emails,
  setEmails,
}) {
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form
              id="shareFrm"
              onSubmit={handleSubmit}
              className="needs-validation"
              noValidate
            >
              <div className="text-center">
                <h3 className="dark-grey-text mb-5">
                  <strong>Share Report</strong>
                </h3>
              </div>
              <MDBRow>
                <MDBCol md="12">
                  <MDBInput
                    icon="envelope"
                    value={emails}
                    name="name"
                    onChange={(e) => setEmails(e.target.value)}
                    type="text"
                    id="txtEmails"
                    label="Email"
                    validate
                    required
                  >
                    <div className="invalid-feedback">
                      Please enter email address.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                  </MDBInput>
                </MDBCol>
              </MDBRow>
              <div className="text-center mb-3">
                {loading ? (
                  <Spinner />
                ) : (
                  <MDBBtn color="warning" type="submit">
                    Send
                  </MDBBtn>
                )}
                <MDBBtn color="black" type="button" onClick={handleClose}>
                  Cancel
                </MDBBtn>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ShareModal;
