import React, { useReducer, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import PatientInfo from './PatientInfo/';
import AddSpecimen from './AddSpecimen/';
import Spinner from '../Spinner';
import { isFormValid, contactNumberPresent } from '../../shared/utility';
import AlertModal from '../AlertModal';
import { format } from 'date-fns';

const initialState = {
  id: '',
  provIndex: -1,
  name: '',
  surname: '',
  initials: '',
  title: '',
  identificationType: 'idnumber',
  idnumber: '',
  passportnumber: '',
  birthday: format(new Date('2000/01/01'), 'dd-MM-yyyy'),
  dependantCode: '',
  gender: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  code: '',
  province: '',
  phone: '',
  cellphone: '',
  mainMember: {
    name: '',
    surname: '',
    initials: '',
    title: '',
    identificationType: 'idnumber',
    idnumber: '',
    passportnumber: '',
    phone: '',
    cellphone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    code: '',
    province: '',
    paymentType: 'Cash',
    mmIndex: -1,
    medicalAid: '',
    medicalAidNumber: '',
    option: '',
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return initialState;
    }
    case 'field': {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case 'date': {
      return {
        ...state,
        birthday: action.value,
      };
    }
    case 'mfield': {
      const category = { ...state['mainMember'] };
      category[action.field] = action.value;
      return {
        ...state,
        mainMember: category,
      };
    }
    default:
  }
};

function Patient(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [message, setMessage] = React.useState('');
  const [stitle, setStitle] = React.useState('');
  const [alertType, setAlertType] = React.useState('');
  const [step, setStep] = useState(1);
  const [animation, setAnimaion] = useState('zoomInLeft');
  const {
    id,
    initials,
    title,
    name,
    surname,
    email,
    identificationType,
    idnumber,
    passportnumber,
    birthday,
    gender,
    dependantCode,
    address1,
    address2,
    city,
    code,
    province,
    phone,
    cellphone,
    mainMember,
  } = state;

  const nextStep = () => {
    setAnimaion('zoomInLeft');
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setAnimaion('zoomInRight');
    setStep(step - 1);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.patientId) {
      props.onClearPatient();
      setOpen(false);
      history.push('/patients');
    } else if (props.error) {
      props.onClearError();
      setOpen(false);
    }
  };

  const handleBirthday = (e) => {
    dispatch({
      type: 'date',
      value: e,
    });
  };

  const handleTag = ({ target }) => {
    const { value } = target;
    if (value) {
      const index = props.medicalAids.findIndex((x) => x.descr === value);
      if (index !== -1) {
        dispatch({
          type: 'mfield',
          value: props.medicalAids[index].id,
          field: 'medicalAid',
        });
        dispatch({
          type: 'mfield',
          value: index,
          field: 'mmIndex',
        });
      }
    } else {
      dispatch({
        type: 'mfield',
        value: '',
        field: 'medicalAid',
      });
      dispatch({
        type: 'mfield',
        value: -1,
        field: 'mmIndex',
      });
    }
  };

  const handleCopy = () => {
    dispatch({
      type: 'mfield',
      field: 'title',
      value: title,
    });
    dispatch({
      type: 'mfield',
      field: 'name',
      value: name,
    });
    dispatch({
      type: 'mfield',
      field: 'surname',
      value: surname,
    });
    dispatch({
      type: 'mfield',
      field: 'initials',
      value: initials,
    });
    dispatch({
      type: 'mfield',
      field: 'idnumber',
      value: idnumber,
    });
    dispatch({
      type: 'mfield',
      field: 'identificationType',
      value: identificationType,
    });
    dispatch({
      type: 'mfield',
      field: 'passportnumber',
      value: passportnumber,
    });
    dispatch({
      type: 'mfield',
      field: 'phone',
      value: phone,
    });
    dispatch({
      type: 'mfield',
      field: 'cellphone',
      value: cellphone,
    });
    dispatch({
      type: 'mfield',
      field: 'email',
      value: email,
    });
    dispatch({
      type: 'mfield',
      field: 'address1',
      value: address1,
    });
    dispatch({
      type: 'mfield',
      field: 'address2',
      value: address2,
    });
    dispatch({
      type: 'mfield',
      field: 'city',
      value: city,
    });
    dispatch({
      type: 'mfield',
      field: 'code',
      value: code,
    });
    dispatch({
      type: 'mfield',
      field: 'province',
      value: province,
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    contactNumberPresent('txtTelephone', 'txtCellphone');
    contactNumberPresent('txtMTelephone', 'txtMCellphone');
    event.target.className += ' was-validated';
    if (!isFormValid(event)) return;
    const pat = {
      id,
      initials,
      name,
      surname,
      email,
      idnumber,
      address: `${address1}, ${address2}, ${city}, ${code}, ${province}`,
      address1,
      address2,
      city,
      code,
      province,
      phone,
      cellphone,
      birthday,
      gender,
      dependantCode,
      mainMember,
    };
    props.add ? nextStep() : props.onEditPatient(pat);
    event.target.reset();
  };

  const wizard = () => {
    switch (step) {
      case 1:
        return (
          <PatientInfo
            medicalAids={props.medicalAids}
            state={state}
            dispatch={dispatch}
            //mmIndex={mmIndex}
            loading={props.loading}
            add={true}
            animation={animation}
            setAnimaion={setAnimaion}
            onAddPatient={props.onAddPatient}
            onClearPatient={props.onClearPatient}
            handleBirthday={handleBirthday}
            handleCopy={handleCopy}
            handleTag={handleTag}
            handleCancel={() => history.push('/patients')}
            submitHandler={submitHandler}
          />
        );
      case 2:
        return (
          <AddSpecimen
            Doctors={props.doctors}
            patient={state}
            loading={props.sloading}
            error={props.serror}
            onAddPatient={props.onAddPatient}
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      //
      default:
        return null;
    }
  };

  useEffect(() => {
    props.onFetchMedicalAids();
    props.onFetchDoctors();
  }, []);

  if ((props.error || props.serror) && !open) {
    handleOpen();
    setStitle('Error');
    setMessage(props.error);
    setAlertType('error');
  }

  if (!!props.specimen && !open) {
    handleOpen();
    setStitle('Success');
    setMessage('Changes saved successfully!');
    setAlertType('success');
  }

  return props.dloading ? (
    <Spinner />
  ) : props.add ? (
    <>
      {wizard()}
      {/* <PatientInfo
        loading={props.loading}
        add={true}
        animation={animation}
        setAnimaion={setAnimaion}
        onAddPatient={props.onAddPatient}
        onClearPatient={props.onClearPatient}
        handleBirthday={handleBirthday}
        handleCopy={handleCopy}
        handleTag={handleTag}
        handleCancel={() => history.push('/patients')}
        submitHandler={submitHandler}
        state={state}
        dispatch={dispatch}
        nextStep={nextStep}
      /> */}
      <AlertModal
        alertType={alertType}
        open={open}
        onClose={handleClose}
        title={stitle}
        message={message}
      />
    </>
  ) : props.edit ? (
    <>
      <PatientInfo
        loading={props.loading}
        edit={true}
        animation={animation}
        mainMember={mainMember}
        setAnimaion={setAnimaion}
        handleCopy={handleCopy}
        handleBirthday={handleBirthday}
        onFetchPatient={props.onFetchPatient}
        onEditPatient={props.onEditPatient}
        onClearPatient={props.onClearPatient}
        handleTag={handleTag}
        patient={props.Patient}
        submitHandler={submitHandler}
        handleCancel={() => history.push('/patients')}
        state={state}
        dispatch={dispatch}
        patientId={props.patientId}
      />
      <AlertModal
        alertType={alertType}
        open={open}
        onClose={handleClose}
        title={stitle}
        message="Patient successfully updated!"
      />
    </>
  ) : (
    <PatientInfo
      animation={animation}
      setAnimaion={setAnimaion}
      patient={props.patient}
      state={state}
      onClearPatient={props.onClearPatient}
      dispatch={dispatch}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    medicalAids: state.patient.medicalAids,
    doctors: state.doctor.doctors,
    mainMember: state.patient.mainmember,
    Patient: state.patient.patient,
    specimen: state.specimen.specimen,
    loading: state.patient.loading,
    dloading: state.doctor.loading,
    sloading: state.patient.sloading,
    error: state.patient.error,
    serror: state.specimen.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchMedicalAids: () => dispatch(actions.fetchMedicalAidsStart()),
    onFetchDoctors: () => dispatch(actions.fetchDoctorsStart()),
    onFetchPatient: (patientId) =>
      dispatch(actions.fetchPatientStart(patientId)),
    onAddPatient: (patient) => dispatch(actions.addPatientStart(patient)),
    onEditPatient: (patient) => dispatch(actions.editPatientStart(patient)),
    onClearError: () => dispatch(actions.clearError()),
    onClearPatient: () => dispatch(actions.clearPatient()),
    onResetState: () => dispatch(actions.resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
