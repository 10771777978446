import * as actionTypes from './actionTypes';

export const fetchPatientSpecimenStart = (patientId) => {
  return {
    type: actionTypes.FETCH_PATIENT_SPECIMENS_START,
    patientId,
  };
};

export const fetchPatientSpecimenSuccess = (specimens) => {
  return {
    type: actionTypes.FETCH_PATIENT_SPECIMENS_SUCCESS,
    specimens,
  };
};

export const fetchPatientSpecimenFail = (error) => {
  return {
    type: actionTypes.FETCH_PATIENTS_FAIL,
    error: error,
  };
};

export const fetchSpecimensStart = () => {
  return {
    type: actionTypes.FETCH_SPECIMENS_START,
  };
};

export const fetchSpecimensSuccess = (specimens) => {
  return {
    type: actionTypes.FETCH_SPECIMENS_SUCCESS,
    specimens,
  };
};

export const fetchSpecimensFail = (error) => {
  return {
    type: actionTypes.FETCH_SPECIMENS_FAIL,
    error: error,
  };
};

export const addSpecimenStart = (specimen) => {
  return {
    type: actionTypes.ADD_SPECIMEN_START,
    specimen,
  };
};

export const addSpecimenSuccess = (specimen) => {
  return {
    type: actionTypes.ADD_SPECIMEN_SUCCESS,
    specimen,
  };
};

export const addSpecimenFail = (error) => {
  return {
    type: actionTypes.ADD_SPECIMEN_FAIL,
    error,
  };
};

export const editSpecimenStart = (specimen) => {
  return {
    type: actionTypes.UPDATE_SPECIMEN_START,
    specimen,
  };
};

export const editSpecimenSuccess = (specimen) => {
  return {
    type: actionTypes.UPDATE_SPECIMEN_SUCCESS,
    specimen,
  };
};

export const editSpecimenFail = (error) => {
  return {
    type: actionTypes.UPDATE_SPECIMEN_FAIL,
    error,
  };
};

export const deleteSpecimenStart = (payload) => {
  return {
    type: actionTypes.DELETE_SPECIMEN_START,
    payload,
  };
};

export const deleteSpecimenSuccess = (specimen) => {
  return {
    type: actionTypes.DELETE_SPECIMEN_SUCCESS,
    specimen,
  };
};

export const deleteSpecimenFail = (error) => {
  return {
    type: actionTypes.DELETE_SPECIMEN_FAIL,
    error,
  };
};

export const resetSpecimen = () => {
  return {
    type: actionTypes.RESET_SPECIMEN,
  };
};

export const clearSpecimenError = () => {
  return {
    type: actionTypes.CLEAR_SPECIMEN_ERROR,
  };
};
