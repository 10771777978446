import React, { useState, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useHistory } from 'react-router-dom';
import Spinner from '../../Spinner/';
import AddPatient from '../AddPatient';
import AddSpecimen from '../AddSpecimen';
import AlertModal from '../../AlertModal';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import { format } from 'date-fns';

const initialState = {
  provIndex: -1,
  name: '',
  surname: '',
  initials: '',
  title: '',
  identificationType: 'idnumber',
  idnumber: '',
  passportnumber: '',
  birthday: format(new Date('2000/01/01'), 'dd-MM-yyyy'),
  dependantCode: '',
  gender: '',
  email: '',
  address1: '',
  address2: '',
  city: '',
  code: '',
  province: '',
  phone: '',
  cellphone: '',
  mainMember: {
    name: '',
    surname: '',
    initials: '',
    title: '',
    identificationType: 'idnumber',
    idnumber: '',
    passportnumber: '',
    phone: '',
    cellphone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    code: '',
    province: '',
    paymentType: 'Cash',
    mmIndex: -1,
    medicalAid: '',
    medicalAidNumber: '',
    option: '',
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      return initialState;
    }
    case 'field': {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case 'date': {
      return {
        ...state,
        birthday: action.value,
      };
    }
    case 'mfield': {
      const category = { ...state['mainMember'] };
      category[action.field] = action.value;
      return {
        ...state,
        mainMember: category,
      };
    }
    default:
  }
};

function NewPatient(props) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { medicalAids } = props;
  const [message, setMessage] = React.useState('');
  const [stitle, setTitle] = React.useState('');
  const [alertType, setAlertType] = React.useState('');
  const [step, setStep] = useState(1);
  const [animation, setAnimaion] = useState('zoomInLeft');
  const [mmIndex] = useState(-1);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!!props.success) {
      props.onResetSpecimen();
      setOpen(false);
      history.push(`/patients/`);
    } else if (props.serror) {
      props.onClearSpecimenError();
      setOpen(false);
    } else if (props.error) {
      props.onClearError();
      setOpen(false);
      prevStep();
    }
  };

  const nextStep = () => {
    setAnimaion('zoomInLeft');
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setAnimaion('zoomInRight');
    setStep(step - 1);
  };

  if (!!props.error | !!props.serror && !open) {
    handleOpen();
    setTitle('Error');
    setMessage(!!props.error ? props.error : props.serror);
    setAlertType('error');
  }

  if (props.success && !open) {
    handleOpen();
    setTitle('Success');
    setMessage('Changes saved successfully!');
    setAlertType('success');
  }
  const wizard = () => {
    switch (step) {
      case 1:
        return (
          <AddPatient
            medicalAids={medicalAids}
            patient={state}
            dispatch={dispatch}
            mmIndex={mmIndex}
            animation={animation}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 2:
        return (
          <>
            <AddSpecimen
              Doctors={props.doctors}
              patient={state}
              loading={props.dloading}
              error={props.derror}
              onAddPatient={props.onAddPatient}
              onAddSpecimen={props.onAddSpecimen}
              animation={animation}
              nextStep={nextStep}
              prevStep={prevStep}
            />
            <AlertModal
              alertType={alertType}
              open={open}
              onClose={handleClose}
              title={stitle}
              message={message}
            />
          </>
        );
      //
      default:
        return null;
    }
  };

  useEffect(() => {
    props.onFetchMedicalAids();
    props.onFetchDoctors();
  }, []);

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol className="mt-3">
          {props.loading | props.sloading ? <Spinner /> : <div>{wizard()}</div>}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
const mapStateToProps = (state) => {
  return {
    medicalAids: state.patient.medicalAids,
    success: state.specimen.success,
    doctors: state.doctor.doctors,
    dloading: state.doctor.loading,
    derror: state.doctor.error,
    patient: state.patient.patient,
    loading: state.patient.loading,
    sloading: state.specimen.loading,
    error: state.patient.error,
    serror: state.specimen.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchMedicalAids: () => dispatch(actions.fetchMedicalAidsStart()),
    onFetchDoctors: () => dispatch(actions.fetchDoctorsStart()),
    onAddSpecimen: (specimen) => dispatch(actions.addSpecimenStart(specimen)),
    onAddPatient: (mainMember, patient, specimen) =>
      dispatch(actions.addMainMemberStart(mainMember, patient, specimen)),
    onClearError: () => dispatch(actions.clearError()),
    onResetSpecimen: () => dispatch(actions.resetSpecimen()),
    onClearSpecimenError: () => dispatch(actions.clearSpecimenError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPatient);
