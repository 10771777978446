export const FETCH_SPECIMENS_START = 'FETCH_SPECIMENS_START';
export const FETCH_SPECIMENS_SUCCESS = 'FETCH_SPECIMENS_SUCCESS';
export const FETCH_SPECIMENS_FAIL = 'FETCH_SPECIMENS_FAIL';

export const FETCH_ICD10CODES_START = 'FETCH_ICD10CODES_START';
export const FETCH_ICD10CODES_SUCCESS = 'FETCH_ICD10CODES_SUCCESS';
export const FETCH_ICD10CODES_FAIL = 'FETCH_ICD10CODES_FAIL';

export const ADD_SELECTION = 'ADD_SELECTION';
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const UPDATE_SINGLE_SELECTION = 'UPDATE_SINGLE_SELECTION';
export const DELETE_SELECTION = 'DELETE_SELECTION';

export const FETCH_REPORT_START = 'FETCH_REPORT_START';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAIL = 'FETCH_REPORT_FAIL';

export const FETCH_REPORTS_START = 'FETCH_REPORTS_START';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAIL = 'FETCH_REPORTS_FAIL';

export const FETCH_CYTOLOGYTEXT_START = 'FETCH_CYTOLOGYTEXT_START';
export const FETCH_CYTOLOGYTEXT_SUCCESS = 'FETCH_CYTOLOGYTEXT_SUCCESS';
export const FETCH_CYTOLOGYTEXT_FAIL = 'FETCH_CYTOLOGYTEXT';

export const ADD_REPORT_START = 'ADD_REPORT_START';
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS';
export const ADD_REPORT_FAIL = 'ADD_REPORT_FAIL';

export const UPDATE_REPORT_START = 'UPDATE_REPORT_START';
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS';
export const UPDATE_REPORT_FAIL = 'UPDATE_REPORT_FAIL';

export const UPDATE_SECTION_START = 'UPDATE_SECTION_START';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS';
export const UPDATE_SECTION_FAIL = 'UPDATE_SECTION_FAIL';

export const UPDATE_AUDITTRAIL_START = 'UPDATE_AUDITTRAIL_START';
export const UPDATE_AUDITTRAIL_SUCCESS = 'UPDATE_AUDITTRAIL_SUCCESS';
export const UPDATE_AUDITTRAIL_FAIL = 'UPDATE_AUDITTRAIL_FAIL';

export const SEND_REPORT_START = 'SEND_REPORT_START';
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS';
export const SEND_REPORT_FAIL = 'SEND_REPORT_FAIL';

export const FETCH_PATIENTS = 'FETCH_PATIENTS';
export const FETCH_PATIENTS_START = 'FETCH_PATIENTS_START';
export const FETCH_PATIENTS_SUCCESS = 'FETCH_PATIENTS_SUCCESS';
export const FETCH_PATIENTS_FAIL = 'FETCH_PATIENTS_FAIL';
export const FETCH_PATIENT_START = 'FETCH_PATIENT_START';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAIL = 'FETCH_PATIENT_FAIL';
export const CLEAR_PATIENT = 'CLEAR_PATIENT';
export const FETCH_MEDICALAIDS_START = 'FETCH_MEDICALAIDS_START';
export const FETCH_MEDICALAIDS_SUCCESS = 'FETCH_MEDICALAIDS_SUCCESS';
export const FETCH_MEDICALAIDS_FAIL = 'FETCH_MEDICALAIDS_FAIL';
export const ADD_PATIENT = 'ADD_PATIENT';
export const ADD_PATIENT_START = 'ADD_PATIENT_START';
export const ADD_PATIENT_SUCCESS = 'ADD_PATIENT_SUCCESS';
export const ADD_PATIENT_FAIL = 'ADD_PATIENT_FAIL';
export const EDIT_PATIENT_START = 'EDIT_PATIENT_START';
export const EDIT_PATIENT_SUCCESS = 'EDIT_PATIENT_SUCCESS';
export const EDIT_PATIENT_FAIL = 'EDIT_PATIENT_FAIL';
export const ADD_MAINMEMBER_START = 'ADD_MAINMEMBER_START';
export const ADD_MAINMEMBER_SUCCESS = 'ADD_MAINMEMBER_SUCCESS';
export const ADD_MAINMEMBER_FAIL = 'ADD_MAINMEMBER_FAIL';
export const EDIT_MAINMEMBER_START = 'EDIT_MAINMEMBER_START';
export const EDIT_MAINMEMBER_SUCCESS = 'EDIT_MAINMEMBER_SUCCESS';
export const EDIT_MAINMEMBER_FAIL = 'EDIT_MAINMEMBER_FAIL';
export const ADD_DOCTOR_START = 'ADD_DOCTOR_START';
export const ADD_DOCTOR_SUCCESS = 'ADD_DOCTOR_SUCCESS';
export const ADD_DOCTOR_FAIL = 'ADD_DOCTOR_FAIL';
export const EDIT_DOCTOR_START = 'EDIT_DOCTOR_START';
export const EDIT_DOCTOR_SUCCESS = 'EDIT_DOCTOR_SUCCESSS';
export const EDIT_DOCTOR_FAIL = 'EDIT_DOCTOR_FAIL';
export const FETCH_DOCTOR_START = 'FETCH_DOCTOR_START';
export const FETCH_DOCTOR_SUCCESS = 'FETCH_DOCTOR_SUCCESS';
export const FETCH_DOCTOR_FAIL = 'FETCH_DOCTOR_FAIL';
export const FETCH_DOCTORS_START = 'FETCH_DOCTORS_START';
export const FETCH_DOCTORS_SUCCESS = 'FETCH_DOCTORS_SUCCESS';
export const FETCH_DOCTORS_FAIL = 'FETCH_DOCTORS_FAIL';

export const DELETE_SPECIMEN_START = 'DELETE_SPECIMEN_START';
export const DELETE_SPECIMEN_SUCCESS = 'DELETE_SPECIMEN_SUCCESS';
export const DELETE_SPECIMEN_FAIL = 'DELETE_SPECIMEN_FAIL';

export const ADD_SPECIMEN_START = 'ADD_SPECIMEN_START';
export const ADD_SPECIMEN_SUCCESS = 'ADD_SPECIMEN_SUCCESS';
export const ADD_SPECIMEN_FAIL = 'ADD_SPECIMEN_FAIL';
export const UPDATE_SPECIMEN_START = 'UPDATE_SPECIMEN_START';
export const UPDATE_SPECIMEN_SUCCESS = 'UPDATE_SPECIMEN_SUCCESS';
export const UPDATE_SPECIMEN_FAIL = 'UPDATE_SPECIMEN_FAIL';
export const CLEAR_DOCTOR = 'CLEAR_DOCTOR';
export const CLEAR_DOCTOR_ERROR = 'CLEAR_DOCTOR_ERROR';

export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_REGISTRATION_SUCCESS = 'AUTH_REGISTRATION_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const CONFIRM_EMAIL_START = 'CONFIRM_EMAIL_START';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAIL = 'CONFIRM_EMAIL_FAIL';
export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR';
export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESEND_CONFIRM_EMAIL_START = 'RESEND_CONFIRM_EMAIL_START';
export const RESEND_CONFIRM_EMAIL_SUCCESS = 'RESEND_CONFIRM_EMAIL_SUCCESS';
export const RESEND_CONFIRM_EMAIL_FAIL = 'RESEND_CONFIRM_EMAIL_FAIL';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_REPORT_ERROR = 'CLEAR_REPORT_ERROR';
export const CLEAR_SPECIMEN_ERROR = 'CLEAR_SPECIMEN_ERROR';
export const RESET_STATE = 'RESET_STATE';

export const FETCH_PATIENT_SPECIMENS_START = 'FETCH_PATIENT_SPECIMENS_START';
export const FETCH_PATIENT_SPECIMENS_SUCCESS = 'FETCH_PATIENT_SPECIMENS_SUCCESS';
export const FETCH_PATIENT_SPECIMENS_FAIL = 'FETCH_PATIENT_SPECIMENS_FAIL';
export const RESET_SPECIMEN = 'RESET_SPECIMEN';
