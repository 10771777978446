import React, { useEffect } from 'react';
import './App.css';
import { Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';
import Main from './components/Main';

const App = (props) => {
  useEffect(() => {
    const { onTryAutoSignup } = props;
    if (!props.isAuthenticated) onTryAutoSignup();
  }, [props, props.isAuthenticated]);

  return (
    <Switch>
      <Main />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
