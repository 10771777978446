import { put } from 'redux-saga/effects';
import * as actions from '../actions/index';
import { config } from '../../Constants';
import { isEmpty } from '../../shared/utility';

export function* fetchDoctorsSaga() {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    const response = yield fetch(`${config.url.API_URL}/api/v1/doctors/`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
    }).then((d) => d.json());
    if (response.error) throw response.error;
    yield put(actions.fetchDoctorsSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchDoctorsFail(error));
  }
}

export function* fetchDoctorSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    const response = yield fetch(
      `${config.url.API_URL}/api/v1/doctors/${action.doctorId}`,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: bearer,
        },
      }
    ).then((d) => d.json());
    if (response.error) throw response.error;
    yield put(actions.fetchDoctorSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchDoctorFail(error));
  }
}

export function* addDoctorSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/doctors`;
    const response = yield fetch(endpointURL, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(action.doctor),
    }).then((d) => d.json());
    if (response.error) throw response.error;
    yield put(actions.addDoctorSuccess(response.data));
    if (!isEmpty(action.specimen)) {
      const docId = response.data.id;
      action.specimen.doctors = docId;
      yield put(actions.addSpecimenStart(action.specimen));
    }
  } catch (error) {
    if (typeof error === 'object')
      yield put(actions.addDoctorFail(error.errmsg));
    else {
      error.includes('Duplicate')
        ? yield put(
            actions.addDoctorFail(
              'A doctor with this email address has already been added to the dictionary'
            )
          )
        : yield put(actions.addDoctorFail(error));
    }
  }
}

export function* editDoctorSaga(action) {
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    let endpointURL = `${config.url.API_URL}/api/v1/doctors/${action.doctor.id}`;
    const response = yield fetch(endpointURL, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: bearer,
      },
      body: JSON.stringify(action.doctor),
    }).then((d) => d.json());
    if (response.error) throw response.error;
    yield put(actions.editDoctorSuccess(response.data));
  } catch (error) {
    if (typeof error === 'object')
      yield put(actions.editDoctorFail(error.errmsg));
    else yield put(actions.editDoctorFail(error));
  }
}
