import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBAnimation,
  MDBCardBody,
  MDBCard,
} from 'mdbreact';
import Error from '../Error';
import Spinner from '../Spinner';
import * as actions from '../../store/actions/index';
import {
  isFormValid,
  passwordValidation,
  passwordEqual,
} from '../../shared/utility';

function ResetPassword({
  match,
  onResetPassword,
  loading,
  error,
  onAuthClearError,
}) {
  const { resetPasswordToken } = match.params;

  const [password, setPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [animation] = useState('bounce');

  useEffect(() => {
    // if (!error) onEmailConfirmed(confirmationtoken);
    if (
      document.getElementById('txtPassword') &&
      document.getElementById('txtPasswordConfirm')
    ) {
      document.getElementById('txtPassword').oninput = passwordValidation;
      document.getElementById('txtPasswordConfirm').oninput = passwordEqual;
    }
    // returned function will be called on component unmount
    return () => {
      if (
        document.getElementById('txtPassword') &&
        document.getElementById('txtPasswordConfirm')
      ) {
        document.getElementById('txtPassword').oninput = undefined;
        document.getElementById('txtPasswordConfirm').oninput = undefined;
      }
    };
  });

  const submitHandler = (event) => {
    event.preventDefault();
    event.target.className += ' was-validated';
    if (!isFormValid(event)) return;
    onResetPassword(resetPasswordToken, password);
  };

  const changeHandler = (event) => {
    switch (event.target.name) {
      case 'password':
        setPassword(event.target.value);
        break;
      case 'password1':
        setPassword1(event.target.value);
        break;
      default:
    }
  };

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol md="6" className="offset-md-3">
          <Error show={error} error={error} clearErr={onAuthClearError} />
          <MDBAnimation type={animation}>
            <MDBCard>
              <MDBCardBody className="mx-4">
                <form
                  id="frmRegister"
                  className="needs-validation"
                  onSubmit={submitHandler}
                  noValidate
                >
                  <div className="text-center">
                    <h3 className="dark-grey-text mb-5">
                      <strong>Reset Password</strong>
                    </h3>
                  </div>
                  <div className="grey-text">
                    <MDBInput
                      id="txtPassword"
                      name="password"
                      value={password}
                      onChange={changeHandler}
                      type="password"
                      label="Your password"
                      icon="lock"
                      validate
                      required
                    >
                      <div className="invalid-feedback">
                        Ensure password is at least 8 charcaters long, contains
                        alpha-numeric and special character.
                      </div>
                      <div className="valid-feedback">Looks good!</div>
                    </MDBInput>
                  </div>{' '}
                  <div className="grey-text">
                    <MDBInput
                      id="txtPasswordConfirm"
                      name="password1"
                      value={password1}
                      onChange={changeHandler}
                      type="password"
                      label="Confirm password"
                      icon="exclamation"
                      validate
                      required
                    >
                      <div className="invalid-feedback">
                        Re-type password and ensure they are the same.
                      </div>
                      <div className="valid-feedback">Looks good!</div>
                    </MDBInput>
                  </div>
                  <div className="text-center mb-3">
                    <MDBBtn
                      form="frmRegister"
                      type="submit"
                      gradient="cloudy-knoxville-gradient"
                      rounded
                      className="btn-block z-depth-1a"
                    >
                      Reset
                    </MDBBtn>
                  </div>
                </form>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="d-flex justify-content-center">
                    <MDBBtn href="/confirm" color="deep-orange">
                      Request Reset Email
                    </MDBBtn>
                  </div>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBAnimation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPassword: (resetPasswordToken, password) =>
      dispatch(actions.resetPasswordStart(resetPasswordToken, password)),
    onAuthClearError: () => dispatch(actions.authClearError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
