import * as actionTypes from './actionTypes';

export const fetchICD10Start = () => {
  return {
    type: actionTypes.FETCH_ICD10CODES_START,
  };
};

export const fetchICD10Success = (codes) => {
  return {
    type: actionTypes.FETCH_ICD10CODES_SUCCESS,
    codes,
  };
};

export const fetchICD10Fail = (error) => {
  return {
    type: actionTypes.FETCH_ICD10CODES_FAIL,
    error,
  };
};

export const fetchCytologyTextStart = () => {
  return {
    type: actionTypes.FETCH_CYTOLOGYTEXT_START,
  };
};

export const fetchCytologyTextSuccess = (texts) => {
  return {
    type: actionTypes.FETCH_CYTOLOGYTEXT_SUCCESS,
    texts,
  };
};

export const fetchCytologyTextFail = (error) => {
  return {
    type: actionTypes.FETCH_CYTOLOGYTEXT_FAIL,
    error,
  };
};

export const deleteCytoSelection = (selection, subSelection, value) => {
  return {
    type: actionTypes.DELETE_SELECTION,
    selection,
    subSelection,
    arrayIndex:value,
  };
};

export const updateCytoSelection = (payload) => {
  return {
    type: actionTypes.UPDATE_SELECTION,
    item:payload,
  };
};

export const addCytoSelection = (payload) => {
  return {
    type: actionTypes.ADD_SELECTION,
    item:payload,
  };
};

export const updateCytoSingleSelection = (selection, subSelection, value) => {
  return {
    type: actionTypes.UPDATE_SINGLE_SELECTION,
    selection,
    subSelection,
    value
  };
};

export const fetchReportStart = (reportId) => {
  return {
    type: actionTypes.FETCH_REPORT_START,
    payload: reportId,
  };
};

export const fetchReportSuccess = (report) => {
  return {
    type: actionTypes.FETCH_REPORT_SUCCESS,
    report,
  };
};

export const fetchReportFail = (error) => {
  return {
    type: actionTypes.FETCH_REPORT_FAIL,
    error,
  };
};

export const fetchReportsStart = () => {
  return {
    type: actionTypes.FETCH_REPORTS_START,
  };
};

export const fetchReportsSuccess = (reports) => {
  return {
    type: actionTypes.FETCH_REPORTS_SUCCESS,
    reports,
  };
};

export const fetchReportsFail = (error) => {
  return {
    type: actionTypes.FETCH_REPORTS_FAIL,
    error,
  };
};

export const addReportFail = (error) => {
  return {
    type: actionTypes.ADD_REPORT_FAIL,
    error: error,
  };
};

export const addReportSuccess = (report) => {
  return {
    type: actionTypes.ADD_REPORT_SUCCESS,
    report,
  };
};

export const addReportStart = (report) => {
  return {
    type: actionTypes.ADD_REPORT_START,
    report,
  };
};

export const updateReportStart = (report, reportId) => {
  return {
    type: actionTypes.UPDATE_REPORT_START,
    report,
    reportId,
  };
};

export const updateReportSuccess = (report) => {
  return {
    type: actionTypes.UPDATE_REPORT_SUCCESS,
    report,
  };
};

export const updateReportFail = (error) => {
  return {
    type: actionTypes.UPDATE_REPORT_FAIL,
    error: error,
  };
};

export const updateSectionStart = (reportId, section, payload, userId) => {
  return {
    type: actionTypes.UPDATE_SECTION_START,
    reportId,
    payload,
    section,
    userId,
  };
};

export const updateSectionSuccess = (reportId, section, payload, userId) => {
  return {
    type: actionTypes.UPDATE_SECTION_SUCCESS,
    reportId,
    payload,
    section,
    userId,
  };
};

export const updateSectionFail = (error) => {
  return {
    type: actionTypes.UPDATE_SECTION_FAIL,
    error,
  };
};

export const updateAuditTrailStart = (reportId, section, payload, userId) => {
  return {
    type: actionTypes.UPDATE_AUDITTRAIL_START,
    reportId,
    payload,
    section,
    userId,
  };
};

export const updateAuditTrailSuccess = (report) => {
  return {
    type: actionTypes.UPDATE_AUDITTRAIL_SUCCESS,
    payload: report,
  };
};

export const updateAuditTrailFail = (error) => {
  return {
    type: actionTypes.UPDATE_AUDITTRAIL_FAIL,
    error,
  };
};

export const clearReportError = () => {
  return {
    type: actionTypes.CLEAR_REPORT_ERROR,
  };
};

export const resetState = () => {
  return {
    type: actionTypes.RESET_STATE,
  };
};

export const sendReportStart = (payload) => {
  return {
    type: actionTypes.SEND_REPORT_START,
    payload,
  };
};

export const sendReportSuccess = (name) => {
  return {
    type: actionTypes.SEND_REPORT_SUCCESS,
    name,
  };
};

export const sendReportFail = (error) => {
  return {
    type: actionTypes.SEND_REPORT_FAIL,
    error,
  };
};
