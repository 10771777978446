import { config } from '../Constants';
import axios from 'axios';
import { sub } from 'date-fns';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const updateObjectSection = (array, action) => {
  var itemIndex = parseInt(action.item.arrayIndex.split('-')[0].slice(-1));
  let newArray = [...array.report[action.item.subSelection]];
  newArray[itemIndex] = { ...newArray[itemIndex], text: action.item.text, id: action.item.id, icdcode: action.item.icd };
  return { ...array[action.item.selection], [action.item.subSelection]: newArray.filter(el=>el != null && el != '') };
};

export const deleteObjectSection = (array, action) => {
  var itemIndex = parseInt(action.arrayIndex);
  let newArray = [...array[action.selection][action.subSelection]];
  let removed = newArray.splice(itemIndex,1)
  return { ...array[action.selection], [action.subSelection]: newArray };
};

export const updatedNestedObject = (state, action) => {
  return { ...state[action.selection], [action.subSelection]: action.value };
}

export const addObjectSection = (array, action) => {
  let newArray = [];
  if (array.report[action.item.subSelection])
    newArray = [...array.report[action.item.subSelection]];
  newArray.push({ text: action.item.text, id: action.item.id });
  return { ...array.report, [action.item.subSelection]: newArray };
};

export const updateObjectInArray = (array, action) => {
  return array.map((item) => {
    if (item.id !== action.item.id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item,
    };
  });
};

export const cmp = (a, b) => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  } else if (typeof a === 'number' && typeof b !== 'number') {
    return -1;
  } else if (typeof a !== 'number' && typeof b === 'number') {
    return 1;
  } else {
    return a > b ? 1 : -1;
  }
}

export const removeObjectInArray = (array, action) =>
  array.filter((item) => item.id !== action.item.id);

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}

export const wrapperOnFetch = (url) => {
  var headers = new Headers();
  headers.append('Accept', 'application/json');
  var request = new Request(url, { headers: headers });

  return fetch(request).then(function (res) {
    return res.json();
  });
};

const uploadImageToServer = async (file, specimenId, caption) => {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('caption', caption);
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    const response = await axios({
      method: 'put',
      url: `${config.url.API_URL}/api/v1/specimens/${specimenId}/photo`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: bearer },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadReportToServer = async (file, reportId) => {
  let formData = new FormData();
  formData.append('file', file);
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    const response = await axios({
      method: 'put',
      url: `${config.url.API_URL}/api/v1/reports/${reportId}/pdf`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: bearer },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const uploadAddendumReportToServer = async (file, reportId) => {
  let formData = new FormData();
  formData.append('file', file);
  var bearer = 'Bearer ' + localStorage.getItem('token');
  try {
    const response = await axios({
      method: 'put',
      url: `${config.url.API_URL}/api/v1/reports/${reportId}/addendum`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data', Authorization: bearer },
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

export const uploadImage = (file, specimenId, caption) => {
  return new Promise(async (resolve, reject) => {
    const response = await uploadImageToServer(file, specimenId, caption);
    if (response.error) {
      reject();
      return;
    }
    await sleep(500);
    resolve({
      data: {
        url: response.data.url,
        width: 300,
        height: 200,
        alignment: 'left', // or "center", "right"
        contentType: response.data.contentType,
        caption,
      },
    });
  });
};

export const isEmpty = (obj) => {
  for (var i in obj) {
    return false;
  }
  return true;
};

export const filteredByKey = (obj, k) =>
  Object.fromEntries(Object.entries(obj).filter(([key, value]) => key === k));

export const parseISOString = (s) => {
  if (!!s) {
    if (Object.prototype.toString.call(s) === '[object Date]') s = s.toString();
    var b = s.split(/\D+/);
    if (b.length === 8)
      return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    return new Date(s);
  }
};

//Can change 7 to 2 for longer results.
export const generate5CharAlphaNumeric = () => (Math.random() + 1).toString(36).substring(7);

export const getbirthdayFromID = (s) => {
  if (s.length === 13) {
    var pattern = /(\d{2})(\d{2})(\d{2})/;
    var leadingDigit = parseInt(s.substring(0, 1));
    let birthday =
      leadingDigit <= 3 && leadingDigit >= 0
        ? new Date(s.substring(0, 6).replace(pattern, '20$1-$2-$3'))
        : new Date(s.substring(0, 6).replace(pattern, '19$1-$2-$3'));
    return birthday;
  } else return new Date();
};

export const isFormValid = (e) => {
  let isValid = true;
  const vcntrls = Array.from(e.target.elements)
    .filter((x) => x.classList.contains('validate'))
    .filter((y) => y.validity.valid === false);
  if (vcntrls.length) {
    isValid = false;
    const elemPos = vcntrls[0].getBoundingClientRect();
    var offset = elemPos.top - 100 - document.body.getBoundingClientRect().top;
    window.scroll({
      top: offset,
      left: 0,
      behavior: 'smooth',
    });
  }
  return isValid;
};

export const searchInArray = (searchQuery, array, objectKey = null) => {
  return array.filter((d) => {
    let data = objectKey ? d[objectKey] : d; //Incase If It's Array Of Objects.
    let dataWords =
      typeof data == 'string' &&
      data
        ?.split(' ')
        ?.map((b) => b && b.toLowerCase().trim())
        .filter((b) => b);
    let searchWords =
      typeof searchQuery == 'string' &&
      searchQuery
        ?.split(' ')
        .map((b) => b && b.toLowerCase().trim())
        .filter((b) => b);
    let matchingWords = searchWords.filter((word) => dataWords.includes(word));
    return matchingWords.length;
  });
};

export const passwordValidation = () => {
  var passwordValue = document.getElementById('txtPassword').value;
  var password = document.getElementById('txtPassword');
  var hasMinLength = passwordValue.length >= 8;
  var hasAlpha = /[a-zA-Z]/.test(passwordValue);
  var hasNumbers = /\d/.test(passwordValue);
  var hasNonalphas = /\W/.test(passwordValue);
  if (hasAlpha && hasNumbers && hasNonalphas && hasMinLength)
    password.setCustomValidity('');
  else
    password.setCustomValidity(
      'Password must be complex. Minimum length is 8 charcaters, must contain alpha-numeric and special character'
    );
};

export const passwordEqual = () => {
  var passwordConfirm = document.getElementById('txtPasswordConfirm');
  var passwordConfirmValue =
    document.getElementById('txtPasswordConfirm').value;
  var password = document.getElementById('txtPassword').value;

  if (password === passwordConfirmValue) passwordConfirm.setCustomValidity('');
  else passwordConfirm.setCustomValidity('Passwords must be the same');
};

export const contactNumberPresent = (tel, cell) => {
  if (
    document.getElementById(tel).value.trim().length === 0 &&
    document.getElementById(cell).value.trim().length === 0
  )
    document
      .getElementById(tel)
      .setCustomValidity('Enter either telephone or cellphone number or both.');
  else document.getElementById(tel).setCustomValidity('');
};

export const identityNumberPresent = (idn, ppn, it) => {
  if (it === 'idnumber') {
    if (document.getElementById(idn).value.length === 0)
      document
        .getElementById(idn)
        .setCustomValidity('Valid SA ID number is required');
    else document.getElementById(idn).setCustomValidity('');
  } else if (it === 'passportnumber') {
    if (document.getElementById(ppn).value.length === 0)
      document
        .getElementById(ppn)
        .setCustomValidity('Valid passport number is required');
    else document.getElementById(ppn).setCustomValidity('');
  }
};

export const medicalAidPresent = (mno, mp) => {
  if (document.getElementById(mno).value.trim().length === 0)
    document
      .getElementById(mno)
      .setCustomValidity('Medical aid number is required.');
  else document.getElementById(mno).setCustomValidity('');

  if (document.getElementById(mp).value.trim().length === 0)
    document
      .getElementById(mp)
      .setCustomValidity('Medical aid plan/option is required.');
  else document.getElementById(mp).setCustomValidity('');
};

export const isValidDate = (s, d) => {
  var bits = s.split(d);
  var dated = new Date(bits[2], bits[1] - 1, bits[0]);
  return dated && dated.getMonth() + 1 === parseInt(bits[1]);
};

export const getAge = (dateString) => {
  const birthday = new Date(dateString);
  const today = new Date();
  let thisYear = 0;
  if (today.getMonth() < birthday.getMonth()) thisYear = 1;
  else if (
    today.getMonth() === birthday.getMonth() &&
    today.getDate() < birthday.getDate()
  ) {
    thisYear = 1;
  }
  var age = today.getFullYear() - birthday.getFullYear() - thisYear;
  return age;
};

export const titleCase = (string) => string.toLowerCase().split(' ').map(s => s[0].toUpperCase() + s.slice(1)).join(' ')


// const getFirstError = (e) => {
//   const inv = Object.keys(e).filter((x) => x.validity.valid === 'false');
//   const elemPos = inv[0].getBoundingClientRect();
//   var offset = elemPos.top - document.body.getBoundingClientRect();
//   window.scrollTo(0, offset);
// };
// const addStyledText = (docObj, startX, startY, text, style) => {
//   switch (style) {
//     case 'BOLD':
//       docObj.setFont('montserratBold');
//       docObj.text(startX, startY, text);
//       break;
//     case 'ITALIC':
//       docObj.setFont('montserratItalic');
//       docObj.text(startX, startY, text);
//       break;
//     case 'BOLDITALIC':
//       docObj.setFont('montserratBoldItalic');
//       docObj.text(startX, startY, text);
//       break;
//     default:
//       docObj.setFont('montserratRegular');
//       docObj.text(startX, startY, text);
//   }
// };

// const getTextWidth = (doc, text) =>
//   (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
//   doc.internal.scaleFactor;

// const addTextToPage = (doc, textToAdd, lMargin, startY, rMargin) => {
//   const textWidth = getTextWidth(doc, textToAdd);
//   startY += 4;
//   let tempY = startY;
//   if (doc.splitTextToSize(textToAdd, 170).length > 1) {
//     var lines = doc.splitTextToSize(textToAdd, 170);
//     tempY = startY + lines.length * 5;
//     if (tempY + 25 > doc.internal.pageSize.getHeight()) {
//       const howMuchOverThePage =
//         tempY + 25 - doc.internal.pageSize.getHeight();
//       const linesToRemove = Math.floor(howMuchOverThePage / 5);
//       const old = lines;
//       const arr = old.splice(-1, linesToRemove);
//       doc.text(lMargin, startY, old);
//       doc.addPage();
//       doc.text(lMargin, 24, arr);
//       return 20 + arr.length * 5 - 5;
//     } else doc.text(lMargin, startY, lines);
//   } else doc.text(lMargin, startY, textToAdd);
//   return tempY;
// };

// const addTextToPageWithStyling = (
//   doc,
//   textToAdd,
//   lMargin,
//   startY,
//   styles
// ) => {
//   const styledLines = [];
//   const textWidth = getTextWidth(doc, textToAdd);
//   startY += 5;
//   let tempY = startY;
//   if (doc.splitTextToSize(textToAdd, 170).length > 1) {
//     var lines = doc.splitTextToSize(textToAdd, 170);
//     tempY = startY + lines.length * 5;
//     if (tempY + 20 > doc.internal.pageSize.getHeight()) {
//       const howMuchOverThePage =
//         tempY + 20 - doc.internal.pageSize.getHeight();
//       const linesToRemove = Math.floor(howMuchOverThePage / 5);
//       const lineMap = [];
//       let cummulativeTotal = 0;
//       for (let i = 0; i < lines.length; i++) {
//         lineMap.push({
//           startIndex: cummulativeTotal,
//           endIndex: cummulativeTotal + lines[i].length - 1,
//         });
//         cummulativeTotal += lines[i].length;
//       }
//       console.log(lineMap);
//       styles.map((x) => {
//         const { offset, length, style } = x;
//         const lastIndex = offset + length;

//         // const searchTerm = paragraph.substring(offset, offset + length);
//         // const sIndex = lines.findFirstSubstring(searchTerm);
//         // if (sIndex === -1) {
//         //   //style is on more than 1 line
//         //   while(sIndex === -1) {
//         //     const newSearchTerm = searchTerm.substring(0, searchTerm.lastIndexOf(' '));
//         //     sIndex = lines.findFirstSubstring(searchTerm);
//         //   }
//         //   const res = searchInArray(searchTerm, lines);
//         //   for (let i = 0; i < res.length; i++) {
//         //     if (searchTerm.indexOf(res[i]) > -1) {
//         //       // The first part is here so we can determine start and end, but it could span multiple lines...
//         //       console.log(res[i]);
//         //       console.log(searchTerm);
//         //     }
//         //   }
//         // } else {
//         //   styledLines.push({
//         //     lineIndex: sIndex,
//         //     style: style,
//         //     start: lines[sIndex].indexOf(searchTerm),
//         //     length: length,
//         //     lineLenght:lines[sIndex].length
//         //   });
//         // }

//         //if found, push index of location into array
//         //break up the line into styled part and unstyled part
//         //look for index that are the same suggesting multiple style on a single line
//         //figure out a way to apply the styles....
//       });
//       const old = lines;
//       const arr = old.splice(-1, linesToRemove);
//       doc.text(lMargin, startY, old);
//       doc.addPage();
//       doc.text(lMargin, 20, arr);
//       return 20 + arr.length * 5 - 5;
//     } else doc.text(lMargin, startY, lines);
//   } else {
//     let newStartPoint = 0;
//     styles.map((x) => {
//       addStyledText(
//         doc,
//         lMargin,
//         startY,
//         textToAdd.substring(newStartPoint, x.offset),
//         ''
//       );
//       addStyledText(
//         doc,
//         lMargin +
//           getTextWidth(doc, textToAdd.substring(newStartPoint, x.offset)),
//         startY,
//         textToAdd.substring(x.offset, x.offset + x.length),
//         x.style
//       );
//       newStartPoint = x.offset + x.length;
//       lMargin =
//         lMargin + getTextWidth(doc, textToAdd.substring(0, newStartPoint));
//     });
//     addStyledText(
//       doc,
//       23 + getTextWidth(doc, textToAdd.substring(0, newStartPoint)),
//       startY,
//       textToAdd.substring(newStartPoint),
//       ''
//     );
//     tempY += 5;
//   }
//   return tempY;
// };

// const getCentreTextOffset = (doc, text) =>
//   (doc.internal.pageSize.width - getTextWidth(doc, text)) / 2;

// const addTextandUnderline = (doc, text, startX, startY, centre) => {
//   startY += 2;
//   let textOffset = startX;
//   if (centre) {
//     textOffset = getCentreTextOffset(doc, text);
//   }
//   doc.text(textOffset, startY, text);
//   const length = getTextWidth(doc, text);
//   doc.line(textOffset, startY + 1, textOffset + length, startY + 1);
//   return startY;
// };

// const addCustomFont = (docObj, fontFilename, fontB64, font) => {
//   docObj.addFileToVFS(fontFilename, fontB64);
//   docObj.addFont(fontFilename, font, 'normal');
// };

// const addImageToPage = (
//   docObj,
//   url,
//   startX,
//   startY,
//   width,
//   height,
//   alignment
// ) => {
//   var img = new Image();
//   img.src = url;
//   docObj.addImage(
//     img,
//     url.substring(url.length - 3).toUpperCase(),
//     startX,
//     startY + 3,
//     width / 3.7795275591,
//     height / 3.7795275591
//   );
//   return startY + height * 0.2645833333;
// };

// const addPageNumbering = (docObj) => {
//   const pages = docObj.internal.getNumberOfPages();
//   const pageWidth = docObj.internal.pageSize.width;
//   const pageHeight = docObj.internal.pageSize.height;
//   docObj.setFontSize(7);

//   for (let j = 1; j < pages + 1; j++) {
//     let horizontalPos = pageWidth / 2; //Can be fixed number
//     let verticalPos = pageHeight - 5; //Can be fixed number
//     docObj.setPage(j);
//     docObj.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
//       align: 'center',
//     });
//   }
// };

// const addJSONBlock = (docObj, jsonObj, startX, startY) => {
//   let y = startY;
//   JSON.parse(jsonObj).blocks.map((x) => {
//     x.inlineStyleRanges.length
//       ? (y = addTextToPageWithStyling(
//           docObj,
//           x.text,
//           startX,
//           startY,
//           x.inlineStyleRanges
//         ))
//       : (y = addTextToPage(docObj, x.text, startX, startY));
//   });
//   return y;
// };

// const addJSONEntity = (docObj, jsonObj, startX, startY) => {
//   let y = startY;
//   Object.values(JSON.parse(jsonObj).entityMap).map((x) => {
//     x.type === 'IMAGE' &&
//       (y = addImageToPage(
//         docObj,
//         x.data.url,
//         startX,
//         startY,
//         x.data.width,
//         x.data.height,
//         x.data.alignment
//       ));
//   });
//   return y;
// };

// const genetePDF = () => {
//   let startX = 20;
//   let startY = 80;
//   const fontSize = 10;
//   let text = '';
//   var doc = new jsPDF('p', 'mm', 'a4');
//   const width = doc.internal.pageSize.getWidth();
//   const height = doc.internal.pageSize.getHeight();
//   doc.addImage(headerImage, 'JPEG', 0, 0, width, 108);
//   addCustomFont(doc, 'Montserrat-Bold.ttf', MontserratBold, 'montserratBold');
//   addCustomFont(
//     doc,
//     'Montserrat-BoldItalic.ttf',
//     MontserratBoldItalic,
//     'montserratBoldItalic'
//   );
//   addCustomFont(
//     doc,
//     'Montserrat-ExtraBold.ttf',
//     MontserratExtraBold,
//     'montserratExtraBold'
//   );
//   addCustomFont(
//     doc,
//     'Montserrat-Italic.ttf',
//     MontserratItalic,
//     'montserratItalic'
//   );
//   addCustomFont(
//     doc,
//     'Montserrat-Regular.ttf',
//     MontserratRegular,
//     'montserratRegular'
//   );
//   doc.setFont('montserratBold');
//   doc.setFontSize(fontSize);
//   text = `REF NO: ${specimenNo}`;
//   doc.text(startX, startY, text);
//   text = `RECEIVED: ${format(
//     parseISOString(specimenDate),
//     'dd MMM yyyy'
//   )} ${specimenTime}`;
//   startY += 5;
//   doc.text(startX, startY, text);
//   startY += 5;
//   text = `ISSUED: ${format(new Date(), 'dd MMM yyyy HH:mm')}`;
//   doc.text(startX, startY, text);
//   doc.setFont('montserratBoldItalic');
//   text = `Patient: ${patient.title} ${patient.name} ${patient.surname}, ${patient.location.formattedAddress}`;
//   startY = addTextToPage(doc, text, startX, startY);
//   if (hospitalName) {
//     text = `Hospital: ${hospitalName} Ward: ${hospitalWard}`;
//     startY = addTextToPage(doc, text, startX, startY);
//   }
//   text = urgent ? 'Urgent: Yes' : 'Urgent: No';
//   startY = addTextToPage(doc, text, startX, startY);
//   text = `Doctor: Dr. ${doctors.name} ${doctors.surname}, ${doctors.location.formattedAddress}`;
//   startY = addTextToPage(doc, text, startX, startY);
//   startY += 10;
//   doc.setFont('montserratRegular');
//   startY = addTextandUnderline(
//     doc,
//     title.toUpperCase(),
//     startX,
//     startY,
//     true
//   );
//   startY += 5;
//   doc.setFont('montserratBold');
//   startY = addTextandUnderline(
//     doc,
//     'CLINICAL HISTORY',
//     startX,
//     startY,
//     false
//   );
//   doc.setFont('montserratRegular');
//   startY = addTextToPage(doc, clinicalData, startX, startY);
//   doc.setFont('montserratBold');
//   startY = addTextandUnderline(doc, 'MACROSCOPY', startX, startY, false);
//   doc.setFont('montserratRegular');
//   startY = addJSONEntity(doc, macroscopic, startX, startY);
//   startY = addJSONBlock(doc, macroscopic, startX, startY);
//   doc.setFont('montserratBold');
//   startY = addTextandUnderline(doc, 'MICROSCOPY', startX, startY, false);
//   doc.setFont('montserratRegular');
//   startY = addJSONBlock(
//     doc,
//     JSON.stringify(convertToRaw(microscopic.getCurrentContent())),
//     startX,
//     startY
//   );
//   doc.setFont('montserratBold');
//   startY = addTextandUnderline(doc, 'DIAGNOSIS', startX, startY, false);
//   doc.setFont('montserratRegular');
//   startY = addJSONBlock(doc, diagnosis, startX, startY);
//   if (optcomments) {
//     doc.setFont('montserratBold');
//     startY = addTextandUnderline(doc, 'COMMENTS', startX, startY, false);
//     doc.setFont('montserratRegular');
//     startY = addJSONBlock(doc, comments, startX, startY);
//   }
//   addPageNumbering(doc);
//   doc.save('Generated.pdf');
// };
